import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import AddButton from "../AddButton";
import AddIcon from "@mui/icons-material/Add";
import { FieldErrors, FormError } from "../../../redux/slices/Types";
import { capitalize, isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import DocumentForm from "./DocumentForm";
import LoadingOverlay from "../LoadingOverlay";
import { useTranslation } from "react-i18next";

export interface DocumentWithFile {
  _id: string;
  name: string;
  url: string;
  file: File | null;
}

export interface DocumentsUploadModalProps {
  isOpen: boolean;
  isUploading: boolean;
  multiple?: boolean;
  title?: string;
  hasLabel?: boolean;
  accept?: string;
  onSubmit: (documents: Array<DocumentWithFile>) => void;
  onCancel: () => void;
  initialDocuments?: Array<DocumentWithFile>;
}

export default function DocumentsUploadModal({
  isOpen,
  isUploading,
  multiple = true,
  hasLabel = true,
  title,
  accept,
  onSubmit,
  onCancel,
  initialDocuments,
}: DocumentsUploadModalProps) {
  const { t } = useTranslation("common");
  const [documents, setDocuments] = useState<Array<DocumentWithFile>>(
    initialDocuments || []
  );
  const [errors, setErrors] = useState<Array<FormError>>([]);

  useEffect(() => {
    if (isOpen && initialDocuments) {
      setDocuments(initialDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, initialDocuments?.length]);

  useEffect(() => {
    if (isOpen && !documents.length && !initialDocuments?.length) {
      setDocuments([{ name: "", url: "", _id: uuid(), file: null }]);
    }
  }, [isOpen, documents, initialDocuments]);

  useEffect(() => {
    if (!isOpen) {
      setDocuments([]);
    }
  }, [isOpen]);

  const handleChange = (id: number, document: DocumentWithFile) => {
    const _documents = [...documents];
    _documents[id] = document;
    setDocuments(_documents);
  };

  const handleFileSelect = async (
    id: number,
    file: File | null,
    error?: string
  ) => {
    if (!error && file) {
      return handleChange(id, {
        ...documents[id],
        file,
        url: "",
      });
    }
  };

  const handleAddDocument = () => {
    setDocuments([
      ...documents,
      { name: "", url: "", _id: uuid(), file: null },
    ]);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    if (documents.length) {
      const errors: Array<FormError> = [];
      for (const document of documents) {
        const correspondantInitialDocument = documents.find(
          (d) => d._id === document._id
        );
        const err: FieldErrors = {};
        if (hasLabel && !document.name) {
          err["name"] = t(
            "common:documents.errors.emptyLabel",
            "Document label cannot be empty"
          );
        }

        if (
          correspondantInitialDocument
            ? !document.file && !correspondantInitialDocument.url
            : !document.file
        ) {
          err["file"] = t(
            "common:documents.errors.attachFile",
            "Please attach a file"
          );
        }

        if (!isEmpty(err)) {
          errors.push({ formId: document._id || "", error: err });
        }
      }
      if (errors.length) {
        return setErrors(errors);
      }
      onSubmit(documents);
    }
  };

  return (
    <Dialog maxWidth={hasLabel ? "md" : "sm"} fullWidth open={isOpen}>
      <DialogTitle
        variant="h6"
        sx={{
          fontWeight: "bold",
        }}
      >
        {title ||
          capitalize(t("common:documents.uploadMany", "Upload Documents"))}
      </DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <LoadingOverlay loading={isUploading} />
        <Stack spacing={2}>
          {documents?.map((document, index) => (
            <div key={document._id}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <DocumentForm
                    id={index}
                    document={document}
                    hasLabel={hasLabel}
                    onChange={handleChange}
                    onFileSelect={handleFileSelect}
                    errors={errors.find((e) => e.formId === document._id)}
                    accept={accept}
                  />
                </Box>
                {documents.length > 1 && (
                  <IconButton
                    onClick={() =>
                      setDocuments(
                        documents.filter((d) => d._id !== document._id)
                      )
                    }
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
              <Divider sx={{ mt: 2 }} />
            </div>
          ))}
        </Stack>
        {multiple ? (
          <Stack
            direction="row"
            spacing={2}
            sx={{ m: 1, display: "flex", alignItems: "center" }}
          >
            <AddButton
              sx={{ height: 50, width: 20 }}
              onClick={handleAddDocument}
              id="add-document-button"
            >
              <AddIcon />
            </AddButton>
            <span>{t("common:documents.add", "Add Document")}</span>
          </Stack>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="inherit"
          variant="contained"
          onClick={handleCancel}
        >
          {t("common:cancel", "Cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          id="document-upload-modal-validate-button"
        >
          {t("common:upload", "Upload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
