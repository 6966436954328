import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import GroupSelect from "../../asset-management/GroupSelect";

type BulkActionModalProps = {
  onSubmit: (args: { groups: string[] }, runAfterSubmit: () => void) => void;
  loading?: boolean;
  open: boolean;
  onClose: () => void;
};

export default function BulkActionModal({
  onSubmit,
  loading,
  open,
  onClose,
}: BulkActionModalProps) {
  const { t } = useTranslation(["common"]);
  const [selectedGroups, setSelectedGroup] = useState<string[]>([]);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {t("common:list.edit", "Edit")}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} py={2}>
          <GroupSelect
            onChange={(groups) => {
              setSelectedGroup(groups);
            }}
            value={selectedGroups}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose} sx={{ mr: 1 }}>
          {t("common:cancel", "Cancel")}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <LoadingButton
          onClick={() => {
            onSubmit(
              {
                groups: selectedGroups,
              },
              onClose
            );
          }}
          variant="contained"
          loading={loading}
        >
          {t("common:save", "Save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
