import { useSelector } from "react-redux";
import {
  GoodDistributionInput,
  GoodProfileEquivalenceConditionOperator,
  GoodProfileEquivalenceConditionTarget,
  GoodUnits,
  ShipmentCommodityType,
  useGetCustomerDetailsQuery,
  useGetGoodProfileListQuery,
} from "../../../../graphql/generated";
import GoodForm, { GoodFormProps } from "./GoodForm";
import { RootState } from "../../../../redux/store";
import { castArray, intersection, isArray } from "lodash";

const GoodFormContainer = (
  props: Omit<
    GoodFormProps,
    "goodProfiles" | "equivalentGoodProfiles" | "goodInventoryItems"
  > & {
    customer: string | undefined | null;
    shipper: string | null;
    allReceivedGoods?: Array<GoodDistributionInput>;
  }
) => {
  const getGoodProfilesQuery = useGetGoodProfileListQuery();
  const getCustomerDetailsQuery = useGetCustomerDetailsQuery(
    {
      id: props.customer || "",
    },
    {
      enabled: !!props.customer,
    }
  );

  const shipment = useSelector(
    (state: RootState) => state.shipmentReducer.shipment
  );

  const goodProfiles = getGoodProfilesQuery.data?.goodProfiles.data || [];
  const goodInventoryItems =
    getCustomerDetailsQuery.data?.customerById.goodInventoryItems || [];

  const availableGoodProfiles = goodProfiles
    .filter(
      (goodProfile) =>
        !props.shipper ||
        !goodProfile.shipperIds?.length ||
        goodProfile.shipperIds.includes(props.shipper)
    )
    .filter((goodProfile) => {
      // If the received goods are already determined
      // then we can only ship those received goods
      if (props.allReceivedGoods?.length) {
        if (
          props.allReceivedGoods.some(
            (receivedGood) =>
              receivedGood.goodProfileId === goodProfile._id ||
              receivedGood.goodId === goodProfile._id
          )
        ) {
          return true;
        }

        return false;
      }
      return shipment.commodityType === ShipmentCommodityType.Liquid
        ? [GoodUnits.Liters, GoodUnits.Gallons].includes(goodProfile.unit)
        : shipment.commodityType === ShipmentCommodityType.Dry
        ? [GoodUnits.Kilograms, GoodUnits.Pounds, GoodUnits.Item].includes(
            goodProfile.unit
          )
        : true;
    });

  const equivalentGoodProfiles = availableGoodProfiles
    .map((goodProfile) => {
      const applicableEquivalences = (goodProfile.equivalences || []).filter(
        (equivalence) =>
          equivalence.conditions.every((condition) => {
            let targetValue: string | number | string[] | null = null;
            if (
              condition.target === GoodProfileEquivalenceConditionTarget.Date
            ) {
              targetValue = shipment.date;
            }
            if (
              condition.target === GoodProfileEquivalenceConditionTarget.Time
            ) {
              targetValue = shipment.date;
            }
            if (
              condition.target ===
              GoodProfileEquivalenceConditionTarget.Receiver
            ) {
              targetValue = shipment.shipmentLocations
                .filter((location) => location.receiver)
                .map((location) => location.receiver) as string[];
            }
            if (
              condition.target === GoodProfileEquivalenceConditionTarget.Shipper
            ) {
              targetValue = shipment.shipmentLocations
                .filter((location) => location.shipper)
                .map((location) => location.shipper) as string[];
            }

            if (!targetValue) {
              return false;
            }

            if (
              condition.operator ===
              GoodProfileEquivalenceConditionOperator.Equals
            ) {
              return (
                intersection(castArray(targetValue), castArray(condition.value))
                  .length > 0
              );
            }
            if (
              condition.operator ===
              GoodProfileEquivalenceConditionOperator.IsGreaterThanOrEqual
            ) {
              if (isArray(targetValue)) {
                return targetValue.every((value) => value >= condition.value);
              }
              return targetValue >= condition.value;
            }
            if (
              condition.operator ===
              GoodProfileEquivalenceConditionOperator.IsLessThanOrEqual
            ) {
              if (isArray(targetValue)) {
                return targetValue.every((value) => value <= condition.value);
              }
              return targetValue <= condition.value;
            }
            if (
              condition.operator ===
              GoodProfileEquivalenceConditionOperator.IsBetween
            ) {
              if (isArray(targetValue)) {
                return targetValue.every(
                  (value) =>
                    value >= condition.value[0] && value <= condition.value[1]
                );
              }
              return (
                targetValue >= condition.value[0] &&
                targetValue <= condition.value[1]
              );
            }

            return false;
          })
      );

      return applicableEquivalences.map((equivalence) => {
        const equivalentGoodProfile = goodProfiles.find(
          (goodProfile) => goodProfile._id === equivalence.equivalentGoodId
        );
        if (!equivalentGoodProfile) {
          return null;
        }
        return {
          ...equivalentGoodProfile,
          equivalentOf: goodProfile,
        };
      });
    })
    .flat()
    .filter(Boolean) as GoodFormProps["equivalentGoodProfiles"];

  return (
    <GoodForm
      {...props}
      goodProfiles={availableGoodProfiles}
      goodInventoryItems={goodInventoryItems}
      equivalentGoodProfiles={equivalentGoodProfiles}
    />
  );
};

export default GoodFormContainer;
