import Joi from "joi";
import { AssetLinking } from "../../../graphql/generated";

const assetLinkingSchema = Joi.object<AssetLinking>({
  _id: Joi.string().allow(null).label("ID"),
  driver: Joi.string().allow(null).label("Driver"),
  tractor: Joi.string().allow(null).label("Tractor"),
  trailer: Joi.string().allow(null).label("Trailer"),
  isHardLinked: Joi.boolean().allow(null).label("Is Hard Linked"),
  additionalTrailers: Joi.array()
    .items(Joi.string())
    .allow(null)
    .label("Additional Trailers"),
  startDate: Joi.date().allow(null).label("Start Date and Time"),
  endDate: Joi.date().allow(null).label("End Date and Time"),
})
  .custom((value, helpers) => {
    const { driver, tractor, trailer } = value;
    const definedColumns = [driver, tractor, trailer].filter(Boolean).length;
    if (definedColumns < 2) {
      return helpers.message({
        custom: "At least two assets must be linked",
      });
    }
    return value;
  })
  .custom((value, helpers) => {
    const { startDate, endDate } = value;
    if (startDate && endDate && startDate > endDate) {
      return helpers.message({
        custom: "Start date must be before end date",
      });
    }
    return value;
  });

export default assetLinkingSchema;
