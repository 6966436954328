import {
  GenerateTripsForShipmentsMutation,
  useGenerateTripsForShipmentsMutation,
  useGetShipmentListForAutoPlanQuery,
} from "../../../../graphql/generated";
import AutoPlanModal, { AutoPlanModalProps } from "./AutoPlanModal";
import { ShipmentListItem } from "../../../shipment/ShipmentsList/ShipmentsList";
import { useLocation } from "react-router-dom";
import { Alert, Stack } from "@mui/material";
import ShipmentLink from "../../../common/ShipmentLink";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
import { Warning } from "@mui/icons-material";
import { useEffect } from "react";
import useDialog from "../../../../utils/hooks/useDialog";
import { max, min } from "date-fns";
import LoadingOverlay from "../../../common/LoadingOverlay";

type AutoPlanModalContainerProps = Omit<
  AutoPlanModalProps,
  "shipments" | "onPlan"
> & {
  onPlanned: (
    result: GenerateTripsForShipmentsMutation["generateTripsForShipments"]
  ) => void;
};

const AutoPlanModalContainer = ({
  onPlanned,
  ...props
}: AutoPlanModalContainerProps) => {
  const { t } = useTranslation(["planning", "common"]);
  const shipmentsForAutoplanQuery = useGetShipmentListForAutoPlanQuery();
  const {
    state,
  }: { state: { shipmentsToAutoPlan: ShipmentListItem[] } | undefined } =
    useLocation();
  const confirm = useConfirm();
  const { showDialog } = useDialog();

  const generateTripsForShipmentsMutation =
    useGenerateTripsForShipmentsMutation();

  // This is the same function in the AutoPlanModalContainer component
  const onPlan = async (
    startDate: Date,
    endDate: Date,
    shipmentIds: string[]
  ) => {
    try {
      const result = await generateTripsForShipmentsMutation.mutateAsync({
        shipmentIds,
        startDate,
        endDate,
      });

      const unplannedShipments =
        result.generateTripsForShipments.unplannedShipments;
      if (unplannedShipments.length) {
        try {
          await confirm({
            title: t("autoPlanModal.unplannedShipments"),
            description: (
              <Stack spacing={2}>
                <Alert severity="warning">
                  {t("autoPlanModal.unplannedShipmentsDescription")}
                </Alert>
                {unplannedShipments.map((shipment) => (
                  <ShipmentLink key={shipment._id} shipment={shipment} />
                ))}
              </Stack>
            ),
            confirmationText: t("autoPlanModal.unplannedShipmentsConfirm"),
            cancellationText: t("autoPlanModal.cancel"),
            confirmationButtonProps: {
              color: "warning",
              startIcon: <Warning />,
              variant: "contained",
            },
          });
        } catch (error) {
          if (!error) {
            return;
          }
          throw error;
        }
      }

      onPlanned(result.generateTripsForShipments);
    } catch (error) {
      console.error(error);
      showDialog({
        type: "error",
        title: t("autoPlanModal.error"),
        description: `${t("autoPlanModal.errorDescription")} ${
          (error as Error).message
        }`,
      });
    }
  };

  useEffect(() => {
    if (state?.shipmentsToAutoPlan && state.shipmentsToAutoPlan.length > 0) {
      const shipments = state.shipmentsToAutoPlan;
      const startDate = min(
        shipments
          .flatMap((shipment) => shipment.shipmentLocations)
          .filter((location) => location.timeWindows[0]?.fromDate)
          .map((location) => new Date(location.timeWindows[0].fromDate))
      );
      const endDate = max(
        shipments
          .flatMap((shipment) => shipment.shipmentLocations)
          .filter((location) => location.timeWindows[0]?.toDate)
          .map((location) => new Date(location.timeWindows[0]?.toDate))
      );

      onPlan(
        startDate,
        endDate,
        state.shipmentsToAutoPlan.map((o) => o._id)
      );
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.shipmentsToAutoPlan]);

  const shipments: ShipmentListItem[] =
    shipmentsForAutoplanQuery.data?.shipments.data || [];

  return (
    <>
      <AutoPlanModal {...props} shipments={shipments} onPlan={onPlan} />
      <LoadingOverlay loading={generateTripsForShipmentsMutation.isLoading} />
    </>
  );
};

export default AutoPlanModalContainer;
