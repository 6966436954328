import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { format, isValid, parse } from "date-fns";
import { CustomFieldInputComponent } from "../CustomFieldsForm";

const DateTimeInput: CustomFieldInputComponent = ({
  value,
  onChange,
  customFieldDefinition,
  label,
}) => {
  const parsedDate = parse(
    String(value),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    new Date()
  );
  return (
    <DateTimePicker
      label={label || customFieldDefinition.label}
      value={value && isValid(parsedDate) ? parsedDate : null}
      onChange={(value) => {
        if (!value) {
          return;
        }
        if (!isValid(value)) {
          return;
        }
        onChange(format(value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default DateTimeInput;
