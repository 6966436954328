import Joi from "joi";
import { OrganizationUpdateInput } from "../../../graphql/generated";

const organizationInfosSchema = Joi.object<OrganizationUpdateInput>({
  name: Joi.string().label("Name").required(),
  address: Joi.string().label("Address").allow(null, ""),
  dotNumber: Joi.string().label("DOT Number").allow(null, ""),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .label("Email")
    .allow(null, ""),
  contactFullname: Joi.string().label("Contact Fullname").allow(null, ""),
  phoneNumber: Joi.string().label("Organization Phone").allow(null, ""),
});

export default organizationInfosSchema;
