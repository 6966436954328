import { AutocompleteProps } from "@mui/material";
import {
  GoodUnits,
  useGetGoodProfileListQuery,
} from "../../../../../graphql/generated";
import { ConditionValueSelectorComponentProps } from "../../ConditionForm";
import GoodsInput from "./GoodsInput";

type GoodsInputContainerProps = ConditionValueSelectorComponentProps;

const GoodsInputContainer = ({
  ...props
}: GoodsInputContainerProps &
  Pick<AutocompleteProps<string, true, undefined, undefined>, "sx">) => {
  const commoditiesQuery = useGetGoodProfileListQuery();

  const allGoods = (commoditiesQuery.data?.goodProfiles.data || []).concat(
    (props.customer?.goodInventoryItems || []).map((goodInventoryItem) => ({
      _id: goodInventoryItem._id || "",
      label: goodInventoryItem.label,
      unit: GoodUnits.Item,
      weight: goodInventoryItem.weight || 0,
    }))
  );
  return <GoodsInput {...props} goods={allGoods} />;
};

export default GoodsInputContainer;
