import {
  ExtensionWidgetContext,
  useGetExtensionsWidgetsQuery,
} from "../../../graphql/generated";
import ExtensionsWidgets, { ExtensionWidgetsProps } from "./ExtensionsWidgets";

export type ExtensionsWidgetsContainerProps = Omit<
  ExtensionWidgetsProps,
  "widgets"
> & {
  context: ExtensionWidgetContext;
};

const ExtensionsWidgetsContainer = (props: ExtensionsWidgetsContainerProps) => {
  const getExtensionsWidgetsQuery = useGetExtensionsWidgetsQuery(
    {
      context: props.context,
    },
    {
      refetchInterval: 60 * 1000,
      refetchOnWindowFocus: true,
    }
  );

  const widgets = getExtensionsWidgetsQuery.data?.extensionsWidgets || [];

  return <ExtensionsWidgets widgets={widgets} />;
};

export default ExtensionsWidgetsContainer;
