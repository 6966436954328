import {
  EditShipmentNoteInput,
  GetShipmentQuery,
  GetTripQuery,
  ShipmentNoteInput,
  Status,
  TransactionInput,
} from "../../../graphql/generated";
import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ShipmentDetails from "../ShipmentDetails";
import LoadingOverlay from "../../common/LoadingOverlay";
import {
  ShipmentChargeInputData,
  ShipmentDocumentInputData,
} from "../../../redux/slices/Types";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import {
  ContentCopy,
  DeleteOutlineOutlined,
  ModeEditOutlineOutlined,
  RestoreOutlined,
} from "@mui/icons-material";
import AssignmentModal from "../../trip/AssignmentModal";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentsListProps } from "../ShipmentForm/ShipmentDocumentsList/DocumentsList";
import {
  referenceNumberFieldLabels,
  referenceNumberFields,
} from "../ShipmentForm/ShipmentReferencesForm/ShipmentReferencesForm";
import LynksIconButton from "../../common/LynksIconButton";
import theme from "../../../theme";
import RoleGuard from "../../account/Access/RoleGuard";
import { useTranslation } from "react-i18next";
type ShipmentInfo = GetShipmentQuery["shipmentById"];

export type ShipmentInfosProps = {
  onDelete: (reason: string) => void;
  onRestore: () => void;
  loading: boolean;
  uploadingDocuments: boolean;
  error: unknown;
  shipment: ShipmentInfo | null | undefined;
  trip: GetTripQuery["tripById"] | null | undefined;
  onDocumentsUploaded: (documents: Array<ShipmentDocumentInputData>) => void;
  onNotesAdded: (notes: Array<ShipmentNoteInput>) => void;
  onNoteEdited: (notes: EditShipmentNoteInput) => void;
  onNoteDeleted: (id: string) => void;
  onDocumentDeleted: (id: string) => void;
  onDocumentsChanged: DocumentsListProps["onChange"];
  onChargeAdded: (charge: ShipmentChargeInputData) => void;
  onChargesChanged: (charges: ShipmentChargeInputData[]) => void;
  onChargeDeleted: (id: string) => void;
  onExpenseAdded: (expense: TransactionInput) => void;
  onExpensesChanged: (expenses: TransactionInput[]) => void;
  onExpenseDeleted: (id: string) => void;
  onRefresh: () => void;
  onShipmentLock: () => void;
  onShipmentUnlock: () => void;
  canInvoice?: boolean;
  canAssign?: boolean;
  actionsInNewTab?: boolean;
};

export default function ShipmentInfos({
  onDelete,
  onRestore,
  loading,
  uploadingDocuments,
  error,
  shipment,
  trip,
  onDocumentsUploaded,
  onNotesAdded,
  onNoteEdited,
  onNoteDeleted,
  onDocumentDeleted,
  onDocumentsChanged,
  onChargeAdded,
  onChargeDeleted,
  onChargesChanged,
  onExpenseAdded,
  onExpensesChanged,
  onExpenseDeleted,
  onRefresh,
  canInvoice,
  canAssign = true,
  actionsInNewTab,
  onShipmentLock,
  onShipmentUnlock,
}: ShipmentInfosProps) {
  const [isAssignmentModalOpen, setAssignmentModalOpen] = useState(false);
  const [isCancelMidalVisible, setIsCancelModalVisible] = useState(false);
  const [reason, setReason] = useState<string>("");
  const navigate = useNavigate();
  const { t } = useTranslation(["orders", "trips"]);

  if (loading) {
    return (
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <LoadingOverlay loading={loading} />
      </Box>
    );
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (shipment) {
    return (
      <Container>
        <Stack
          direction="row"
          flexWrap="wrap"
          spacing={1}
          sx={{
            mb: 3,
          }}
          justifyContent={{
            xs: "center",
            sm: "flex-end",
          }}
          alignItems="center"
        >
          {shipment.status !== Status.Deleted ? (
            <>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mr: 1 }}
                mb={{
                  xs: 2,
                  sm: 0,
                }}
                width={{
                  xs: "100%",
                  sm: "auto",
                }}
                justifyContent={{
                  xs: "center",
                  sm: "flex-end",
                }}
              >
                <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
                  {shipment.status === Status.Forecasted ? (
                    <Button
                      onClick={() => {
                        onShipmentLock();
                      }}
                      variant="outlined"
                    >
                      Lock Order
                    </Button>
                  ) : shipment.status === Status.ForecastedLocked ? (
                    <Button
                      onClick={() => {
                        onShipmentUnlock();
                      }}
                      variant="outlined"
                    >
                      Unlock Order
                    </Button>
                  ) : null}
                  {!shipment.isSplit &&
                  !shipment.dailyRotationCount &&
                  canAssign ? (
                    <Button
                      onClick={() => {
                        setAssignmentModalOpen(true);
                      }}
                      variant="contained"
                      color="lightPrimary"
                      size="large"
                      id="assign-button"
                    >
                      {t("trips:assign", "Assign")}
                    </Button>
                  ) : null}
                </RoleGuard>
              </Stack>
              <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
                {!shipment.isSplit ? (
                  <LynksIconButton
                    sx={{
                      color: "black",
                      backgroundColor: theme.palette.primaryBackground.main,
                    }}
                    color="primary"
                    href={`/orders/edit/${shipment?._id}`}
                    target={actionsInNewTab ? "_blank" : undefined}
                  >
                    <ModeEditOutlineOutlined />
                  </LynksIconButton>
                ) : null}
                {!shipment.isFromSplit ? (
                  <>
                    <LynksIconButton
                      sx={{
                        color: "black",
                        backgroundColor: theme.palette.primaryBackground.main,
                      }}
                      color="primary"
                      href={`/orders/new?duplicatedShipmentId=${shipment._id}`}
                      target={actionsInNewTab ? "_blank" : undefined}
                    >
                      <ContentCopy />
                    </LynksIconButton>
                    <LynksIconButton
                      sx={{
                        color: "black",
                        backgroundColor: theme.palette.primaryBackground.main,
                      }}
                      onClick={() => setIsCancelModalVisible(true)}
                      color="error"
                    >
                      <DeleteOutlineOutlined />
                    </LynksIconButton>
                  </>
                ) : null}
              </RoleGuard>
            </>
          ) : (
            <LynksIconButton
              sx={{
                color: "black",
                backgroundColor: theme.palette.primaryBackground.main,
              }}
              onClick={() => onRestore()}
            >
              <RestoreOutlined />
            </LynksIconButton>
          )}
        </Stack>
        {shipment.status === Status.Deleted ? (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Cancellation reason: {shipment.reasonForCancellation || "N/A"}
          </Alert>
        ) : null}
        <ShipmentDetails
          uploadingDocuments={uploadingDocuments}
          onDocumentDeleted={onDocumentDeleted}
          onNoteEdited={onNoteEdited}
          onNoteDeleted={onNoteDeleted}
          shipment={shipment}
          onDocumentsUploaded={onDocumentsUploaded}
          onDocumentsChanged={onDocumentsChanged}
          onNotesAdded={onNotesAdded}
          onChargeAdded={onChargeAdded}
          onChargesChanged={onChargesChanged}
          onChargeDeleted={onChargeDeleted}
          onExpenseAdded={onExpenseAdded}
          onExpensesChanged={onExpensesChanged}
          onExpenseDeleted={onExpenseDeleted}
          onRefresh={onRefresh}
          trip={trip}
          isInputView={false}
          routeDistance={shipment.route?.routeDistance || 0}
          canInvoice={canInvoice}
        />

        <AssignmentModal
          tripId={shipment.tripId}
          shipments={[shipment]}
          open={isAssignmentModalOpen}
          onClose={(tripId) => {
            setAssignmentModalOpen(false);
            if (tripId) {
              navigate(`/trips/details/${tripId}`);
            }
          }}
        />

        <Dialog
          open={isCancelMidalVisible}
          onClose={() => setIsCancelModalVisible(false)}
          aria-labelledby="dialog-confirmation"
          aria-describedby="dialog-confirmation"
        >
          <DialogTitle
            sx={{
              color: "error.main",
            }}
            id="dialog-confirmation"
          >
            Danger zone
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>
                {`Do you really want to cancel this shipment? This action cannot be undone.`}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography>Shipment #:&nbsp;</Typography>
                <Typography fontWeight={"bold"}>
                  {shipment?.shipmentNumber}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography>Customer/Broker:&nbsp;</Typography>
                <Typography></Typography>
                <Typography fontWeight={"bold"}>
                  {shipment?.customer.name}
                </Typography>
              </Box>
              {referenceNumberFields.map((field) => (
                <Box key={field} sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography>
                    {referenceNumberFieldLabels[field]}:&nbsp;
                  </Typography>
                  <Typography fontWeight={"bold"}>
                    {shipment[field]?.join(", ")}
                  </Typography>
                </Box>
              ))}
              <ReasonForm
                reasons={cancellationReasons}
                onChange={(_reason) => setReason(_reason)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setIsCancelModalVisible(false)}
            >
              No
            </Button>
            <Button
              disabled={!reason || reason.trim() === ""}
              color="error"
              onClick={() => {
                onDelete(reason);
                setIsCancelModalVisible(false);
              }}
            >
              Yes, cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  } else {
    return <div></div>;
  }
}

const cancellationReasons = [
  {
    label: "Request from management",
    value: "request",
  },
  {
    label: "Required documents non-fulfilled",
    value: "non-fulfilled",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const ReasonForm = ({
  reasons,
  title,
  onChange,
}: {
  reasons: Array<{ label: string; value: string }>;
  title?: string;
  onChange: (message: string) => void;
}) => {
  const [reason, setReason] = useState<string>(reasons[0].label);
  const [choice, setChoice] = useState<string>(reasons[0].value);
  const [otherReason, setOtherReason] = useState<string>("");

  useEffect(() => {
    onChange(reason === "Other" ? otherReason : reason);
  }, [reason, otherReason, onChange]);

  return (
    <FormControl fullWidth component="fieldset">
      <FormLabel component="legend">{title || "Cancellation reason"}</FormLabel>
      <RadioGroup
        aria-label="reason"
        name="reason"
        onChange={(e) => {
          setChoice(e.target.value);
          setReason(
            reasons.find((r) => r.value === e.target.value)?.label || ""
          );
          setOtherReason("");
        }}
        value={choice || ""}
      >
        {reasons.map((reason) => (
          <FormControlLabel
            value={reason.value}
            control={<Radio />}
            label={reason.label}
          />
        ))}
      </RadioGroup>
      {choice === "other" && (
        <TextField
          onChange={(e) => setOtherReason(e.target.value)}
          fullWidth
          multiline
          rows={4}
          label="Reason"
          value={otherReason}
        />
      )}
    </FormControl>
  );
};
