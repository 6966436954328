import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { GoodInventoryItem } from "../../../../../graphql/generated";
import { ConditionValueSelectorComponentProps } from "../../ConditionForm";

const GoodsInput = ({
  value,
  onChange,
  goods: goodInventoryItems,
  ...otherProps
}: ConditionValueSelectorComponentProps & {
  goods: Pick<GoodInventoryItem, "_id" | "label">[];
} & Pick<AutocompleteProps<string, true, undefined, undefined>, "sx">) => {
  return (
    <Autocomplete
      disablePortal
      options={goodInventoryItems || []}
      getOptionLabel={(goodInventoryItem) => goodInventoryItem.label}
      onChange={(event, value) => {
        if (!value) {
          return;
        }
        onChange(value.label);
      }}
      value={
        goodInventoryItems.find((goodItem) => goodItem.label === value) || null
      }
      size="small"
      renderInput={(inputProps) => (
        <TextField {...inputProps} label={otherProps.label} />
      )}
      sx={{
        display: "flex",
      }}
      {...otherProps}
    />
  );
};

export default GoodsInput;
