import { useTranslation } from "react-i18next";
import {
  useBulkUpdateContractForShipmentsMutation,
  useGetShipmentsForBestBuyBulkUpdateQuery,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import BestBuyBulkUpdateModal, {
  BestBuyBulkUpdateModalProps,
} from "./BestBuyBulkUpdateModal";
import LoadingOverlay from "../../common/LoadingOverlay";

export type BestBuyBulkUpdateModalContainerProps = Omit<
  BestBuyBulkUpdateModalProps,
  "shipments" | "onSubmit"
> & {
  shipmentIds: string[];
  onUpdated?: () => void;
};

const BestBuyBulkUpdateModalContainer = ({
  shipmentIds,
  ...props
}: BestBuyBulkUpdateModalContainerProps) => {
  const shipmentsQuery = useGetShipmentsForBestBuyBulkUpdateQuery({
    shipmentIds,
  });
  const { showDialog } = useDialog();
  const { t } = useTranslation(["orders", "common"]);

  const bulkUpdateContractMutation =
    useBulkUpdateContractForShipmentsMutation();

  if (props.open && shipmentsQuery.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (!shipmentsQuery.data?.shipments.data) {
    return null;
  }

  return (
    <BestBuyBulkUpdateModal
      {...props}
      shipments={shipmentsQuery.data.shipments.data}
      onSubmit={async (shipmentIds, contractId) => {
        try {
          await bulkUpdateContractMutation.mutateAsync({
            shipmentIds,
            contractId,
          });
          props.onUpdated?.();
        } catch (error) {
          showDialog({
            type: "error",
            title: t("common:error.title"),
            description: `${t(
              "bestBuy.bulkUpdateErrorDescription",
              "An error occurred when bulk updating contracts"
            )} ${(error as Error).message}`,
          });
        }
      }}
    />
  );
};

export default BestBuyBulkUpdateModalContainer;
