import {
  Box,
  Container,
  Grid,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import OrganizationInfosForm from "../../components/organization/OrgnizationInfosForm";
import { useState } from "react";
import InfoBlock from "../../components/common/InfoBlock";
import { useTranslation } from "react-i18next";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import OrganizationBillingAddress from "../../components/organization/OrganizationBillingAddress";
import OrganizationSubscriptionInfos from "../../components/organization/OrganizationSubscriptionInfos";
import OrganizationPaymentMethods from "../../components/organization/OrganizationPaymentMethods";
import OrganizationBillingHistory from "../../components/organization/OrganizationBillingHistory";
import OrganizationDocumentsManagementContainer from "../../components/organization/OrganizationDocumentsManagement";

type TabPanelProps = {
  children?: React.ReactNode;
  value: SectionItem;
  active: SectionItem;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, active, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={active !== value}
      id={`vertical-tabpanel-${value}`}
      aria-labelledby={`vertical-tab-${value}`}
      {...other}
    >
      {
        <Box
          sx={{
            display: active === value ? "block" : "none",
          }}
        >
          {children}
        </Box>
      }
    </div>
  );
}

enum SectionItem {
  GeneralInfos = "general-infos",
  ManageSubscription = "manage-subscription",
}

enum SubSectionItem {
  AccountInformations = "account-informations",
  PaymentMethods = "payment-methods",
  BillingHistory = "billing-history",
}

function ViewOrganizationInfos() {
  const [activeSection, setActiveSection] = useState(SectionItem.GeneralInfos);
  const [activeSubSection, setActiveSubSection] = useState(
    SubSectionItem.AccountInformations
  );
  const { t } = useTranslation(["organization"]);

  const subSections = [
    {
      id: SubSectionItem.AccountInformations,
      label: t("accountInfo", "Account Information") as string,
    },
    {
      id: SubSectionItem.PaymentMethods,
      label: t("paymentMethods", "Payment Methods") as string,
    },
    {
      id: SubSectionItem.BillingHistory,
      label: t("billingHistory", "Billing History") as string,
    },
  ];

  return (
    <Container sx={{ display: "flex", flexDirection: "row" }}>
      <MenuList sx={{ width: "227px" }}>
        <MenuItem
          key="menu-item-general-infos"
          sx={{
            mb: 1,
            backgroundColor:
              activeSection === SectionItem.GeneralInfos
                ? "primary.main"
                : "transparent",
            borderRadius: 1,
            "&:hover": {
              backgroundColor: "primary.main",
              "& .MuiTypography-root": {
                color: "white",
              },
            },
          }}
          aria-label="Vertical menu"
          id="vertical-tab-general-infos"
          aria-controls="vertical-tabpanel-general-infos"
          onClick={() => setActiveSection(SectionItem.GeneralInfos)}
        >
          <Typography
            sx={{
              color:
                activeSection === SectionItem.GeneralInfos ? "white" : "black",
            }}
          >
            {t("generalInfo", "General Information")}
          </Typography>
        </MenuItem>
        <SimpleTreeView>
          <TreeItem
            itemId="manage-subscription"
            label={t("manageSubscription", "Manage Subscription")}
          >
            {subSections.map((subSection, index) => {
              const isActive =
                activeSection === SectionItem.ManageSubscription &&
                activeSubSection === subSection.id;
              return (
                <TreeItem
                  itemId={subSection.id}
                  label={subSection.label}
                  sx={{
                    mb: 1,
                    mt: index === 0 ? 1 : "unset",
                    backgroundColor: isActive ? "primary.main" : "transparent",
                    borderRadius: 1,
                    "&:hover": {
                      backgroundColor: "primary.main",
                      "& .MuiTreeItem-label": {
                        color: "white",
                      },
                    },
                    "& .MuiTreeItem-label": {
                      color: isActive ? "white" : "black",
                    },
                  }}
                  onClick={() => {
                    setActiveSection(SectionItem.ManageSubscription);
                    setActiveSubSection(subSection.id);
                  }}
                />
              );
            })}
          </TreeItem>
        </SimpleTreeView>
      </MenuList>
      <Box sx={{ p: 1, ml: 2, width: "100%" }}>
        <TabPanel
          key={`panel-${SectionItem.GeneralInfos}`}
          value={SectionItem.GeneralInfos}
          active={activeSection}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InfoBlock
                childrenContainerSx={{ p: 3 }}
                title={t("generalInfo", "General Information")}
              >
                <OrganizationInfosForm />
              </InfoBlock>
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoBlock
                childrenContainerSx={{ p: 3 }}
                title={t("organization:documents", "Documents")}
              >
                <OrganizationDocumentsManagementContainer />
              </InfoBlock>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel
          key={`panel-${SectionItem.ManageSubscription}`}
          value={SectionItem.ManageSubscription}
          active={activeSection}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <InfoBlock
                childrenContainerSx={{ p: 3 }}
                title={
                  activeSubSection === SubSectionItem.AccountInformations
                    ? t("billingAddress", "Billing Address")
                    : activeSubSection === SubSectionItem.PaymentMethods
                    ? t("paymentMethods", "Payment Methods")
                    : activeSubSection === SubSectionItem.BillingHistory
                    ? t("billingHistory", "Billing History")
                    : ""
                }
              >
                <Box
                  sx={{
                    display:
                      activeSubSection === SubSectionItem.AccountInformations
                        ? "block"
                        : "none",
                  }}
                >
                  <OrganizationBillingAddress />
                </Box>
                <Box
                  sx={{
                    display:
                      activeSubSection === SubSectionItem.PaymentMethods
                        ? "block"
                        : "none",
                  }}
                >
                  <OrganizationPaymentMethods />
                </Box>
                <Box
                  sx={{
                    display:
                      activeSubSection === SubSectionItem.BillingHistory
                        ? "block"
                        : "none",
                  }}
                >
                  <OrganizationBillingHistory />
                </Box>
              </InfoBlock>
            </Grid>
            <Grid item xs={12} lg={6}>
              <InfoBlock
                childrenContainerSx={{ p: 3 }}
                title={t("subscriptionDetails", "Subscription Details")}
              >
                <OrganizationSubscriptionInfos />
              </InfoBlock>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Container>
  );
}

export default ViewOrganizationInfos;
