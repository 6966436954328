import {
  useGetAllDriverShiftsQuery,
  useGetDriverListQuery,
  useGetTractorListQuery,
  useGetTrailerListQuery,
} from "../../../graphql/generated";
import AssetLinkingForm, { AssetLinkingFormProps } from "./AssetLinkingForm";

export type AssetLinkingFormContainerProps = Omit<
  AssetLinkingFormProps,
  "drivers" | "trailers" | "tractors" | "driverShifts"
>;

const AssetLinkingFormContainer = (props: AssetLinkingFormContainerProps) => {
  const driversQuery = useGetDriverListQuery();
  const trailersQuery = useGetTrailerListQuery();
  const tractorsQuery = useGetTractorListQuery();

  const driverShiftsQuery = useGetAllDriverShiftsQuery();

  const driverShifts = driverShiftsQuery.data?.driverShifts.data || [];

  return (
    <AssetLinkingForm
      drivers={driversQuery.data?.drivers.data || []}
      trailers={trailersQuery.data?.trailers.data || []}
      tractors={tractorsQuery.data?.tractors.data || []}
      driverShifts={driverShifts}
      {...props}
      loading={
        props.loading ||
        driversQuery.isLoading ||
        trailersQuery.isLoading ||
        tractorsQuery.isLoading
      }
    />
  );
};

export default AssetLinkingFormContainer;
