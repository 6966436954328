import { SelectProps } from "@mui/material";
import { isArray } from "lodash";
import { ShipmentLocationType } from "../../../../graphql/generated";
import { ConditionValueSelectorComponentProps } from "../ConditionForm";
import EnumSelect from "../../../common/EnumSelect";

const LocationTypeSelect = ({
  value,
  onChange,
  ...otherProps
}: ConditionValueSelectorComponentProps & Pick<SelectProps<string>, "sx">) => {
  return (
    <EnumSelect
      label={otherProps.label || "Any Stop Type"}
      size="small"
      multiple
      placeholder="Any stop type"
      enumObject={ShipmentLocationType}
      value={isArray(value) ? (value as ShipmentLocationType[]) : []}
      onChange={(event, value) => {
        onChange(value);
      }}
      {...otherProps}
    />
  );
};

export default LocationTypeSelect;
