import {
  TripSortCriteria,
  GetAssetLinkingsQuery,
  TripAssetTypes,
  Scalars,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useRoles } from "../../account/Access/RoleGuard";
import driverLabel from "../../../utils/labels/driverLabel";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerLabel from "../../../utils/labels/trailerLabel";
import AssetLinkingFormModalContainer from "../AssetLinkingFormModal";
import { useTranslation } from "react-i18next";

type AssetLinkingListItem = GetAssetLinkingsQuery["assetLinkings"]["data"][0];

export type AssetLinkingListProps = {
  query: QueryFn<AssetLinkingListItem, "assetLinkings", TripSortCriteria>;
  deleteMutation: DeleteMutation;
  onRefresh: () => void;
  assetType: TripAssetTypes;
  assetId: Scalars["ObjectId"];
};

export default function AssetLinkingsList({
  query,
  deleteMutation,
  onRefresh,
  assetType,
  assetId,
}: AssetLinkingListProps) {
  const { t } = useTranslation("assets");
  const [formOpen, setFormOpen] = useState(false);
  const [selectedAssetLinking, setSelectedAssetLinking] =
    useState<AssetLinkingListItem | null>(null);

  const roles = useRoles();

  const hasWriteAccess = roles.some((role) =>
    ["Carrier Admin", "Manager", "Dispatcher"].includes(role)
  );

  const fields: TableField<AssetLinkingListItem>[] = [
    {
      value: (linking) => driverLabel(linking.driver),
      label: t("driver.one", "Driver"),
      type: "string",
    },
    {
      value: (linking) => tractorLabel(linking.tractor),
      label: t("tractor.one", "Tractor"),
      type: "string",
    },
    {
      value: (linking) => trailerLabel(linking.trailer),
      label: t("trailer.one", "Trailer"),
      type: "string",
    },
    {
      value: "startDate",
      label: t("assetLinkingColumns.startDateAndTime", "Start Date & Time"),
      type: "datetime",
    },
    {
      value: "endDate",
      label: t("assetLinkingColumns.endDateAndTime", "End Date & Time"),
      type: "datetime",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          id="new-assetLinking-button"
          onClick={() => {
            setFormOpen(true);
            setSelectedAssetLinking(null);
          }}
        >
          {t("linkAssets", "Link Assets")}
        </Button>
      </Box>
      <SmartLynksTable
        query={query}
        dataKey="assetLinkings"
        id="assetLinkings-table"
        fields={fields}
        onRecordClick={(assetLinking) => {
          if (!hasWriteAccess) return;
          setFormOpen(true);
          setSelectedAssetLinking(assetLinking);
        }}
        disableSearch
        deleteMutation={hasWriteAccess ? deleteMutation : undefined}
      />
      <AssetLinkingFormModalContainer
        isOpen={formOpen}
        assetLinkingId={selectedAssetLinking?._id}
        onSaved={() => {
          setFormOpen(false);
          setSelectedAssetLinking(null);
          onRefresh();
        }}
        onClose={() => {
          setFormOpen(false);
          setSelectedAssetLinking(null);
        }}
        selectedDriverId={
          assetType === TripAssetTypes.Driver ? assetId : undefined
        }
        selectedTrailerId={
          assetType === TripAssetTypes.Trailer ? assetId : undefined
        }
        selectedTractorId={
          assetType === TripAssetTypes.Tractor ? assetId : undefined
        }
      />
    </div>
  );
}
