import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  Route,
  Navigate,
  useLocation,
  useNavigate,
  Outlet,
  useOutletContext,
  createBrowserRouter,
  createRoutesFromElements,
  useRouteError,
} from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  Drawer,
  IconButton,
  useMediaQuery,
  Theme,
  Stack,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CreateShipment from "./shipment/CreateShipment";
import DrawerMenu from "../components/common/DrawerMenu";
import Shipments from "./shipment/Shipments";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ViewShipment from "./shipment/ViewShipment";
import CreateTrip from "./trip-planning/CreateTrip";
import ViewTrip from "./trip-planning/ViewTrip";
import EditShipment from "./shipment/EditShipment";
import GenerateTrips from "./trip-planning/GenerateTrips";
import GlobalDialog from "../components/common/GlobalDialog";
import GlobalToast from "../components/common/GlobalToast";
import Customers from "./asset-management/customers/Customers";
import CreateCustomer from "./asset-management/customers/CreateCustomer";
import ViewCustomer from "./asset-management/customers/ViewCustomer";
import EditCustomer from "./asset-management/customers/EditCustomer";
import BusinessLocations from "./asset-management/business-locations/BusinessLocations";
import ViewBusinessLocation from "./asset-management/business-locations/ViewBusinessLocation";
import CreateBusinessLocation from "./asset-management/business-locations/CreateBusinessLocation";
import EditBusinessLocation from "./asset-management/business-locations/EditBusinessLocation";
import isString from "lodash/isString";
import LiveTracker from "./operational-dashboard/LiveTracker";
import Tractors from "./asset-management/tractors/Tractors";
import ViewTractor from "./asset-management/tractors/ViewTractor";
import CreateTractor from "./asset-management/tractors/CreateTractor";
import EditTractor from "./asset-management/tractors/EditTractor";
import ViewDriver from "./asset-management/drivers/ViewDriver";
import CreateDriver from "./asset-management/drivers/CreateDriver";
import EditDriver from "./asset-management/drivers/EditDriver";
import Trailers from "./asset-management/trailers/Trailers";
import ViewTrailer from "./asset-management/trailers/ViewTrailer";
import EditTrailer from "./asset-management/trailers/EditTrailer";
import CreateTrailer from "./asset-management/trailers/CreateTrailer";
import Billing from "./accounting/Billing";
import Users from "./account/Users";
import CreateUser from "./account/CreateUser";
import EditUser from "./account/EditUser";
import ViewUser from "./account/ViewUser";
import GenerateReport from "./reporting/GenerateReport";
import ViewShipmentTracker from "./shipment/ViewShipmentTracker";
import ErrorMessage from "../components/common/ErrorMessage/ErrorMessage";
import { Error } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import ViewExtensions from "./extensions/ViewExtensions";
import DownloadApps from "./driver/DownloadApps";
import ManageOrgSettings from "./account/ManageOrgSettings";
import Register from "./account/Register";
import SubscriptionInfoBanner from "../components/account/SubscriptionInfoBanner";
import SegmentProvider from "../components/account/SegmentProvider";
import LoadBoard from "./load-board/LoadBoard";
import Planning from "./trip-planning/Planning";
import Carriers from "./asset-management/carriers/Carriers";
import ViewCarrier from "./asset-management/carriers/ViewCarrier";
import CreateCarrier from "./asset-management/carriers/CreateCarrier";
import EditCarrier from "./asset-management/carriers/EditCarrier";
import ViewPayto from "./asset-management/payto/ViewPayto";
import CreatePayto from "./asset-management/payto/CreatePayto";
import EditPayto from "./asset-management/payto/EditPayto";
import Settlements from "./accounting/Settlements";
import ViewSettlement from "./accounting/ViewSettlement";
import GoodProfiles from "./commodity-management/good-profilles/GoodProfiles";
import ViewGoodProfile from "./commodity-management/good-profilles/ViewGoodProfile";
import CreateGoodProfile from "./commodity-management/good-profilles/CreateGoodProfile";
import EditGoodProfile from "./commodity-management/good-profilles/EditGoodProfile";
import Suppliers from "./asset-management/suppliers/Suppliers";
import ViewSupplier from "./asset-management/suppliers/ViewSupplier";
import CreateSupplier from "./asset-management/suppliers/CreateSupplier";
import EditSupplier from "./asset-management/suppliers/EditSupplier";
import DriverShifts from "./driver-shifts/driver-shifts/DriversShifts";
import ViewOrder from "./order/ViewOrder";
import EditOrder from "./order/EditOrder";
import CustomerPortalHome from "./customer-portal/CustomerPortalHome";
import CustomerCreateOrder from "./customer-portal/CustomerCreateOrder";
import ViewOrganizationInfos from "./organization/ViewOrganizationInfos";

import trueLogo from "../assets/icons/true-logo.svg";
import Relationships from "./relationships/relationships/Relationships";
import { useTranslation } from "react-i18next";
import CreateReceiverForecast from "./forecasting/CreateReceiverForecast";
import ViewReceiverForecast from "./forecasting/ViewReceiverForecast";
import EditReceiverForecast from "./forecasting/EditReceiverForecast";
import ReceiverForecasts from "./forecasting/ReceiverForecasts";
import { ConfirmProvider } from "material-ui-confirm";

export const NAVBAR_HEIGHT = 130;

const Content = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingTop: theme.spacing(3),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  marginLeft: 10,
  // Make the content only scroll
  boxSizing: "border-box",
  overflow: "auto",
}));

type NavigationOptions = {
  title?: string | JSX.Element;
  showBackIcon?: boolean;
  headerSize?: "small" | "medium";
};

type NavigationOptionsContext = {
  setNavigationOptions: (options: NavigationOptions) => void;
};

const ViewCustomerPortal = () => {
  const { t } = useTranslation("orders");
  return (
    <Stack>
      <Box
        sx={{
          height: 130,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            ml: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: NAVBAR_HEIGHT - 24,
            }}
          >
            <Box>
              <Link href="/customer-portal" underline="none">
                <img alt="TrueTMS Logo" src={trueLogo} style={{ width: 150 }} />
              </Link>
            </Box>
          </Box>
          <Typography
            variant={"h5"}
            sx={{
              ml: 2,
              fontWeight: "600",
              flex: 1,
              textAlign: "center",
            }}
          >
            {t("orders:title", "Customer Portal")}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Outlet />
    </Stack>
  );
};

const HeaderNavigation = () => {
  const { t } = useTranslation(["common", "orders"]);

  const titleByPath: { [key: string]: string | undefined } = useMemo(
    () => ({
      "/dashboard": t("dashboard"),
      "/reports": t("reports"),
      "/loads": t("orders"),
      "/loads/new": t("newOrder"),
      "/trips": t("trips"),
      "/carrier-trips": t("trips"),
      "/trips/new": t("newTrip"),
      "/trips/generate": t("generateTrip"),
      "/customers": t("customers"),
      "/customers/new": t("newCustomer"),
      "/business-locations": t("locations"),
      "/carriers": t("carriers"),
      "/carriers/new": t("newCarrier"),
      "/payto/new": t("newPayToProfile"),
      "/business-locations/new": t("newLocation"),
      "/tractors": t("tractors"),
      "/tractors/new": t("newTractor"),
      "/trailers": t("trailers"),
      "/trailers/new": t("newTrailer"),
      "/drivers/new": t("newDriver"),
      "/users": t("users"),
      "/users/new": t("newUser"),
      "/tracker": t("liveTracker"),
      "/accounting": t("accounting"),
      "/organization-settings": t("settings"),
      "/extensions": t("extensions"),
      "/billing": t("billing"),
      "/forecasting": t("forecasting"),
      "/forecasting/new": t("forecasting"),
      "/organization-info": t("companyInfo", "Company Information"),
    }),
    [t]
  );

  const location = useLocation();

  const [navigationOptions, setNavigationOptions] = useState<NavigationOptions>(
    {
      title: titleByPath[location.pathname] || "",
      showBackIcon: false,
      headerSize: "medium",
    }
  );
  const navigate = useNavigate();
  const mobileDrawerRef = useRef<HTMLDivElement | null>(null);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useLayoutEffect(() => {
    setNavigationOptions({
      title: titleByPath[location.pathname] || "",
      showBackIcon: false,
    });
  }, [location, titleByPath]);

  useEffect(() => {
    if (isString(navigationOptions.title)) {
      if (navigationOptions.title) {
        document.title = `${navigationOptions.title} | TrueTMS`;
      } else {
        document.title = "TrueTMS";
      }
    }
  }, [navigationOptions?.title]);

  // Open the mobile menu if a tour card is targeting it
  useEffect(() => {
    window.addEventListener("productfruits_ready", function () {
      window.productFruits?.api.tours.listen(
        "tour-advanced",
        (tourId: string, params: { currentCardId: string }) => {
          console.debug("tour advanced", tourId, params);
          const tour =
            // @ts-ignore
            window.productFruits?.toursFactory?.toursConfig?.tours?.find(
              // @ts-ignore
              (t) => t.id === tourId
            );
          if (!tour) {
            console.error("Tour not found", tourId);
          }
          // @ts-ignore
          const card = tour?.cards?.find((c) => c.id === params.currentCardId);
          if (!card) {
            console.error("Card not found", params.currentCardId);
          }
          const htmlElement = card.elements
            // @ts-ignore
            .map((e) => document.querySelector(e.query))
            .find(Boolean);

          console.log(htmlElement, mobileDrawerRef.current);
          if (mobileDrawerRef.current?.contains(htmlElement)) {
            console.debug("Tour contains element in mobile drawer, opening it");
            setMobileOpen(true);
          }
        }
      );
    });
  }, []);

  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // set drawer and navbar width to 0 if current route is /planning
  const drawerWidth = location.pathname === "/planning" ? 0 : 260;
  const navbarHeight = navigationOptions.headerSize === "small" ? 50 : 130;

  return (
    // We place the confirm provider here because some confirm content
    // needs access to the router context, but the router provider does not
    // accept children
    <ConfirmProvider>
      <Box sx={{ height: "100%" }}>
        <SubscriptionInfoBanner />
        <Box
          sx={{
            width: { sm: drawerWidth },
          }}
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
            }}
            ref={mobileDrawerRef}
          >
            {isSmDown ? (
              <DrawerMenu onItemClick={() => handleDrawerToggle()} />
            ) : null}
          </Drawer>
          <Drawer
            variant="permanent"
            open={true}
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              "& .MuiDrawer-paper": {
                width: { md: drawerWidth },
              },
            }}
          >
            {isSmDown ? null : (
              <DrawerMenu onItemClick={() => handleDrawerToggle()} />
            )}
          </Drawer>
        </Box>
        <Box
          sx={{
            height: "100%",
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
            pb: { xs: 12, md: 0 },
          }}
        >
          <Box
            sx={{
              height: navbarHeight,
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                ml: 2,
              }}
            >
              {navigationOptions.showBackIcon ? (
                <Button
                  sx={{
                    height:
                      navigationOptions.headerSize === "small"
                        ? "30px"
                        : "50px",
                    width:
                      navigationOptions.headerSize === "small"
                        ? "30px"
                        : "20px",
                    borderRadius: "15px",
                    minWidth:
                      navigationOptions.headerSize === "small"
                        ? "unset"
                        : undefined,
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon />
                </Button>
              ) : isSmDown ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  id="open-drawer-button"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ display: { md: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              ) : null}
              {isString(navigationOptions?.title) ? (
                <Typography
                  variant={
                    navigationOptions.headerSize === "small"
                      ? "subtitle1"
                      : "h5"
                  }
                  sx={{
                    ml: 2,
                    fontWeight: "600",
                  }}
                >
                  {navigationOptions?.title || "TrueTMS"}
                </Typography>
              ) : (
                navigationOptions?.title
              )}
            </Box>
          </Box>
          <Divider />
          <Content
            sx={{
              height: `calc(100% - ${navbarHeight}px)`,
            }}
          >
            <Outlet context={{ setNavigationOptions }} />
            <SegmentProvider />
          </Content>
        </Box>
        <GlobalToast />
        <GlobalDialog />
      </Box>
    </ConfirmProvider>
  );
};

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Error
        color="error"
        sx={{
          fontSize: 50,
          mb: 2,
        }}
      />
      <ErrorMessage
        message="An error occurred. Please contact support and refresh this page."
        error={error}
      />
    </Box>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={<HeaderNavigation />}
        errorElement={<ErrorBoundary />}
      >
        <Route path="/" element={<Navigate to="/loads" replace />} />
        <Route path="/register" element={<Register />} />
        <Route path="/loads" element={<Navigate replace to="/orders" />} />
        <Route
          path="/loads/new"
          element={<Navigate replace to="/orders/new" />}
        />
        <Route path="/loads/:id" element={<ViewOrder />} />
        <Route path="/loads/details/:id" element={<ViewOrder />} />
        <Route path="/loads/edit/:id" element={<EditOrder />} />
        <Route path="/orders" element={<Shipments />} />
        <Route path="/orders/new" element={<CreateShipment />} />
        <Route path="/orders/:id" element={<ViewShipment />} />
        <Route path="/orders/details/:id" element={<ViewShipment />} />
        <Route path="/orders/edit/:id" element={<EditShipment />} />

        <Route path="/trips/new" element={<CreateTrip />} />
        <Route path="/trips/generate" element={<GenerateTrips />} />
        <Route path="/trips/:id" element={<ViewTrip />} />
        <Route path="/carrier-trips/details/:id" element={<ViewTrip />} />
        <Route path="/trips/details/:id" element={<ViewTrip />} />
        <Route path="/load-board" element={<LoadBoard />} />
        <Route path="/planning" element={<Planning />} />

        <Route path="/good-profiles" element={<GoodProfiles />} />
        <Route path="/good-profiles/:id" element={<ViewGoodProfile />} />
        <Route
          path="/good-profiles/details/:id"
          element={<ViewGoodProfile />}
        />
        <Route path="/good-profiles/new" element={<CreateGoodProfile />} />
        <Route path="/good-profiles/:id/edit" element={<EditGoodProfile />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/customers/:id" element={<ViewCustomer />} />
        <Route path="/customers/details/:id" element={<ViewCustomer />} />
        <Route path="/customers/new" element={<CreateCustomer />} />
        <Route path="/customers/:id/edit" element={<EditCustomer />} />
        <Route path="/business-locations" element={<BusinessLocations />} />
        <Route
          path="/business-locations/:id"
          element={<ViewBusinessLocation />}
        />
        <Route
          path="/business-locations/details/:id"
          element={<ViewBusinessLocation />}
        />
        <Route
          path="/business-locations/new"
          element={<CreateBusinessLocation />}
        />
        <Route
          path="/business-locations/:id/edit"
          element={<EditBusinessLocation />}
        />
        <Route path="/suppliers" element={<Suppliers />} />
        <Route path="/suppliers/:id" element={<ViewSupplier />} />
        <Route path="/suppliers/details/:id" element={<ViewSupplier />} />
        <Route path="/suppliers/new" element={<CreateSupplier />} />
        <Route path="/suppliers/:id/edit" element={<EditSupplier />} />
        <Route path="/carriers" element={<Carriers />} />
        <Route path="/carriers/:id" element={<ViewCarrier />} />
        <Route path="/carriers/details/:id" element={<ViewCarrier />} />
        <Route path="/carriers/new" element={<CreateCarrier />} />
        <Route path="/carriers/:id/edit" element={<EditCarrier />} />
        <Route path="/payto" element={<Carriers />} />
        <Route path="/payto/details/:id" element={<ViewPayto />} />
        <Route path="/payto/new" element={<CreatePayto />} />
        <Route path="/payto/:id/edit" element={<EditPayto />} />
        <Route path="/drivers/:id" element={<ViewDriver />} />
        <Route path="/drivers/details/:id" element={<ViewDriver />} />
        <Route path="/drivers/new" element={<CreateDriver />} />
        <Route path="/drivers/:id/edit" element={<EditDriver />} />
        <Route path="/drivers/driver-shifts" element={<DriverShifts />} />
        <Route path="/relations" element={<Relationships />} />
        <Route path="/tractors" element={<Tractors />} />
        <Route path="/tractors/:id" element={<ViewTractor />} />
        <Route path="/tractors/details/:id" element={<ViewTractor />} />
        <Route path="/tractors/new" element={<CreateTractor />} />
        <Route path="/tractors/:id/edit" element={<EditTractor />} />
        <Route path="/trailers" element={<Trailers />} />
        <Route path="/trailers/:id" element={<ViewTrailer />} />
        <Route path="/trailers/details/:id" element={<ViewTrailer />} />
        <Route path="/trailers/new" element={<CreateTrailer />} />
        <Route path="/trailers/:id/edit" element={<EditTrailer />} />
        <Route path="/tracker" element={<LiveTracker />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/settlements" element={<Settlements />} />
        <Route path="/settlements/details/:id" element={<ViewSettlement />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/new" element={<CreateUser />} />
        <Route path="/users/:id" element={<ViewUser />} />
        <Route path="/users/details/:id" element={<ViewUser />} />
        <Route path="/users/:id/edit" element={<EditUser />} />
        {/* disabled temporarily, feature need to be revisited https://tecafrik.atlassian.net/browse/TTMS-565 */}
        {/* <Route path="/accounting" element={<Accounting />} /> */}
        <Route path="/reports" element={<GenerateReport />} />
        <Route path="/extensions" element={<ViewExtensions />} />
        <Route path="/organization-settings" element={<ManageOrgSettings />} />
        <Route path="*" />
        <Route
          path="/download"
          element={<DownloadApps />}
          errorElement={<ErrorBoundary />}
        />

        <Route path="/forecasting" element={<ReceiverForecasts />} />
        <Route
          path="/forecasting/:id/edit"
          element={<EditReceiverForecast />}
        />
        <Route
          path="/forecasting/details/:id"
          element={<ViewReceiverForecast />}
        />
        <Route path="/forecasting/new" element={<CreateReceiverForecast />} />
        <Route path="/organization-info" element={<ViewOrganizationInfos />} />
      </Route>
      <Route
        path="/tracking"
        element={<ViewShipmentTracker />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/customer-portal"
        element={<ViewCustomerPortal />}
        errorElement={<ErrorBoundary />}
      >
        <Route path="" element={<CustomerPortalHome />} />
        <Route path="orders/new" element={<CustomerCreateOrder />} />
      </Route>
    </>
  )
);

export function useNavigationOptions() {
  return useOutletContext<NavigationOptionsContext>();
}

export default router;
