import ShipmentsList, { ShipmentsListProps } from "./ShipmentsList";
import {
  useCancelShipmentSplitMutation,
  useGetOrganizationSettingsQuery,
  useGetShipmentListQuery,
  Role,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";

import RoleGuard from "../../account/Access/RoleGuard";
import { useNavigate } from "react-router-dom";

type ShipmentsListContainerProps = Omit<
  ShipmentsListProps,
  "query" | "onRefetch" | "orgSettings"
>;

export default function ShipmentsListContainer({
  selectable = true,
  ...props
}: ShipmentsListContainerProps) {
  const navigate = useNavigate();
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new settlement has been generated
  const useGetShipmentListQueryCustom: typeof useGetShipmentListQuery = (
    variables,
    options
  ) => {
    const query = useGetShipmentListQuery(variables, {
      ...options,
    });
    refetch = query.refetch;
    return query;
  };
  useGetShipmentListQueryCustom.fetcher = useGetShipmentListQuery.fetcher;
  useGetShipmentListQueryCustom.getKey = useGetShipmentListQuery.getKey;

  const { showDialog, hideDialog } = useDialog();

  const cancelSplitMutation = useCancelShipmentSplitMutation();

  const orgSettingsQuery = useGetOrganizationSettingsQuery();
  return (
    <RoleGuard
      roles={[
        Role.Accounting,
        "Carrier Admin",
        Role.Dispatcher,
        Role.Manager,
        Role.Clerical,
      ]}
    >
      <ShipmentsList
        query={useGetShipmentListQueryCustom}
        onRefetch={() => {
          refetch();
        }}
        onSplitCancel={async (shipmentId) => {
          showDialog({
            type: "primary",
            title: "Cancel Split",
            description: "Are you sure you want to cancel the split?",
            actions: [
              {
                type: "secondary",
                title: "No, keep the split",
              },
              {
                type: "primary",
                title: "Yes, cancel the split",
                onClick: async () => {
                  try {
                    await cancelSplitMutation.mutateAsync({
                      shipmentId,
                    });
                    hideDialog();
                    refetch();
                  } catch (error) {
                    showDialog({
                      type: "error",
                      title: "Error",
                      description: `Error cancelling the split. ${
                        (error as Error).message
                      }`,
                    });
                  }
                },
              },
            ],
          });
        }}
        orgSettings={orgSettingsQuery.data?.organizationSettings}
        onAutoPlan={(shipments) => {
          navigate("/planning", { state: { shipmentsToAutoPlan: shipments } });
        }}
        selectable={selectable}
        {...props}
      />
    </RoleGuard>
  );
}
