import { omit } from "lodash";
import {
  useAddOrgDocMutation,
  useDeleteOrgDocMutation,
  useEditOrgDocMutation,
  useMyDocumentsQuery,
} from "../../../graphql/generated";
import OrganizationDocumentsManagement from "./OrganizationDocumentsManagement";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default function OrganizationDocumentsManagementContainer() {
  const addOrgDocMutation = useAddOrgDocMutation();
  const editOrgDocMutation = useEditOrgDocMutation();
  const deleteOrgDocMutation = useDeleteOrgDocMutation();
  const myDocumentsQuery = useMyDocumentsQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["organization", "common"]);

  return (
    <OrganizationDocumentsManagement
      documents={myDocumentsQuery.data?.myDocuments || []}
      loading={myDocumentsQuery.isLoading}
      onAddDocument={async (document) => {
        try {
          await addOrgDocMutation.mutateAsync({
            newOrganizationDocumentData: {
              ...omit(document, "_id"),
            },
          });
          enqueueSnackbar(
            t("documentAddedSuccessfully", "Document added successfully"),
            {
              variant: "success",
            }
          );
          myDocumentsQuery.refetch();
        } catch (error) {
          enqueueSnackbar(t("errorAddingDocument", "Error adding document"), {
            variant: "error",
          });
        }
      }}
      onEditDocument={async (document) => {
        try {
          await editOrgDocMutation.mutateAsync({
            id: document._id || "",
            organizationDocumentData: {
              name: document.name,
              url: document.url,
            },
          });
          enqueueSnackbar(
            t("documentEditedSuccessfully", "Document edited successfully"),
            {
              variant: "success",
            }
          );
          myDocumentsQuery.refetch();
        } catch (error) {
          enqueueSnackbar(t("errorEditingDocument", "Error editing document"), {
            variant: "error",
          });
        }
      }}
      onDeleteDocument={async (documentId) => {
        try {
          await deleteOrgDocMutation.mutateAsync({
            id: documentId,
          });
          myDocumentsQuery.refetch();
          enqueueSnackbar(t("documentDeleted", "Document deleted"), {
            variant: "success",
          });
        } catch (error) {
          enqueueSnackbar(
            t("errorDeletingDocument", "Error deleting document"),
            {
              variant: "error",
            }
          );
        }
      }}
    />
  );
}
