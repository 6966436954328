import {
  Box,
  Button,
  Chip,
  ChipProps,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Assignment,
  AutoAwesome,
  CallMerge,
  CallSplit,
  ContentCopy,
  Star,
} from "@mui/icons-material";
import { first, flatten, last, uniq, without } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomFieldContext,
  GetOrganizationSettingsQuery,
  GetShipmentListQuery,
  ShipmentLocationType,
  ShipmentSortCriteria,
  Status,
  TripAssetTypes,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import { formatDateTimeForLocation } from "../../../utils/labels/formatDateTime";
import {
  EnumTableField,
  SortField,
  TableField,
} from "../../common/LynksTable/LynksTable";
import ShipmentLink from "../../common/ShipmentLink";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import AssignmentModalContainer from "../../trip/AssignmentModal";
import TripLink from "../../common/TripLink";
import LoadSplittingModalContainer from "../LoadSplittingModal";
import {
  shipmentLocationAddressLabel,
  shipmentLocationLabel,
} from "../../../utils/labels/shipmentLocationLabel";
import RoleGuard from "../../account/Access/RoleGuard";
import { useTranslation } from "react-i18next";
import statusLabel from "../../../utils/labels/statusLabel";
import goodUnitLabel from "../../../utils/labels/goodUnitsLabel";
import BestBuyBulkUpdateModalContainer from "../../best-buy/BestBuyBulkUpdateModal";
import AssetLink from "../../asset-management/AssetLink/AssetLink";

export type ShipmentListItem = GetShipmentListQuery["shipments"]["data"][0];

export const renderExtraLocations = (
  shipment: {
    route?: {
      locations?:
        | {
            locationType: ShipmentLocationType;
            shipper?: {
              name: string;
              address: {
                label: string;
              };
            } | null;
            receiver?: {
              name: string;
              address: {
                label: string;
              };
            } | null;
          }[]
        | null;
    } | null;
  },
  locationType: ShipmentLocationType
) => {
  const sameTypeLocations = shipment.route?.locations?.filter(
    (location) => location.locationType === locationType
  );
  if (sameTypeLocations?.length === 1) {
    return null;
  }
  const furthestLocation =
    locationType === ShipmentLocationType.Pickup
      ? first(shipment.route?.locations)
      : last(shipment.route?.locations);
  const extraDropoffLocations = without(sameTypeLocations, furthestLocation);
  if (!extraDropoffLocations.length) {
    return null;
  }
  return (
    <Tooltip
      title={extraDropoffLocations.map((location) => {
        const shipperOrReceiver =
          location?.locationType === ShipmentLocationType.Pickup
            ? location.shipper
            : location?.receiver;
        return (
          <Box>
            {shipperOrReceiver?.name} - {shipperOrReceiver?.address?.label}
          </Box>
        );
      })}
    >
      <Chip
        label={`+${extraDropoffLocations.length} other${
          extraDropoffLocations.length > 1 ? "s" : ""
        }`}
        color="honeyDew"
        sx={{
          fontWeight: "bold",
        }}
      />
    </Tooltip>
  );
};

export const shipmentStatusColors: Record<Status, ChipProps["color"]> = {
  ASSIGNED: "primary",
  ACTIVE: "primary",
  CANCELLED: "error",
  COMPLETE: "success",
  DELETED: "error",
  DELIVERED: "success",
  PENDING: "default",
  UNASSIGNED: "default",
  WAITING_FOR_APPROVAL: "default",
  WAITING_FOR_ASSIGNMENT: "default",
  PLANNED: "primary",
  IN_PROGRESS: "info",
  FORECASTED: "honeyDew",
  FORECASTED_LOCKED: "default",
  UNPLANNED: "warning",
};

export const sortFields: SortField<ShipmentSortCriteria>[] = [
  {
    criteria: ShipmentSortCriteria.ShipmentNumber,
    label: "orderNumber",
  },
  {
    criteria: ShipmentSortCriteria.PickupDate,
    label: "pickupDate",
  },
  {
    criteria: ShipmentSortCriteria.DropoffDate,
    label: "deliveryDate",
  },
  {
    criteria: ShipmentSortCriteria.PickupDistance,
    label: "pickupLocation",
  },
  {
    criteria: ShipmentSortCriteria.DropoffDistance,
    label: "deliveryLocation",
  },
  {
    criteria: ShipmentSortCriteria.Revenue,
    label: "revenue",
  },
  {
    criteria: ShipmentSortCriteria.RevenuePerMile,
    label: "revenuePerMile",
  },
];

export type ShipmentsListProps = {
  query: QueryFn<
    ShipmentListItem,
    "shipments",
    ShipmentSortCriteria,
    {
      status: Status | null;
    }
  >;
  onRefetch?: () => void;
  onSplitCancel?: (shipmentId: string) => void;
  readonly?: boolean;
  disableSearch?: boolean;
  disablePagination?: boolean;
  disableStatusTab?: boolean;
  excludePastShipments?: boolean;
  receiverId?: string;
  orgSettings?: GetOrganizationSettingsQuery["organizationSettings"];
  onFilteredShipmentsChange?: (shipments: ShipmentListItem[]) => void;
  onAutoPlan?: (orders: ShipmentListItem[]) => void;
  onSelect?: (selected: ShipmentListItem[]) => void;
  selectable?: boolean;
  tableId?: string;
  useClientSideFiltering?: boolean;
};

export enum ShipmentStatusTab {
  ALL,
  FORECASTED,
  UNASSIGNED,
  ASSIGNED,
  IN_PROGRESS,
  PAST,
  CANCELLED,
}

const statusTabToShipmentStatus: {
  [key in ShipmentStatusTab]: Status | null;
} = {
  [ShipmentStatusTab.ALL]: null,
  [ShipmentStatusTab.ASSIGNED]: Status.Assigned,
  [ShipmentStatusTab.FORECASTED]: Status.Forecasted,
  [ShipmentStatusTab.UNASSIGNED]: Status.WaitingForAssignment,
  [ShipmentStatusTab.IN_PROGRESS]: Status.InProgress,
  [ShipmentStatusTab.PAST]: Status.Complete,
  [ShipmentStatusTab.CANCELLED]: Status.Deleted,
};

export default function ShipmentsList({
  query,
  onRefetch,
  readonly,
  disableSearch,
  disablePagination,
  disableStatusTab,
  excludePastShipments,
  receiverId,
  onSplitCancel,
  orgSettings,
  onFilteredShipmentsChange,
  onAutoPlan,
  onSelect,
  selectable,
  tableId,
  useClientSideFiltering,
}: ShipmentsListProps) {
  const { t } = useTranslation([
    "orders",
    "common",
    "assets",
    "trailers",
    "trips",
    "planning",
  ]);
  const statusField: EnumTableField<ShipmentListItem, Status> = {
    value: (shipment) => shipment.status,
    valueLabel: statusLabel,
    label: t("status.label"),
    type: "enum",
    colors: shipmentStatusColors,
    sortBy: "status",
    values: Object.values(Status),
  };

  const fields: TableField<
    GetShipmentListQuery["shipments"]["data"][0],
    Status
  >[] = [
    {
      value: (shipment) => <ShipmentLink shipment={shipment} />,
      label: t("orderNumber"),
      type: "number",
      sortBy: "shipmentNumber",
    },
    {
      value: (shipment) =>
        shipment.trip ? <TripLink trip={shipment.trip} /> : null,
      label: t("tripNumber"),
      type: "number",
      sortBy: "trip.tripNumber",
    },
    statusField,
    {
      value: (shipment) => {
        return (shipment.shipmentLocations || [])
          .flatMap((location) =>
            location.shippedGoods?.map(
              (good) =>
                `${good.label} (${good.quantity} ${goodUnitLabel(good.unit)})`
            )
          )
          .join(", ");
      },
      label: t("commodities"),
      type: "string",
      sortBy: "shipmentLocations.shippedGoods.label",
    },
    {
      value: (shipment) => (
        <Typography variant="body1">
          {formatDateTimeForLocation(
            shipment.route?.firstPickupTime,
            shipment.route?.locations && shipment.route?.locations[0]
          )}
        </Typography>
      ),
      label: t("pickupDate"),
      type: "datetime",
      sortBy: "route.firstPickupTime",
    },
    {
      value: (shipment) => (
        <Typography variant="body1">
          {formatDateTimeForLocation(
            shipment.route?.lastDropoffTime,
            last(shipment.route?.locations)
          )}
        </Typography>
      ),
      label: t("deliveryDate"),
      type: "datetime",
      sortBy: "route.lastDropoffTime",
    },
    {
      value: (shipment) => {
        const lastDropoffLocation = last(shipment.route?.locations);
        return (
          <Typography variant="body1">
            {formatDateTimeForLocation(
              lastDropoffLocation?.timeWindows?.[0]?.fromDate,
              lastDropoffLocation
            )}{" "}
            -{" "}
            {formatDateTimeForLocation(
              lastDropoffLocation?.timeWindows?.[0]?.toDate,
              lastDropoffLocation
            )}
          </Typography>
        );
      },
      label: "Delivery Time Window",
      type: "datetime",
      sortBy: "shipmentLocations.timeWindows.fromDate",
    },
    {
      value: (shipment) =>
        shipmentLocationLabel(first(shipment.route?.locations)),
      subtitle: (shipment) =>
        shipmentLocationAddressLabel(first(shipment.route?.locations)),
      extra: (shipment) => {
        return renderExtraLocations(shipment, ShipmentLocationType.Pickup);
      },
      label: t("shipper"),
      type: "string",
      key: "shipper",
      sortBy: "shipmentLocations.name",
    },
    {
      value: (shipment) =>
        shipmentLocationLabel(last(shipment.route?.locations)),
      subtitle: (shipment) =>
        shipmentLocationAddressLabel(last(shipment.route?.locations)),
      extra: (shipment) => {
        return renderExtraLocations(shipment, ShipmentLocationType.DropOff);
      },
      label: t("consigneeReceiver"),
      type: "string",
      key: "receiver",
      sortBy: "shipmentLocations.name",
    },
    {
      value: (shipment) =>
        shipment.trip?.driverEntity ? (
          <AssetLink
            asset={shipment.trip.driverEntity}
            assetType={TripAssetTypes.Driver}
          />
        ) : shipment.trip?.carrierEntity ? (
          <AssetLink
            asset={shipment.trip.carrierEntity}
            assetType={TripAssetTypes.Carrier}
          />
        ) : null,
      label: `${t("trips:driver")} / ${t("common:carrier")}`,
      type: "string",
    },
    {
      value: (shipment) =>
        shipment.trip?.trailerEntity ? (
          <AssetLink
            asset={shipment.trip.trailerEntity}
            assetType={TripAssetTypes.Trailer}
          />
        ) : null,
      label: t("trailers:trailer"),
      type: "string",
    },
    {
      value: (shipment) =>
        shipment.trip?.tractorEntity ? (
          <AssetLink
            asset={shipment.trip.tractorEntity}
            assetType={TripAssetTypes.Tractor}
          />
        ) : null,
      label: t("trips:tractor"),
      type: "string",
    },
    {
      value: (shipment) =>
        shipment.trip?.driver
          ? t("trips:driver")
          : shipment.trip?.carrier
          ? t("common:carrier")
          : null,
      label: t("assets:assetType"),
      type: "string",
    },
    {
      value: (shipment) => (
        <Stack direction="row" spacing={1}>
          {shipment.tags?.map((tag) => (
            <Chip label={tag} />
          ))}
        </Stack>
      ),
      sortBy: "tags",
      label: t("common:tags"),
      type: "string",
    },
    {
      value: (shipment) => shipment.purchaseOrderNumbers?.join(","),
      label: t("orders:referenceNumberType.PURCHASE_ORDER_NUMBER"),
      type: "string",
      sortBy: "purchaseOrderNumbers",
    },
    {
      value: (shipment) => shipment.billOfLadingNumbers?.join(","),
      label: t("orders:referenceNumberType.BILL_OF_LADING_NUMBER"),
      type: "string",
      sortBy: "billOfLadingNumbers",
    },
    {
      value: (shipment) => shipment.ticketNumbers?.join(","),
      label: t("orders:referenceNumberType.TICKET_NUMBER"),
      type: "string",
      key: "ticketNumbers",
    },
    {
      value: (shipment) => shipment.referenceNumbers?.join(","),
      label: t("orders:referenceNumberType.REFERENCE_NUMBER"),
      type: "string",
      sortBy: "referenceNumbers",
    },
  ];

  const handleAssignment = () => {
    if (!selected.length) {
      if (showDialog) {
        showDialog({
          title: t("orders:alert.selectLoadFirst", "Select an order first"),
          description: t(
            "orders:alert.selectLoadFirstDesc",
            "You need to select an order first"
          ),
          type: "primary",
          actions: [
            {
              type: "primary",
              title: t("common:OK", "OK"),
            },
          ],
        });
      }
      return;
    }
    const trailerTypes = uniq(selected.map((shipment) => shipment.trailerType));
    if (trailerTypes.length > 1) {
      if (showDialog) {
        showDialog({
          title: t("orders:alert.mixedTrailer", "Mixed trailer types"),
          description: t(
            "orders:alert.mixedTrailerDesc",
            "You can't mix loads with different trailer types on the same trip"
          ),
          type: "primary",
          actions: [
            {
              type: "primary",
              title: t("common:OK", "OK"),
            },
          ],
        });
      }
      return;
    }
    const hasShipmentInProgress =
      selected.length > 1 &&
      selected.some((shipment) => shipment.status === Status.InProgress);
    if (
      hasShipmentInProgress &&
      !orgSettings?.dispatching?.canConsolidateInProgressShipments
    ) {
      if (showDialog) {
        showDialog({
          title: t("orders:alert.inProgress", "In progress shipments"),
          description: t(
            "orders:alert.inProgressDesc",
            "You can't consolidate in progress shipments"
          ),
          type: "primary",
          actions: [
            {
              type: "primary",
              title: t("common:OK", "OK"),
            },
          ],
        });
      }
      return;
    }
    openAssignmentModal();
  };

  const handleBestBuyUpdate = () => {
    if (!selected.length) {
      if (showDialog) {
        showDialog({
          title: t("orders:alert.selectLoadFirst", "Select an order first"),
          description: t(
            "orders:alert.selectLoadFirstDesc",
            "You need to select an order first"
          ),
          type: "primary",
          actions: [
            {
              type: "primary",
              title: t("common:OK", "OK"),
            },
          ],
        });
      }
      return;
    }
    const hasShipmentInProgress =
      selected.length > 1 &&
      selected.some((shipment) => shipment.status === Status.InProgress);

    const allPickupLocations = flatten(
      selected.map((s) =>
        s.shipmentLocations.filter(
          (sl) => sl.locationType === ShipmentLocationType.Pickup
        )
      )
    );
    const firstPickupLocation = allPickupLocations[0];
    const firstSupplierId = firstPickupLocation?.shippedGoods?.[0].supplierId;
    const firstShipperId = firstPickupLocation?.shipper?._id;
    const allSuppliersShippersMatch = allPickupLocations.every(
      (sl) =>
        sl.shippedGoods?.every((sg) => sg.supplierId === firstSupplierId) &&
        sl?.shipper?._id === firstShipperId
    );

    if (hasShipmentInProgress) {
      if (showDialog) {
        showDialog({
          title: t("orders:alert.bestBuyInProgress", "In progress shipments"),
          description: t(
            "orders:alert.bestBuyInProgressDesc",
            "You can't edit best buy options for orders in progress"
          ),
          type: "primary",
          actions: [
            {
              type: "primary",
              title: t("common:OK", "OK"),
            },
          ],
        });
      }
      return;
    }
    if (!allSuppliersShippersMatch) {
      if (showDialog) {
        showDialog({
          title: t(
            "orders:bestbuy.bulkUpdateSameSuppliersShiphhersErrorTitle",
            "Incorrect shipper/supplier combination"
          ),
          description: t(
            "orders:alert.bulkUpdateSameSuppliersShiphhersErrorDesc",
            "You must select orders with the same shipper/supplier"
          ),
          type: "primary",
          actions: [
            {
              type: "primary",
              title: t("common:OK", "OK"),
            },
          ],
        });
      }
      return;
    }
    openBestBuyModal();
  };

  const translatedSortFields = sortFields.map((field) => ({
    ...field,
    // @ts-expect-error
    label: t(field.label) as string,
  }));

  const [selected, setSelected] = useState<ShipmentListItem[]>([]);

  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);
  const [loadSplittingModalOpen, setLoadSplittingModalOpen] = useState(false);
  const [loadToSplit, setLoadToSplit] = useState<ShipmentListItem | null>(null);
  const [bestBuyModalOpen, setBestBuyModalOpen] = useState(false);
  const [statusTab, setStatusTab] = useState<ShipmentStatusTab>(
    ShipmentStatusTab.ALL
  );
  const { showDialog } = useDialog();

  const openAssignmentModal = () => setAssignmentModalOpen(true);
  const closeAssignmentModal = () => setAssignmentModalOpen(false);
  const openBestBuyModal = () => setBestBuyModalOpen(true);
  const closeBestBuyModal = () => setBestBuyModalOpen(false);
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {disableStatusTab ? null : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={statusTab}
            onChange={(event, value) => {
              setStatusTab(value);
            }}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab
              label={t("orders:filter.all", "All")}
              value={ShipmentStatusTab.ALL}
            />
            <Tab
              label={t("orders:filter.unassigned", "Unassigned")}
              value={ShipmentStatusTab.UNASSIGNED}
            />
            <Tab
              label={t("orders:filter.assigned", "Assigned")}
              value={ShipmentStatusTab.ASSIGNED}
            />
            <Tab
              label={t("orders:filter.inProgress", "In Progress")}
              value={ShipmentStatusTab.IN_PROGRESS}
            />
            <Tab
              label={t("orders:filter.forecasted", "Forecasted")}
              value={ShipmentStatusTab.FORECASTED}
            />
            <Tab
              label={t("orders:filter.complete", "Completed")}
              value={ShipmentStatusTab.PAST}
            />
            <Tab
              label={t("orders:filter.cancelled", "Cancelled")}
              value={ShipmentStatusTab.CANCELLED}
            />
          </Tabs>
        </Box>
      )}
      <Box
        sx={{
          height: "calc(100% - 122px)",
          mt: 2,
        }}
      >
        <SmartLynksTable
          query={query}
          additionalQueryVariables={{
            status: statusTabToShipmentStatus[statusTab],
            receiverId,
            excludePastShipments,
          }}
          dataKey="shipments"
          id={tableId || "shipments-table-2"}
          fields={fields}
          sortFields={translatedSortFields}
          detailsUrlPrefix={(row) =>
            (row.isFromSplit || row.isFromRotation) && row.parentShipment
              ? `/orders/details/${row.parentShipment._id}`
              : !row._id
              ? ""
              : `/orders/details/${row._id}`
          }
          selectable={selectable}
          onSelect={
            selectable
              ? onSelect ||
                ((data) => {
                  setSelected(data);
                })
              : undefined
          }
          disableSearch={disableSearch}
          disablePagination={disablePagination}
          bulkActionMenus={[
            {
              id: "assign",
              label: t("common:assign", "Assign"),
              onClick: handleAssignment,
              icon: <Assignment />,
            },
            {
              id: "best-buy",
              label: t("orders:bestbuy.title", "TrueSource"),
              onClick: handleBestBuyUpdate,
              icon: <Star />,
            },
          ]}
          actions={[
            {
              icon: <ContentCopy />,
              label: t("common:list.duplicate", "Duplicate"),
              tooltip: t("common:list.duplicate", "Duplicate"),
              onClick: (row) => {
                navigate(`/orders/new`, { state: { shipment: row._id } });
              },
              secondary: true,
            },
            {
              icon: <CallSplit />,
              label: t("common:list.split", "Split"),
              tooltip: t("orders:splitLoad", "Split the order"),
              secondary: true,
              onClick: (row) => {
                setLoadToSplit(row);
                setLoadSplittingModalOpen(true);
              },
              isApplicable: (row) => !row.isSplit,
            },
            {
              icon: <CallMerge />,
              label: t("common:list.cancelSplit"),
              tooltip: t("orders:cancelLoadSplit"),
              secondary: true,
              onClick: async (row) => {
                onSplitCancel?.(row._id);
              },
              isApplicable: (row) => !!row.isSplit,
            },
          ]}
          renderHeaderActions={() =>
            !readonly ? (
              <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
                <Stack direction="row" spacing={2} sx={{}}>
                  {onAutoPlan && (
                    <Button
                      sx={{
                        borderRadius: 16,
                      }}
                      variant="contained"
                      color="accent"
                      size="small"
                      onClick={() => {
                        if (
                          selected.some(
                            (shipment) =>
                              shipment.status !== Status.WaitingForAssignment
                          )
                        ) {
                          showDialog({
                            title: t("common:warning"),
                            description: t(
                              "planning:autoPlanModal.autoPlanOrdersStatusWarning",
                              "All selected orders must be unassigned before auto planning"
                            ),
                            type: "secondary",
                          });
                          return;
                        }

                        onAutoPlan?.(selected);
                      }}
                    >
                      <AutoAwesome />{" "}
                      {t("planning:autoPlanModal.title", "Auto Plan")}
                    </Button>
                  )}
                  <Button
                    href="/orders/new"
                    variant="contained"
                    color="secondary"
                    size="large"
                    id="new-load-button"
                  >
                    {t("orders:new", "New Order")}
                  </Button>
                </Stack>
              </RoleGuard>
            ) : null
          }
          customFieldContext={[CustomFieldContext.Shipment]}
          getRecordParentId={(record) => record.parentShipment?._id}
          onFilteredRecordsChange={onFilteredShipmentsChange}
          showGroups
          filterMode={useClientSideFiltering ? "client" : "server"}
          paginationMode={useClientSideFiltering ? "client" : "server"}
          sortingMode={useClientSideFiltering ? "client" : "server"}
        />
      </Box>

      <AssignmentModalContainer
        tripId={
          uniq(selected.map((shipment) => shipment.tripId)).length === 1
            ? selected[0].tripId
            : null
        }
        shipments={selected}
        open={assignmentModalOpen}
        onClose={(tripId) => {
          closeAssignmentModal();
          if (tripId) {
            navigate(`/trips/details/${tripId}`);
          }
        }}
      />

      {loadToSplit && (
        <LoadSplittingModalContainer
          shipment={loadToSplit}
          open={loadSplittingModalOpen}
          onClose={() => {
            setLoadSplittingModalOpen(false);
            onRefetch?.();
          }}
        />
      )}

      <BestBuyBulkUpdateModalContainer
        shipmentIds={selected.map((shipment) => shipment._id)}
        open={bestBuyModalOpen}
        onClose={closeBestBuyModal}
        onUpdated={() => {
          closeBestBuyModal();
          onRefetch?.();
        }}
      />
    </div>
  );
}
