import { Alert } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetDriverDetailsQuery,
  useDeleteDriverMutation,
  useEditDriverMutation,
  useEditOrgUserMutation,
  useGetDriverDetailsQuery,
  useGetOrgUserDetailsQuery,
  useGetRolesQuery,
  useUpdateAccountStatusMutation,
  useUpdatePasswordMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import { UserRole } from "../../account/UserList/UserList";
import LoadingOverlay from "../../common/LoadingOverlay";
import DriverDetails from "./DriverDetails";
import { useTranslation } from "react-i18next";

type DriverDetailsContainerProps = {
  driverId: string;
  onLoad?: (driver: GetDriverDetailsQuery["driverById"]) => void;
};

function DriverDetailsContainer({
  driverId,
  onLoad,
}: DriverDetailsContainerProps) {
  const { t } = useTranslation(["users", "common"]);
  const getDriverDetailsQuery = useGetDriverDetailsQuery({
    id: driverId,
  });
  const updatePasswordMutation = useUpdatePasswordMutation();
  const deleteDriverMutation = useDeleteDriverMutation();
  const editDriverMutation = useEditDriverMutation();
  const driver = getDriverDetailsQuery?.data?.driverById;
  const getUserQuery = useGetOrgUserDetailsQuery({
    id: driver?.userId || "",
  });
  const editUserMutation = useEditOrgUserMutation();
  const getRolesQuery = useGetRolesQuery();
  const updateAccountStatusMutation = useUpdateAccountStatusMutation();

  const user = getUserQuery.data?.orgUserById;
  const navigate = useNavigate();
  const { showDialog, hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (getDriverDetailsQuery.data?.driverById) {
      onLoad?.(getDriverDetailsQuery.data?.driverById);
    }
  }, [onLoad, getDriverDetailsQuery.data]);

  if (getDriverDetailsQuery.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (getDriverDetailsQuery.error) {
    return (
      <Alert severity="error">
        {t("common:error.fetchError", "An error occurred while fetching")}.{" "}
        {(getDriverDetailsQuery.error as Error).message || "Unknown error"}
      </Alert>
    );
  }
  if (!driver) {
    return (
      <Alert severity="error">{t("common:error.notFound", "Not found")}</Alert>
    );
  }
  return (
    <DriverDetails
      driver={driver}
      user={user || null}
      updatingPassword={updatePasswordMutation.isLoading}
      onPasswordUpdate={
        driver.userId
          ? async (password) => {
              try {
                if (!driver.userId) {
                  return;
                }
                await updatePasswordMutation.mutateAsync({
                  id: driver.userId,
                  temporaryPassword: password,
                });
                enqueueSnackbar(
                  t("passwordUpdated", "Password successfully updated")
                );
              } catch (error) {
                console.error(error);
                showDialog({
                  title: t("common:error.title", "Error"),
                  description:
                    t(
                      "error.updatePassword",
                      "An error occurred while updating the password."
                    ) + (error as Error).message || "Unknown error",
                });
              }
            }
          : null
      }
      onEdit={() => {
        navigate(`/drivers/${driverId}/edit`, {
          state: {
            includeAdminRole: user?.roles
              .map((r) => r.name)
              .includes(UserRole.CARRIER_ADMIN),
          },
        });
      }}
      onDelete={async () => {
        showDialog({
          title: t("common:error.dangerZone", "Danger zone"),
          description: t(
            "error.deleteDriverConfirmation",
            "Do you really want to delete this driver? This action cannot be undone."
          ),
          type: "error",
          actions: [
            {
              type: "error",
              title: t("common:error.yesDelete", "Yes, Delete"),
              onClick: async () => {
                try {
                  await deleteDriverMutation.mutateAsync({
                    id: driverId,
                  });
                  hideDialog();
                  navigate("/users");
                } catch (error) {
                  console.error(error);
                  showDialog({
                    title: t("common:error.title", "Error"),
                    description:
                      t(
                        "error.deleteDriverError",
                        "An error occurred while deleting the driver."
                      ) + (deleteDriverMutation.error as Error).message ||
                      t("common:error.unknownError", "Unknown error"),
                  });
                }
              },
            },
            {
              type: "primary",
              title: t("common:error.noCancel", "No, Cancel"),
            },
          ],
        });
      }}
      onRevokeAdmin={
        user && user?.roles.map((r) => r.name).includes(UserRole.CARRIER_ADMIN)
          ? async () => {
              showDialog({
                title: t("common:confirmation", "Confirmation"),
                description: t(
                  "error.revokeDriverAdminConfirmation",
                  "Do you really want to revoke admin access to this driver?"
                ),
                type: "primary",
                actions: [
                  {
                    type: "primary",
                    title: t("driver.yesRevokeAdmin", "Yes, Revoke Admin"),
                    onClick: async () => {
                      try {
                        if (!user?._id) {
                          return;
                        }
                        const roles = getRolesQuery.data?.roles;
                        await editUserMutation.mutateAsync({
                          id: user?._id,
                          editOrgUserData: {
                            roles: roles?.filter(
                              (role) => role.name === UserRole.CARRIER_DRIVER
                            ),
                          },
                        });
                        hideDialog();
                        navigate("/users");
                      } catch (error) {
                        console.error(error);
                        showDialog({
                          title: t("common:error.title", "Error"),
                          description:
                            t(
                              "error.revokeDriverAdminError",
                              "An error occurred while make the driver admin."
                            ) + (editUserMutation.error as Error).message ||
                            t("common:error.unknownError", "Unknown error"),
                        });
                      }
                    },
                  },
                  {
                    type: "primary",
                    title: t("common:error.noCancel", "No, Cancel"),
                  },
                ],
              });
            }
          : null
      }
      onMakeAdmin={
        user && !user?.roles.map((r) => r.name).includes(UserRole.CARRIER_ADMIN)
          ? async () => {
              showDialog({
                title: t("common:confirmation", "Confirmation"),
                description: t(
                  "error.makeDriverAdminConfirmation",
                  "Do you really want to make this driver an admin?"
                ),
                type: "primary",
                actions: [
                  {
                    type: "primary",
                    title: t("driver.yesMakeAdmin", "Yes, Make Admin"),
                    onClick: async () => {
                      try {
                        if (!user?._id) {
                          return;
                        }
                        const roles = getRolesQuery.data?.roles;
                        await editUserMutation.mutateAsync({
                          id: user?._id,
                          editOrgUserData: {
                            roles: roles?.filter(
                              (role) =>
                                role.name === UserRole.CARRIER_ADMIN ||
                                role.name === UserRole.CARRIER_DRIVER
                            ),
                          },
                        });
                        hideDialog();
                        navigate("/users");
                      } catch (error) {
                        console.error(error);
                        showDialog({
                          title: t("common:error.title", "Error"),
                          description:
                            t(
                              "error.makeDriverAdminError",
                              "An error occurred while make the driver admin."
                            ) + (editUserMutation.error as Error).message ||
                            t("common:error.unknownError", "Unknown error"),
                        });
                      }
                    },
                  },
                  {
                    type: "primary",
                    title: t("common:error.noCancel", "No, Cancel"),
                  },
                ],
              });
            }
          : null
      }
      onDocumentsUpdate={async (updatedDocuments) => {
        try {
          await editDriverMutation.mutateAsync({
            id: driverId,
            editDriverData: {
              documents: updatedDocuments,
            },
          });
          getDriverDetailsQuery.refetch();
        } catch (error) {
          console.error(error);
          showDialog({
            type: "error",
            title: t(
              "common:documents.errors.updateError",
              "Error while updating documents"
            ),
            description:
              (error as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      onUserAccountStatusUpdate={async (enabled) => {
        try {
          await updateAccountStatusMutation.mutateAsync({
            enabled,
            id: driver.userId || "",
          });
          getUserQuery.refetch();
          getDriverDetailsQuery.refetch();
          enqueueSnackbar(
            t(
              enabled ? "enabled" : "disabled",
              enabled ? "User enabled" : "User disabled"
            )
          );
        } catch (error) {
          enqueueSnackbar(
            t(
              "updateUserAccountStatus",
              "An error occurred while updating user status"
            ),
            {
              variant: "error",
            }
          );
        }
      }}
    />
  );
}

export default DriverDetailsContainer;
