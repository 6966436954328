import { NewBusinessEntityInput } from "../../../graphql/generated";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useTranslation } from "react-i18next";
import { forwardRef } from "react";

type ContactFormProps = {
  contact: NonNullable<NewBusinessEntityInput["contact"]> | null;
  onChange: (contact: NewBusinessEntityInput["contact"]) => void;
  showInvoiceInclusionCheckbox?: boolean;
  invoiceInclusionCheckboxLabel?: string;
  inputNamePrefix?: string;
  columnCount?: number;
};

export const ContactForm = ({
  contact,
  onChange,
  showInvoiceInclusionCheckbox,
  invoiceInclusionCheckboxLabel,
  inputNamePrefix = "contact",
  columnCount = 3,
}: ContactFormProps) => {
  const { t } = useTranslation(["business", "common"]);

  const onUpdate = (updates: Partial<typeof contact>) => {
    onChange({
      ...contact,
      ...updates,
    });
  };

  return (
    <Grid
      container
      direction="row"
      spacing={3}
      columns={(12 * columnCount) / 3}
    >
      <Grid item sm={4}>
        <TextField
          label={t("common:title", "Title")}
          fullWidth
          name={`${inputNamePrefix}.title`}
          value={contact?.title || ""}
          onChange={(event) => {
            onUpdate({
              title: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <TextField
          label={t("common:firstName", "First Name")}
          fullWidth
          name={`${inputNamePrefix}.firstname`}
          value={contact?.firstname || ""}
          onChange={(event) => {
            onUpdate({
              firstname: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <TextField
          label={t("common:lastName", "Last Name")}
          fullWidth
          name={`${inputNamePrefix}.lastname`}
          value={contact?.lastname || ""}
          onChange={(event) => {
            onUpdate({
              lastname: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <TextField
          label={t("common:email", "Email Address")}
          fullWidth
          name={`${inputNamePrefix}.email`}
          value={contact?.email || ""}
          onChange={(event) => {
            onUpdate({
              email: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <PhoneInput
          label={t("common:phoneNumber", "Phone Number")}
          fullWidth
          name={`${inputNamePrefix}.phoneNumber`}
          defaultCountry="US"
          value={contact?.phoneNumber || ""}
          style={{ flex: 1, minWidth: "25%" }}
          onChange={(phoneNumber) => {
            onUpdate({
              phoneNumber: phoneNumber || "",
            });
          }}
          inputComponent={MuiPhoneInput}
        />
      </Grid>

      <Grid item sm={4}>
        <TextField
          label={t("common:extensionNumber", "Extension Number")}
          fullWidth
          name={`${inputNamePrefix}.extensionNumber`}
          value={contact?.extensionNumber || ""}
          onChange={(event) => {
            onUpdate({
              extensionNumber: event.target.value,
            });
          }}
        />
      </Grid>

      <Grid item sm={4}>
        <TextField
          label={t("common:url", "Url Address")}
          fullWidth
          name={`${inputNamePrefix}.url`}
          value={contact?.url || ""}
          onChange={(event) => {
            onUpdate({
              url: event.target.value,
            });
          }}
        />
      </Grid>

      <Grid item sm={4}>
        <TextField
          label={t("common:faxNumber", "Fax Number")}
          fullWidth
          name={`${inputNamePrefix}.faxNumber`}
          value={contact?.faxNumber || ""}
          onChange={(event) => {
            onUpdate({
              faxNumber: event.target.value,
            });
          }}
        />
      </Grid>

      {showInvoiceInclusionCheckbox ? (
        <Grid item sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                value={contact?.includeInInvoicing || false}
                checked={contact?.includeInInvoicing || false}
                onChange={(event, checked) => {
                  onUpdate({
                    includeInInvoicing: checked,
                  });
                }}
              />
            }
            label={
              invoiceInclusionCheckboxLabel ||
              t("business:includeInInvoicing", "Include in invoicing")
            }
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export const MuiPhoneInput = forwardRef((props, ref) => {
  return <TextField {...props} inputRef={ref} />;
});
