import { useConfirm } from "material-ui-confirm";
import { AugmentedShipment } from "../../../components/trip-planning/PlanningTool/PlanningTool";
import {
  useAddTripMutation,
  useRemoveShipmentFromTripMutation,
} from "../../../graphql/generated";
import TripSeparationTool from "./TripSeparationTool";
import { useTranslation } from "react-i18next";

type TripSeparationToolProps = {
  shipments: AugmentedShipment[];
  onRefresh: (shipmentIds: string[]) => Promise<void>;
};

export default function TripSeparationToolContainer({
  shipments,
  onRefresh,
}: TripSeparationToolProps) {
  const removeShipmentFromTripMutation = useRemoveShipmentFromTripMutation();
  const addTripMutation = useAddTripMutation();
  const { t } = useTranslation(["trips", "common"]);
  const confirm = useConfirm();
  return (
    <TripSeparationTool
      onSubmit={async (removedShipments, tripId) => {
        try {
          await removeShipmentFromTripMutation.mutateAsync({
            shipmentIds: removedShipments,
            tripId,
          });
          await addTripMutation.mutateAsync({
            newTripData: {
              shipments: removedShipments,
            },
          });
          confirm({
            title: t("common:success", "Success"),
            description: t(
              "trips:tripsSeparationSuccess",
              "Trip for separated shipments created successfully"
            ),
            hideCancelButton: true,
          });
          onRefresh(removedShipments);
        } catch (error) {
          confirm({
            title: "Error",
            description: (error as Error | null)?.message || "Unknown error",
            titleProps: { color: "error" },
            hideCancelButton: true,
          });
        }
      }}
      shipments={shipments}
      isLoading={
        removeShipmentFromTripMutation.isLoading || addTripMutation.isLoading
      }
    />
  );
}
