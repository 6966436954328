import { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  NewDriverShiftInput,
  GetDriverShiftDetailsQuery,
  GetDriverListQuery,
  GetBusinessEntityListQuery,
} from "../../../graphql/generated";
import { capitalize, isArray, mergeWith, omit } from "lodash";
import driverShiftSchema from "./driverShiftSchema";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import EnumSelect from "../../common/EnumSelect";
import { TimePicker } from "@mui/x-date-pickers";
import driverLabel from "../../../utils/labels/driverLabel";
import { FormDivider } from "../../asset-management/BusinessEntityForm/BusinessEntityForm";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

type DriverShift = GetDriverShiftDetailsQuery["driverShiftById"];

type DriverShiftFormProps = {
  initialDriverShift?: DriverShift;
  drivers: GetDriverListQuery["drivers"]["data"];
  domiciles: GetBusinessEntityListQuery["businessEntities"]["data"];
  saving: boolean;
  onSave: (driverShift: NewDriverShiftInput) => void;
};
type PartialDriverShift = Partial<DriverShift>;
type DeepPartialDriverShift = {
  [key in keyof PartialDriverShift]: Partial<PartialDriverShift[key]>;
};

enum DayEnum {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}

export const dayEnumLabel = (day: DayEnum) => {
  return i18next.t(`common:day.${day}`);
};

const dayEnumToNumber: Record<DayEnum, number> = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

export const numberToDayEnum: Record<number, DayEnum> = {
  0: DayEnum.SUNDAY,
  1: DayEnum.MONDAY,
  2: DayEnum.TUESDAY,
  3: DayEnum.WEDNESDAY,
  4: DayEnum.THURSDAY,
  5: DayEnum.FRIDAY,
  6: DayEnum.SATURDAY,
};

const DriverShiftForm = ({
  initialDriverShift,
  saving,
  onSave,
  drivers,
  domiciles,
}: DriverShiftFormProps) => {
  const { t } = useTranslation(["users", "common"]);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<DriverShift> | null>(null);
  const [localDriverShift, setLocalDriverShift] = useState<
    Partial<NewDriverShiftInput>
  >(initialDriverShift || {});

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialDriverShift) => {
    setLocalDriverShift((localDriverShift) =>
      mergeWith({}, localDriverShift, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = driverShiftSchema.validate(
      omit(localDriverShift, "_id"),
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDriverShift]);

  const { cancelConfirm } = useConfirmBeforeLeave(localDriverShift);

  const isOvernightShift =
    localDriverShift?.startTime &&
    localDriverShift.endTime &&
    new Date().setHours(
      localDriverShift?.startTime.hour || 0,
      localDriverShift?.startTime.minute || 0
    ) >
      new Date().setHours(
        localDriverShift?.endTime.hour || 0,
        localDriverShift?.endTime.minute || 0
      );

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <FormDivider
            variant="fullWidth"
            text={t("users:driver.shift.details", "Shift Details")}
          />
          <ErrorMessage message={validationResult?.error?.message} />
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                label={t("users:driver.shift.label", "Label")}
                value={localDriverShift?.label || ""}
                onChange={(event) => {
                  onChange({ label: event.target.value });
                }}
                fullWidth
                error={!!getFieldError("label")}
                helperText={getFieldError("label")}
              />
            </Grid>
            <Grid item sm={12}>
              <EnumSelect
                enumObject={DayEnum}
                label={t("users:driver.shift.days", "Days")}
                required
                fullWidth
                multiple
                value={(localDriverShift?.days || []).map(
                  (day) => numberToDayEnum[day]
                )}
                error={!!getFieldError("days")}
                helperText={getFieldError("days")}
                optionLabel={dayEnumLabel}
                onChange={(event, value) => {
                  onChange({ days: value.map((day) => dayEnumToNumber[day]) });
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <TimePicker
                ampm={false}
                label={t("users:driver.shift.startTime", "Start Time")}
                value={
                  localDriverShift?.startTime
                    ? new Date(
                        new Date().setHours(
                          localDriverShift.startTime.hour,
                          localDriverShift.startTime.minute
                        )
                      )
                    : null
                }
                onChange={(value) => {
                  if (!value) {
                    return;
                  }
                  onChange({
                    startTime: {
                      hour: value.getHours(),
                      minute: value.getMinutes(),
                    },
                  });
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      error={!!getFieldError("startTime")}
                      required
                      helperText={getFieldError("startTime")}
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <TimePicker
                ampm={false}
                label={t("users:driver.shift.endTime", "End Time")}
                value={
                  localDriverShift?.endTime
                    ? new Date(
                        new Date().setHours(
                          localDriverShift.endTime.hour,
                          localDriverShift.endTime.minute
                        )
                      )
                    : null
                }
                onChange={(value) => {
                  if (!value) {
                    return;
                  }
                  onChange({
                    endTime: {
                      hour: value.getHours(),
                      minute: value.getMinutes(),
                    },
                  });
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      error={!!getFieldError("endTime")}
                      required
                      helperText={getFieldError("endTime")}
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                select
                label={t("users:driver.domicile", "Domicile")}
                value={localDriverShift?.domicileId || ""}
                onChange={(event) => {
                  onChange({
                    domicileId: event.target.value || null,
                  });
                }}
                fullWidth
                error={!!getFieldError("domicileId")}
                helperText={getFieldError("domicileId")}
              >
                <MenuItem value={""}>{t("common:all")}</MenuItem>
                {domiciles.map((domicile) => (
                  <MenuItem key={domicile._id} value={domicile._id}>
                    {domicile.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item sm={6}>
              <TextField
                label={capitalize(
                  t(
                    "users:driver.shift.driversDefaultsAll",
                    "Drivers (defaults to all)"
                  )
                )}
                select
                value={localDriverShift?.driverIds || []}
                onChange={(event) => {
                  onChange({
                    // @ts-ignore
                    driverIds: event.target.value as string[],
                  });
                }}
                fullWidth
                SelectProps={{
                  multiple: true,
                }}
                error={!!getFieldError("driverIds")}
                // helperText={getFieldError("driverIds")}
              >
                {drivers
                  .filter(
                    (driver) =>
                      !localDriverShift?.domicileId ||
                      driver.domicileId === localDriverShift.domicileId
                  )
                  .map((driver) => (
                    <MenuItem key={driver._id} value={driver._id}>
                      {driverLabel(driver)}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (!localDriverShift) {
              return;
            }
            if (validate()) {
              cancelConfirm();
              onSave(localDriverShift as NewDriverShiftInput);
            }
          }}
          id="saveDriverShiftButton"
        >
          {t("common:save", "Save")}
        </Button>
        {isOvernightShift ? (
          <Alert severity="warning">This shift crosses multiple days</Alert>
        ) : null}
      </Box>
    </Box>
  );
};

export default DriverShiftForm;
