import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AssetLinking, AssetLinkingInput } from "../../../graphql/generated";
import AssetLinkingForm, {
  AssetLinkingFormContainerProps,
} from "../AssetLinkingForm";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import assetLinkingSchema from "./assetLinkingSchema";
import { useTranslation } from "react-i18next";

export type AssetLinkingFormModalProps = Omit<
  AssetLinkingFormContainerProps,
  "assetLinking" | "onChange"
> & {
  assetLinking: AssetLinkingInput | null;
  onSubmit: (assetLinking: AssetLinkingInput) => void;
  isOpen: boolean;
  error: Error | null;
  onClose: () => void;
};

const AssetLinkingFormModal = ({
  assetLinking,
  onSubmit,
  isOpen,
  onClose,
  ...props
}: AssetLinkingFormModalProps) => {
  const [localAssetLinking, setLocalAssetLinking] =
    useState<AssetLinkingInput | null>(assetLinking);

  const [validationResult, setValidationResult] =
    useState<ValidationResult<AssetLinking> | null>(null);

  useEffect(() => {
    if (!localAssetLinking) {
      setLocalAssetLinking(assetLinking);
    }
  }, [assetLinking, localAssetLinking]);

  useEffect(() => {
    if (!isOpen) {
      setLocalAssetLinking(null);
    }
  }, [isOpen]);

  const { t } = useTranslation(["assets", "common"]);

  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogTitle>
        {t("assetLinkings.linkAssets", "Link to tractor/trailer")},
      </DialogTitle>

      <DialogContent>
        <ErrorMessage error={validationResult?.error || props.error} />
        <AssetLinkingForm
          assetLinking={localAssetLinking}
          onChange={(assetLinking) => {
            setLocalAssetLinking(assetLinking);
            if (validationResult) {
              setValidationResult(
                assetLinkingSchema.validate(assetLinking, { abortEarly: false })
              );
            }
          }}
          {...props}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose()}>{t("common:close", "Close")}</Button>
        <Button
          variant="contained"
          disabled={!localAssetLinking || !!validationResult?.error}
          onClick={() => {
            if (!localAssetLinking) {
              return;
            }
            const result = assetLinkingSchema.validate(localAssetLinking, {
              abortEarly: false,
            });
            if (result.error) {
              setValidationResult(result);
              return;
            }
            onSubmit(localAssetLinking);
          }}
        >
          {t("common:save", "Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssetLinkingFormModal;
