import { useMemo, useState } from "react";
import { AugmentedShipment } from "../../../components/trip-planning/PlanningTool/PlanningTool";
import { keyBy } from "lodash";
import { useDrop } from "react-dnd";
import { Box, Grid, Stack, Typography } from "@mui/material";
import ReorderableShipments from "../../../components/trip-planning/ReorderableShipments";
import { DraggableShipmentSummary } from "../../../components/shipment/ShipmentSummariesList/ShipmentSummariesList";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

type TripSeparationToolProps = {
  shipments: AugmentedShipment[];
  onSubmit: (removedShipemntIds: string[], tripId: string) => void;
  isLoading: boolean;
};

export default function TripSeparationTool({
  shipments,
  onSubmit,
  isLoading,
}: TripSeparationToolProps) {
  const [selectedShipmentIds, setSelectedShipmentIds] = useState<string[]>([]);
  const shipmentsById = useMemo(
    () => keyBy(shipments, (shipment) => shipment._id),
    [shipments]
  );
  const { t } = useTranslation(["trips", "common"]);
  const confirm = useConfirm();

  const [{ canDrop, isOver }, shipmentDropRef] = useDrop(
    () => ({
      accept: "Shipment",
      drop: (item: AugmentedShipment) =>
        setSelectedShipmentIds(selectedShipmentIds.concat(item._id)),
      canDrop(shipment, monitor) {
        if (selectedShipmentIds.length === shipments.length - 1) {
          return false;
        }
        return true;
      },
      collect(monitor) {
        return {
          canDrop: monitor.canDrop(),
          isOver: monitor.isOver(),
          item: monitor.getItem(),
        };
      },
    }),
    [selectedShipmentIds, shipmentsById]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid
        sx={{ display: "flex", flex: 1, overflow: "scroll" }}
        container
        columnSpacing={2}
      >
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "auto",
            position: "relative",
          }}
        >
          {shipments
            .filter((s) => !selectedShipmentIds.includes(s._id))
            .map((shipment) => (
              <Box key={shipment._id} borderRadius={3} overflow="hidden" my={2}>
                <DraggableShipmentSummary dragable shipment={shipment} />
              </Box>
            ))}
        </Grid>
        <Grid sx={{ display: "flex", flex: 1 }} item xs={6}>
          <Box
            ref={shipmentDropRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              position: "relative",
              pt: 1,
              mt: 3,
              borderStyle: "solid",
              borderColor: "rgba(104, 14, 21, 0.22)",
              borderWidth: 1,
              borderRadius: "10px",
              pl: 1,
              pr: 1,
            }}
            bgcolor={
              canDrop ? "honeyDew.main" : isOver ? "error.light" : "white"
            }
            data-testid="TripCreatorDropTarget"
          >
            {selectedShipmentIds.length === 0 ? (
              <Typography textAlign="center" variant="caption">
                {t("dropToSeparate", "Drop orders here to begin separation")}
              </Typography>
            ) : null}
            <Box
              sx={{
                height: "100%",
                overflow: "auto",
              }}
            >
              <ReorderableShipments
                shipments={selectedShipmentIds.map((id) => shipmentsById[id])}
                onChange={(reorderedShipments) =>
                  setSelectedShipmentIds(
                    reorderedShipments.map((shipment) => shipment._id)
                  )
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" mt={2}>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          variant="contained"
          onClick={() => {
            if (selectedShipmentIds.length === 0) {
              confirm({
                title: t("common:error.title", "Error"),
                description: t(
                  "error.noOrdersToSeparate",
                  "No orders selected to separate"
                ),
                hideCancelButton: true,
                titleProps: { color: "error" },
              });
              return;
            }
            if (!shipments[0].tripId) {
              confirm({
                title: t("common:error.title", "Error"),
                description: t("error.noTripFound", "No trip found"),
                hideCancelButton: true,
                titleProps: { color: "error" },
              });
              return;
            }

            onSubmit(selectedShipmentIds, shipments[0].tripId);
          }}
        >
          {t("separate", "Separate")}
        </LoadingButton>
      </Stack>
    </Box>
  );
}
