import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/system";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SnackbarProvider } from "notistack";

import { store } from "./redux/store";
import theme from "./theme";
import AuthContextProvider, {
  getOrganizationId,
  useAuth,
} from "./providers/AuthProvider";
import "./App.css";
import SetupAccount from "./views/account/SetupAccount";
import router from "./views/LayoutRoutes";
import ProductFruitsProvider from "./components/account/ProductFruitsProvider";
import LogRocketProvider from "./components/account/LogRocketProvider";
import LocaleProvider from "./components/account/LocaleProvider";
import StaticLocaleProvider from "./providers/LocaleProvider";
import { registerLicense } from "@syncfusion/ej2-base";
import { CircularProgress, Stack } from "@mui/material";
import { Language, useCheckSubscriptionQuery } from "./graphql/generated";
import { locale } from "./utils/labels/formatDateTime";
import { LicenseInfo } from "@mui/x-license";
import { frFR, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isPortalAccess } from "./utils/customer-portal/isPortalAccess";

registerLicense(
  `Mgo+DSMBaFt+QHJqVk1hXk5Hd0BLVGpAblJ3T2ZQdVt5ZDU7a15RRnVfRVxqSH5XckdrWntfcw==;Mgo+DSMBPh8sVXJ1S0R+X1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTn9ad0VnXX1Wc3FTRQ==;ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXlSf0RjXXtceHdSQmM=;MjU4Nzc4MkAzMjMxMmUzMDJlMzBjN3N6R2F3MFRlbVVLVWNJRGtDRGM2TVYva2QrSDNVZWFDVmNWak5icmNjPQ==;MjU4Nzc4M0AzMjMxMmUzMDJlMzBQekNoYkJPRWZwL1JJMTZKU21yVEdsZFo3M0VCaGhzWGIvYkdjeEZXdnlBPQ==;NRAiBiAaIQQuGjN/V0d+Xk9HfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Ud0xiWXtacnxRQ2Ff;MjU4Nzc4NUAzMjMxMmUzMDJlMzBEdlQyQWUydFNwaVdINXJyYlU1L0dmVWJYYWJWaTBNVThaWEVZM2RjZzBFPQ==;MjU4Nzc4NkAzMjMxMmUzMDJlMzBFSXVtQ0tPb1ZTYmQ4MjIxVHRtR0J5ckE4UkhQdGFVVVZ3R0JTMkJ4WmhVPQ==;Mgo+DSMBMAY9C3t2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXlSf0RjXXtceHFUT2M=;MjU4Nzc4OEAzMjMxMmUzMDJlMzBXaWRrKzFGNEg4OHVDWWNNbjU3ZzlTS0NrUVRXY0ZwY0dMeVlIZjZycjJZPQ==;MjU4Nzc4OUAzMjMxMmUzMDJlMzBrNlpwMGhnMDR6UmtHVWtBbGpWZnJaYW8wVG9ZQmlMOUlMMUtJYnZ2ekRBPQ==;MjU4Nzc5MEAzMjMxMmUzMDJlMzBEdlQyQWUydFNwaVdINXJyYlU1L0dmVWJYYWJWaTBNVThaWEVZM2RjZzBFPQ==`
);

LicenseInfo.setLicenseKey(
  "9f4ce913f6fb0965971a0462c95ff42cTz04NzEzMSxFPTE3NDI5ODQ1NDMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <DndProvider backend={HTML5Backend}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              localeText={
                StaticLocaleProvider.getLanguage() === Language.Fr
                  ? frFR.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  : undefined
              }
              adapterLocale={locale}
            >
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <AuthContextProvider>
                  <LocaleProvider>
                    <Suspense
                      fallback={
                        <Stack alignItems="center" justifyContent="center">
                          <CircularProgress />
                        </Stack>
                      }
                    />
                    <RootRouter />
                    {isPortalAccess() ? null : <SetupAccount />}
                    <ProductFruitsProvider />
                    <LogRocketProvider />
                  </LocaleProvider>
                </AuthContextProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </DndProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  );
}

function RootRouter() {
  const { userInfo } = useAuth();
  const checkSubscriptionQuery = useCheckSubscriptionQuery();
  const userOrganizationId = userInfo ? getOrganizationId(userInfo) : null;
  const hasPortalAccess = isPortalAccess();
  return (userOrganizationId && checkSubscriptionQuery.data?.isSubscribed) ||
    hasPortalAccess ? (
    <Suspense
      fallback={
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  ) : null;
}

export default App;
