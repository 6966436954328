import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  Divider,
  DividerProps,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import { ValidationResult } from "joi";
import {
  capitalize,
  flatMap,
  isArray,
  isEqual,
  mergeWith,
  omit,
  pickBy,
  snakeCase,
  uniq,
  without,
} from "lodash";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  BusinessEntityType,
  CustomFieldContext,
  NewBusinessEntityInput,
  NewBusinessEntityInputWithType,
  ShipmentReferenceNumberType,
  StorageFacilityUnit,
  TrailerType,
  AssetStatus,
} from "../../../graphql/generated";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import AddressInput from "../AddressInput";
import OpeningHoursForm from "../OpeningHoursForm/OpeningHoursForm";
import { v4 as uuidv4 } from "uuid";

import businessEntitySchema, {
  liquidBusinessEntitySchema,
} from "./businessEntitySchema";
import AddButton from "../../common/AddButton";
import { Add, Download, RemoveCircle } from "@mui/icons-material";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import EnumSelect from "../../common/EnumSelect";
import BusinessEntitySelectContainer from "../../shipment/ShipmentForm/BusinessEntitySelect";
import FeatureGuard, {
  Feature,
  useFeature,
} from "../../account/Access/FeatureGuard";
import LocaleProvider from "../../../providers/LocaleProvider";
import StorageFacilityFormContainer from "../StorageFacilityForm/StorageFacilityForm";
import { useTranslation } from "react-i18next";
import businessEntityLabelKey from "../../../utils/i18n/businessEntityLabelKey";
import enumLabel from "../../../utils/labels/enumLabel";
import ChipTagsInput from "../../common/ChipTagsInput/ChipTagsInput";
import CustomFieldsFormContainer from "../../extensions/CustomFieldsForm";
import GroupSelectContainer from "../GroupSelect";
import trailerTypeLabel from "../../../utils/labels/trailerTypeLabel";
import DocumentsUploadModalContainer from "../../common/DocumentsUploadModal";
import LabeledAddButton from "../../common/LabeledAddButton";
import { useSnackbar } from "notistack";
import { SelectBusinessEntity } from "../../shipment/ShipmentForm/BusinessEntitySelect/BusinessEntitySelect";

import { PREDEFINED_DOCUMENT_LABELS } from "../../shipment/ShipmentForm/ShipmentDocuments/ShipmentDocumentForm";
import { ContactForm } from "./ContactForm";
import { ReferenceNumberForm } from "./ReferenceNumberForm";

type PartialBusinessEntity = Partial<NewBusinessEntityInputWithType>;
export type DeepPartialBusinessEntity = Omit<
  {
    [key in keyof PartialBusinessEntity]: Partial<PartialBusinessEntity[key]>;
  },
  "storageFacilities"
> & {
  storageFacilities?: Partial<
    NonNullable<NewBusinessEntityInputWithType["storageFacilities"]>[0]
  >[];
};

export type BusinessEntityFormProps = {
  initialBusinessEntity?: NewBusinessEntityInputWithType;
  defaultType?: BusinessEntityType;
  availableTypes?: BusinessEntityType[];
  onSave: (businessEntity: NewBusinessEntityInputWithType) => void;
  saving: boolean;
  minimal?: boolean;
};

const businessEntityTypeToCustomFieldContext: Record<
  BusinessEntityType,
  CustomFieldContext[] | null
> = {
  [BusinessEntityType.Customer]: [CustomFieldContext.Customer],
  [BusinessEntityType.Broker]: [CustomFieldContext.Customer],
  [BusinessEntityType.Receiver]: [
    CustomFieldContext.Location,
    CustomFieldContext.Receiver,
  ],
  [BusinessEntityType.Shipper]: [
    CustomFieldContext.Location,
    CustomFieldContext.Shipper,
  ],
  [BusinessEntityType.Supplier]: [CustomFieldContext.Supplier],
  [BusinessEntityType.Carrier]: [CustomFieldContext.Carrier],
  [BusinessEntityType.PayToProfile]: null,
  [BusinessEntityType.GeneralLocation]: [CustomFieldContext.Location],
  [BusinessEntityType.Port]: [CustomFieldContext.Location],
  [BusinessEntityType.Terminal]: [CustomFieldContext.Location],
  [BusinessEntityType.TruckStop]: [CustomFieldContext.Location],
  [BusinessEntityType.Domicile]: [
    CustomFieldContext.Location,
    CustomFieldContext.Domicile,
  ],
};

export const FormDivider = (
  props: DividerProps & {
    text: string;
  }
) => (
  <Divider
    {...props}
    sx={{
      pt: 5,
      pb: 2,
      "::before": {
        borderColor: "primary.main",
      },
      "::after": {
        borderColor: "primary.main",
      },
    }}
  >
    <Chip
      color="primary"
      label={props.text}
      id={`form-divider-${snakeCase(props.text)}`}
      sx={{
        textTransform: "capitalize",
      }}
    />
  </Divider>
);

function BusinessEntityForm({
  initialBusinessEntity,
  defaultType,
  availableTypes,
  saving,
  onSave,
  minimal,
}: BusinessEntityFormProps) {
  const { t } = useTranslation(["business", "common", "assets", "orders"]);
  const [localBusinessEntity, setLocalBusinessEntity] =
    useState<PartialBusinessEntity>(
      initialBusinessEntity || {
        type: defaultType,
      }
    );
  const [validationResult, setValidationResult] =
    useState<ValidationResult<NewBusinessEntityInput> | null>(null);
  const [billingAddressSameAsPhysical, setBillingAddressSameAsPhysical] =
    useState(false);
  const [remitAddressSameAsBilling, setRemitAddressSameAsBilling] =
    useState(false);
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);
  const [activeBusinessEntityToImport, setActiveBusinessEntityToImport] =
    useState<SelectBusinessEntity | null | undefined>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { isAvailable: isRelationshipsAvailable } = useFeature(
    Feature.Relationships
  );
  const { isAvailable: isCommodityManagementAvailable } = useFeature(
    Feature.CommodityManagement
  );

  const validate = () => {
    const schema = isRelationshipsAvailable
      ? liquidBusinessEntitySchema
      : businessEntitySchema;
    const validationResult = schema.validate(
      omit(
        {
          ...localBusinessEntity,
          storageFacilities: localBusinessEntity?.storageFacilities?.map(
            (storageFacility) =>
              omit(storageFacility, "commodity", "defaultSupplier")
          ),
        },
        "_id",
        "addressTimezone",
        "defaultShipper"
      ),
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialBusinessEntity) => {
    setLocalBusinessEntity((localBusinessEntity) =>
      mergeWith({}, localBusinessEntity, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localBusinessEntity]);

  useEffect(() => {
    if (
      billingAddressSameAsPhysical &&
      !isEqual(
        omit(localBusinessEntity?.address, "label", "coordinates"),
        omit(localBusinessEntity?.billingAddress, "label", "coordinates")
      )
    ) {
      onChange({
        billingAddress: omit(
          localBusinessEntity?.address,
          "label",
          "coordinates"
        ),
      });
    }
  }, [
    billingAddressSameAsPhysical,
    localBusinessEntity?.address,
    localBusinessEntity?.billingAddress,
    onChange,
  ]);

  useEffect(() => {
    if (
      remitAddressSameAsBilling &&
      !isEqual(
        localBusinessEntity?.billingAddress,
        localBusinessEntity?.remitAddress
      )
    ) {
      onChange({
        remitAddress: localBusinessEntity?.billingAddress,
      });
    }
  }, [
    remitAddressSameAsBilling,
    localBusinessEntity?.billingAddress,
    localBusinessEntity?.remitAddress,
    onChange,
  ]);

  const { cancelConfirm } = useConfirmBeforeLeave(localBusinessEntity);

  const localBusinessEntityTypes = [
    localBusinessEntity?.type,
    ...(localBusinessEntity?.additionalTypes || []),
  ].filter(Boolean) as BusinessEntityType[];

  const initialBusinessEntityTypes = [
    initialBusinessEntity?.type,
    ...(initialBusinessEntity?.additionalTypes || []),
  ].filter(Boolean) as BusinessEntityType[];

  const isBusinessOneOfType = (types: BusinessEntityType[]) =>
    types.some((type) => localBusinessEntityTypes.includes(type));

  const customFieldContexts = uniq(
    flatMap(
      localBusinessEntityTypes,
      (type: BusinessEntityType) => businessEntityTypeToCustomFieldContext[type]
    )
  ).filter(Boolean) as CustomFieldContext[];

  return (
    <Box id="businessEntityForm">
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <FormDivider
            variant="fullWidth"
            text={t("business:details", "Business Details")}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label={t("business:name", "Name")}
                required
                fullWidth
                name="name"
                value={localBusinessEntity?.name || ""}
                error={!!getFieldError("name")}
                helperText={getFieldError("name")}
                onChange={(event) => {
                  onChange({
                    name: event.target.value,
                  });
                }}
              />
            </Grid>

            {localBusinessEntity?.type !== BusinessEntityType.Carrier ? (
              <Grid item xs={12} md={6}>
                <EnumSelect
                  label={t("business:types", "Types")}
                  fullWidth
                  required
                  enumObject={pickBy(
                    BusinessEntityType,
                    (type) =>
                      !availableTypes ||
                      availableTypes.includes(type) ||
                      initialBusinessEntityTypes.includes(type)
                  )}
                  optionLabel={(businessEntityType) =>
                    capitalize(
                      t("entityLabel", {
                        business: businessEntityLabelKey[businessEntityType],
                        defaultValue: enumLabel(businessEntityType),
                      })
                    )
                  }
                  name="additionalTypes"
                  value={localBusinessEntityTypes}
                  error={!!getFieldError("type")}
                  helperText={getFieldError("type")}
                  multiple
                  onChange={(event, value) => {
                    onChange({
                      type: (value[0] as BusinessEntityType) || null,
                      additionalTypes: (value as BusinessEntityType[]).slice(1),
                    });
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={6}>
                <EnumSelect
                  label={t("business:carrier.types", "Carrier Types")}
                  fullWidth
                  required
                  enumObject={TrailerType}
                  optionLabel={trailerTypeLabel}
                  name="carrierTypes"
                  value={localBusinessEntity.trailerTypes || []}
                  error={!!getFieldError("trailerTypes")}
                  helperText={getFieldError("trailerTypes")}
                  multiple
                  onChange={(event, value) => {
                    onChange({
                      trailerTypes: value,
                    });
                  }}
                />
              </Grid>
            )}
            <Grid item sm={12}>
              <EnumSelect
                label={t("assets:status", "Status")}
                name="status"
                fullWidth
                enumObject={AssetStatus}
                optionLabel={(status) => t(`common:statusTypes.${status}`)}
                error={!!getFieldError("status")}
                helperText={getFieldError("status")}
                value={localBusinessEntity?.status || AssetStatus.Active}
                onChange={(event, status) => {
                  if (!status) {
                    return;
                  }
                  onChange({
                    status,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={t("business:code", "Business code")}
                fullWidth
                required={!!initialBusinessEntity}
                placeholder={
                  !initialBusinessEntity
                    ? t("business:leaveEmpty", "Leave empty to autogenerate")
                    : ""
                }
                name="code"
                value={localBusinessEntity?.code || ""}
                error={!!getFieldError("code")}
                helperText={
                  getFieldError("code") ||
                  (!initialBusinessEntity
                    ? t("business:leaveEmpty", "Leave empty to autogenerate")
                    : "")
                }
                onChange={(event) => {
                  onChange({
                    code:
                      event.target.value || (initialBusinessEntity ? "" : null),
                  });
                }}
              />
            </Grid>
            <FeatureGuard feature={Feature.Relationships}>
              {isBusinessOneOfType([BusinessEntityType.Receiver]) ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <BusinessEntitySelectContainer
                      inputProps={{
                        label: t(
                          "business:receiver.parentCustomer",
                          "Parent Customer"
                        ),
                        required: true,
                        helperText: getFieldError("parentBusinessEntityId"),
                        error: !!getFieldError("parentBusinessEntityId"),
                      }}
                      businessEntityType={BusinessEntityType.Customer}
                      value={localBusinessEntity?.parentBusinessEntityId}
                      onChange={(businessEntityId) => {
                        onChange({
                          parentBusinessEntityId: businessEntityId,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <BusinessEntitySelectContainer
                      inputProps={{
                        label: t(
                          "business:receiver.defaultShipper",
                          "Default Shipper"
                        ),
                        required: true,
                        helperText: getFieldError("defaultShipperId"),
                        error: !!getFieldError("defaultShipperId"),
                      }}
                      businessEntityType={BusinessEntityType.Shipper}
                      value={localBusinessEntity?.defaultShipperId}
                      onChange={(businessEntityId) => {
                        onChange({
                          defaultShipperId: businessEntityId,
                        });
                      }}
                    />
                  </Grid>
                </>
              ) : null}
            </FeatureGuard>
            {localBusinessEntityTypes.includes(BusinessEntityType.Broker) ||
            localBusinessEntityTypes.includes(BusinessEntityType.Carrier) ? (
              <Grid item xs={12} md={6}>
                <TextField
                  label="MC#"
                  fullWidth
                  name="code"
                  value={localBusinessEntity?.mcNumber || ""}
                  error={!!getFieldError("mcNumber")}
                  helperText={getFieldError("mcNumber")}
                  onChange={(event) => {
                    onChange({
                      mcNumber:
                        event.target.value ||
                        (initialBusinessEntity ? "" : null),
                    });
                  }}
                />
              </Grid>
            ) : null}
            {localBusinessEntityTypes.includes(BusinessEntityType.Carrier) ? (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="SCAC"
                    fullWidth
                    name="code"
                    value={localBusinessEntity?.standardCarrierAlphaCode || ""}
                    error={!!getFieldError("standardCarrierAlphaCode")}
                    helperText={getFieldError("standardCarrierAlphaCode")}
                    onChange={(event) => {
                      onChange({
                        standardCarrierAlphaCode:
                          event.target.value ||
                          (initialBusinessEntity ? "" : null),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="DOT#"
                    fullWidth
                    name="code"
                    value={localBusinessEntity?.dotNumber || ""}
                    error={!!getFieldError("dotNumber")}
                    helperText={getFieldError("dotNumber")}
                    onChange={(event) => {
                      onChange({
                        dotNumber:
                          event.target.value ||
                          (initialBusinessEntity ? "" : null),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="FED ID#"
                    fullWidth
                    name="code"
                    value={localBusinessEntity?.federalId || ""}
                    error={!!getFieldError("federalId")}
                    helperText={getFieldError("federalId")}
                    onChange={(event) => {
                      onChange({
                        federalId:
                          event.target.value ||
                          (initialBusinessEntity ? "" : null),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BusinessEntitySelectContainer
                    inputProps={{
                      label: capitalize(
                        t("business:payToProfile.one", "Pay To Profile")
                      ),
                      required: false,
                    }}
                    businessEntityType={BusinessEntityType.PayToProfile}
                    allowAddress={false}
                    value={localBusinessEntity?.payToProfileId || null}
                    onChange={(payToProfileId) => {
                      onChange({
                        payToProfileId,
                      });
                    }}
                  />
                </Grid>
              </>
            ) : null}

            <Grid item sm={12}>
              <ChipTagsInput
                value={localBusinessEntity?.tags || []}
                onChange={(tags) => {
                  onChange({ tags });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <GroupSelectContainer
                value={localBusinessEntity?.groupIds || []}
                onChange={(groupIds) => {
                  onChange({ groupIds });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <FormDivider
            variant="fullWidth"
            text={t("contactDetails", "Contact Details")}
          />
          <ErrorMessage message={getFieldError("contact")} />
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12}>
              <ContactForm
                contact={localBusinessEntity?.contact || null}
                onChange={(contact) => {
                  onChange({
                    contact,
                  });
                }}
                inputNamePrefix="contact"
                columnCount={2}
              />
            </Grid>

            <FeatureGuard feature={Feature.CustomerPortal}>
              {isBusinessOneOfType([
                BusinessEntityType.Customer,
                BusinessEntityType.Receiver,
              ]) ? (
                <Grid item sm={6}>
                  <FormControlLabel
                    label={t("business:customer.portalAccess", "Portal Access")}
                    control={
                      <Checkbox
                        checked={!!localBusinessEntity?.accessToken}
                        onChange={(event, checked) => {
                          if (checked) {
                            onChange({
                              accessToken: uuidv4(),
                            });
                          } else {
                            onChange({
                              accessToken: null,
                            });
                          }
                        }}
                      />
                    }
                  />
                </Grid>
              ) : null}
            </FeatureGuard>

            {isBusinessOneOfType([BusinessEntityType.Receiver]) ? (
              <Grid item sm={12}>
                <Autocomplete
                  fullWidth
                  options={PREDEFINED_DOCUMENT_LABELS}
                  freeSolo
                  onChange={(e, shipmentDocumentsToReceive) => {
                    onChange({
                      shipmentDocumentsToReceive: (
                        shipmentDocumentsToReceive || []
                      ).map((s) => s.trim()),
                    });
                  }}
                  multiple
                  value={localBusinessEntity?.shipmentDocumentsToReceive || []}
                  size="small"
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions<string>();
                    const { inputValue } = params;
                    if (inputValue !== "") {
                      return filter(options, params).concat([inputValue]);
                    }

                    return filter(options, params);
                  }}
                  renderOption={(props, option) => {
                    if (PREDEFINED_DOCUMENT_LABELS.includes(option)) {
                      return <li {...props}>{option}</li>;
                    }
                    return <li {...props}>Add "{option}"</li>;
                  }}
                  renderInput={(inputProps) => (
                    <TextField
                      {...inputProps}
                      label={capitalize(
                        t(
                          "business:shipmentDocumentsToReceive",
                          "Shipment Documents To Receive"
                        )
                      )}
                      name="shipmentDocumentsToReceive"
                      error={!!getFieldError("shipmentDocumentsToReceive")}
                      helperText={getFieldError("shipmentDocumentsToReceive")}
                    />
                  )}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {!isBusinessOneOfType([BusinessEntityType.PayToProfile]) ? (
          <Grid item sm={12}>
            <FormDivider
              variant="fullWidth"
              text={t("business:physicalAddress", "Physical Address")}
            />
            <ErrorMessage message={getFieldError("address")} />

            <AddressInput
              required
              name="address"
              horizontal
              value={localBusinessEntity?.address || null}
              onChange={(address) => {
                if (!address) {
                  setLocalBusinessEntity({
                    ...localBusinessEntity,
                    address: undefined,
                  });
                  return;
                }
                onChange({ address });
              }}
              errors={{
                label: getFieldError("address.label"),
                coordinates: getFieldError("address.coordinates"),
                line1: getFieldError("address.line1"),
                line2: getFieldError("address.line2"),
                postalCode: getFieldError("address.postalCode"),
                state: getFieldError("address.state"),
                city: getFieldError("address.city"),
                country: getFieldError("address.country"),
              }}
            />
          </Grid>
        ) : null}
        {isBusinessOneOfType([
          BusinessEntityType.Customer,
          BusinessEntityType.Broker,
          BusinessEntityType.PayToProfile,
        ]) ? (
          <Grid
            item
            sm={6}
            sx={{
              display: minimal ? "none" : undefined,
            }}
          >
            <FormDivider
              variant="fullWidth"
              text={t("business:billingAddress", "Billing Address")}
            />
            <ErrorMessage message={getFieldError("billingAddress")} />

            {!isBusinessOneOfType([BusinessEntityType.PayToProfile]) ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={billingAddressSameAsPhysical}
                    onChange={(event, checked) =>
                      setBillingAddressSameAsPhysical(checked)
                    }
                    name="billingAddressSameAsPhysical"
                  />
                }
                sx={{
                  // Not using default spacing values
                  // so the 3 address inputs can align perfectly
                  pb: "38px",
                }}
                label={t(
                  "business:sameAsPhysicalAddress",
                  "Same as Physical Address"
                )}
              />
            ) : null}
            <AddressInput
              name="billingAddress"
              value={localBusinessEntity?.billingAddress || null}
              onChange={(address) =>
                onChange({
                  billingAddress: address,
                })
              }
              disableAutocomplete
              disabled={billingAddressSameAsPhysical}
              errors={{
                label: getFieldError("billingAddress.label"),
                coordinates: getFieldError("billingAddress.coordinates"),
                line1: getFieldError("billingAddress.line1"),
                line2: getFieldError("billingAddress.line2"),
                postalCode: getFieldError("billingAddress.postalCode"),
                state: getFieldError("billingAddress.state"),
                city: getFieldError("billingAddress.city"),
                country: getFieldError("billingAddress.country"),
              }}
            />
            <TextField
              label={t("business:billingEmail", "Billing Email")}
              sx={{ mt: 3 }}
              fullWidth
              error={!!getFieldError("billingEmail")}
              helperText={getFieldError("billingEmail")}
              name="billingEmail"
              value={localBusinessEntity?.billingEmail || ""}
              onChange={(event) => {
                onChange({
                  billingEmail: event.target.value,
                });
              }}
            />
            <TextField
              label={t("business:billingTerms", "Billing Terms")}
              sx={{ mt: 3 }}
              fullWidth
              error={!!getFieldError("billingTermsDay")}
              helperText={getFieldError("billingTermsDay")}
              name="billingTermsDay"
              value={localBusinessEntity?.billingTermsDay || ""}
              onChange={(event) => {
                onChange({
                  billingTermsDay: parseInt(event.target.value) || null,
                });
              }}
              inputProps={{
                type: "number",
                min: 1,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("business:days", {
                      count: Number(
                        localBusinessEntity?.billingTermsDay || "0"
                      ),
                    })}
                  </InputAdornment>
                ),
              }}
            />
            {isBusinessOneOfType([
              BusinessEntityType.Customer,
              BusinessEntityType.Broker,
            ]) ? (
              <Grid>
                {localBusinessEntity?.defaultReferenceNumbers?.length
                  ? localBusinessEntity.defaultReferenceNumbers.map(
                      (referenceNumber, index) => {
                        return (
                          <Grid
                            key={index.toString()}
                            container
                            direction="row"
                            alignItems="center"
                          >
                            <Grid item xs={11}>
                              <ReferenceNumberForm
                                onChange={(referenceNumber) => {
                                  onChange({
                                    defaultReferenceNumbers:
                                      localBusinessEntity?.defaultReferenceNumbers?.map(
                                        (rn, i) =>
                                          i === index ? referenceNumber : rn
                                      ),
                                  });
                                }}
                                value={referenceNumber}
                                businessEntity={localBusinessEntity}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                sx={{ mt: 3 }}
                                onClick={() =>
                                  onChange({
                                    defaultReferenceNumbers:
                                      localBusinessEntity!.defaultReferenceNumbers?.filter(
                                        (_, i) => index !== i
                                      ),
                                  })
                                }
                              >
                                <RemoveCircle />
                              </IconButton>
                            </Grid>
                          </Grid>
                        );
                      }
                    )
                  : null}

                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ ml: 1, mt: 3, display: "flex", alignItems: "center" }}
                >
                  <AddButton
                    sx={{ height: 50, width: 20 }}
                    onClick={() => {
                      onChange({
                        defaultReferenceNumbers: (
                          localBusinessEntity?.defaultReferenceNumbers || []
                        ).concat({
                          referenceNumberType:
                            ShipmentReferenceNumberType.BillOfLadingNumber,
                          referenceNumberValue: "",
                        }),
                      });
                    }}
                    id="add-reference-number-button"
                  >
                    <Add />
                  </AddButton>
                  <span> Add Reference Number</span>
                </Stack>
              </Grid>
            ) : null}
          </Grid>
        ) : null}

        {isBusinessOneOfType([
          BusinessEntityType.Customer,
          BusinessEntityType.Broker,
          BusinessEntityType.PayToProfile,
        ]) ? (
          <Grid
            item
            sm={6}
            sx={{
              display: minimal ? "none" : undefined,
            }}
          >
            <FormDivider
              variant="fullWidth"
              text={t("business:remitAddress", "Remit Address")}
            />
            <ErrorMessage message={getFieldError("remitAddress")} />

            <FormControlLabel
              control={
                <Checkbox
                  checked={remitAddressSameAsBilling}
                  onChange={(event, checked) =>
                    setRemitAddressSameAsBilling(checked)
                  }
                  name="remitAddressSameAsBilling"
                />
              }
              sx={{
                // Not using default spacing values
                // so the 3 address inputs can align perfectly
                pb: "38px",
              }}
              label={t("sameAsPhysicalAddress", "Same as Billing Address")}
            />
            <AddressInput
              name="remitAddress"
              value={localBusinessEntity?.remitAddress || null}
              onChange={(address) => {
                if (!address) {
                  onChange({
                    remitAddress: null,
                  });
                  return;
                }
                // If the user erases all remit address fields
                // consider it not set. This is so this customer will use
                // the global remit address
                if (
                  !address.line1 &&
                  !address.line2 &&
                  !address.city &&
                  !address.state &&
                  !address.postalCode &&
                  !address.country
                ) {
                  onChange({
                    remitAddress: null,
                  });
                  return;
                }
                onChange({ remitAddress: address });
              }}
              disableAutocomplete
              disabled={remitAddressSameAsBilling}
              errors={{
                label: getFieldError("remitAddress.label"),
                coordinates: getFieldError("remitAddress.coordinates"),
                line1: getFieldError("remitAddress.line1"),
                line2: getFieldError("remitAddress.line2"),
                postalCode: getFieldError("remitAddress.postalCode"),
                state: getFieldError("remitAddress.state"),
                city: getFieldError("remitAddress.city"),
                country: getFieldError("remitAddress.country"),
              }}
            />

            <TextField
              label={t("business:remitCompanyName", "Remit Company Name")}
              sx={{ mt: 3 }}
              fullWidth
              error={!!getFieldError("remitCompanyName")}
              helperText={getFieldError("remitCompanyName")}
              name="remitCompanyName"
              value={localBusinessEntity?.remitCompanyName || ""}
              onChange={(event) => {
                onChange({
                  remitCompanyName: event.target.value,
                });
              }}
            />

            <TextField
              label={t("business:remitEmail", "Remit Email")}
              sx={{ mt: 3 }}
              fullWidth
              error={!!getFieldError("remitEmail")}
              helperText={getFieldError("remitEmail")}
              name="remitEmail"
              value={localBusinessEntity?.remitEmail || ""}
              onChange={(event) => {
                onChange({
                  remitEmail: event.target.value,
                });
              }}
            />
          </Grid>
        ) : null}
      </Grid>

      {isBusinessOneOfType([
        BusinessEntityType.Shipper,
        BusinessEntityType.Receiver,
      ]) ? (
        <Box
          sx={{
            pt: 4,
            display: minimal ? "none" : "block",
          }}
        >
          <FormDivider
            variant="fullWidth"
            text={t("business:operatingHours", "Operating Hours")}
          />
          <ErrorMessage message={getFieldError("openingSchedules", true)} />
          <OpeningHoursForm
            value={localBusinessEntity?.openingSchedules || []}
            onChange={(openingSchedules) =>
              onChange({
                openingSchedules,
              })
            }
          />
        </Box>
      ) : null}

      <Box
        sx={{
          pt: 4,
          display: minimal ? "none" : "block",
        }}
      >
        <FormDivider
          variant="fullWidth"
          text={t("business:additionalContacts", "Additional Contacts")}
        />
        <ErrorMessage message={getFieldError("additionalContacts", true)} />
        {localBusinessEntity?.additionalContacts?.map((contact, index) => (
          <Fragment key={index}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ mb: 4, mt: 3, alignItems: "center" }}
            >
              <ContactForm
                contact={contact}
                onChange={(contact) => {
                  onChange({
                    additionalContacts: (
                      localBusinessEntity.additionalContacts || []
                    ).map((c, i) =>
                      index === i
                        ? {
                            ...c,
                            ...contact,
                          }
                        : c
                    ),
                  });
                }}
                inputNamePrefix="additionalContact"
                showInvoiceInclusionCheckbox={isBusinessOneOfType([
                  BusinessEntityType.Customer,
                  BusinessEntityType.Broker,
                  BusinessEntityType.Carrier,
                  BusinessEntityType.Receiver,
                ])}
                invoiceInclusionCheckboxLabel={
                  localBusinessEntity.type === BusinessEntityType.Customer
                    ? t(
                        "orders:documents.includeForInvoice",
                        "Include in invoicing"
                      )
                    : localBusinessEntity.type === BusinessEntityType.Carrier
                    ? t(
                        "orders:documents.includeForRatecon",
                        "Include in ratecon"
                      )
                    : localBusinessEntity.type === BusinessEntityType.Receiver
                    ? t(
                        "orders:documents.includeForOrderCompletion",
                        "Include in order completion"
                      )
                    : undefined
                }
              />
              <IconButton
                onClick={() =>
                  onChange({
                    additionalContacts: without(
                      localBusinessEntity.additionalContacts,
                      contact
                    ),
                  })
                }
              >
                <RemoveCircle />
              </IconButton>
            </Stack>
            <Divider />
          </Fragment>
        ))}
        <Stack
          direction="row"
          spacing={2}
          sx={{ m: 1, display: "flex", alignItems: "center" }}
        >
          <AddButton
            sx={{ height: 50, width: 20 }}
            onClick={() => {
              onChange({
                additionalContacts: (
                  localBusinessEntity?.additionalContacts || []
                ).concat({
                  firstname: "",
                  lastname: "",
                  email: "",
                  phoneNumber: "",
                  extensionNumber: "",
                  faxNumber: "",
                  url: "",
                }),
              });
            }}
            id="add-contact-button"
          >
            <Add />
          </AddButton>
          <span>{t("business:addContact", "Add contact")}</span>
        </Stack>
      </Box>

      {isBusinessOneOfType([
        BusinessEntityType.Broker,
        BusinessEntityType.Customer,
      ]) && !isCommodityManagementAvailable ? (
        <Box
          sx={{
            pt: 4,
            display: minimal ? "none" : "block",
          }}
        >
          <FormDivider variant="fullWidth" text="Commodity Inventory" />
          <ErrorMessage message={getFieldError("goodInventoryItems", true)} />
          {localBusinessEntity?.goodInventoryItems?.map((goodInventoryItem) => (
            <Stack direction="row" spacing={3} sx={{ mb: 2, mt: 1 }}>
              <TextField
                label="Label"
                fullWidth
                name="goodInventoryItem.label"
                value={goodInventoryItem.label || ""}
                onChange={(event) => {
                  onChange({
                    goodInventoryItems: (
                      localBusinessEntity.goodInventoryItems || []
                    ).map((c) =>
                      c === goodInventoryItem
                        ? {
                            ...c,
                            label: event.target.value,
                          }
                        : c
                    ),
                  });
                }}
              />
              <TextField
                label="Weight"
                fullWidth
                type="number"
                name="goodInventoryItem.weight"
                value={String(goodInventoryItem.weight) || ""}
                InputProps={{
                  endAdornment: LocaleProvider.getWeightUnit(),
                }}
                onChange={(event) => {
                  onChange({
                    goodInventoryItems: (
                      localBusinessEntity.goodInventoryItems || []
                    ).map((c) =>
                      c === goodInventoryItem
                        ? {
                            ...c,
                            weight: parseFloat(event.target.value),
                          }
                        : c
                    ),
                  });
                }}
              />

              <IconButton
                onClick={() =>
                  onChange({
                    goodInventoryItems: without(
                      localBusinessEntity.goodInventoryItems,
                      goodInventoryItem
                    ),
                  })
                }
              >
                <RemoveCircle />
              </IconButton>
            </Stack>
          ))}
          <Stack
            direction="row"
            spacing={2}
            sx={{ m: 1, display: "flex", alignItems: "center" }}
          >
            <AddButton
              sx={{ height: 50, width: 20 }}
              onClick={() => {
                onChange({
                  goodInventoryItems: (
                    localBusinessEntity?.goodInventoryItems || []
                  ).concat({
                    label: "",
                    weight: null,
                  }),
                });
              }}
              id="add-commodity-button"
            >
              <Add />
            </AddButton>
            <span> Add Commodity</span>
          </Stack>
        </Box>
      ) : null}

      <FeatureGuard feature={Feature.CommodityManagement}>
        {isBusinessOneOfType([BusinessEntityType.Receiver]) ? (
          <Box
            sx={{
              pt: 4,
              display: minimal ? "none" : "block",
            }}
          >
            <FormDivider
              variant="fullWidth"
              text={t("assets:tanks", "Tanks")}
            />
            <ErrorMessage message={getFieldError("storageFacilities", true)} />
            {localBusinessEntity?.storageFacilities?.map((storageFacility) => {
              return (
                <Stack direction="row" alignItems="flex-start">
                  <Box flex={1}>
                    <StorageFacilityFormContainer
                      storageFacility={storageFacility}
                      onChange={(updatedStorageFacility) => {
                        onChange({
                          storageFacilities: (
                            localBusinessEntity?.storageFacilities || []
                          ).map((c) =>
                            c === storageFacility ? updatedStorageFacility : c
                          ),
                        });
                      }}
                    />
                  </Box>

                  <IconButton
                    onClick={() =>
                      onChange({
                        storageFacilities: without(
                          localBusinessEntity.storageFacilities,
                          storageFacility
                        ),
                      })
                    }
                  >
                    <RemoveCircle />
                  </IconButton>
                </Stack>
              );
            })}
            <Stack
              direction="row"
              spacing={2}
              sx={{ m: 1, display: "flex", alignItems: "center" }}
            >
              <AddButton
                sx={{ height: 50, width: 20 }}
                onClick={() => {
                  onChange({
                    storageFacilities: (
                      localBusinessEntity?.storageFacilities || []
                    ).concat({
                      identifier: `Tank #${
                        (localBusinessEntity?.storageFacilities || []).length +
                        1
                      }`,
                      capacity: 0,
                      unit: StorageFacilityUnit.Gallons,
                      commodityId: "",
                    }),
                  });
                }}
                id="add-tank-button"
              >
                <Add />
              </AddButton>
              <span>{t("assets:addTank", "Add Tank")}</span>
              <Divider orientation="vertical" flexItem />
              <span>
                {t(
                  "business:receiver.importConfigurationFrom",
                  "Import configuration from"
                )}
              </span>
              <BusinessEntitySelectContainer
                sx={{ width: 300 }}
                businessEntityTypes={[BusinessEntityType.Receiver]}
                placeholder={capitalize(t("business:receiver.one", "Receiver"))}
                onChange={(_businessEntityId, be) => {
                  setActiveBusinessEntityToImport(be);
                }}
                value={activeBusinessEntityToImport?._id || ""}
                id="import-tank-config-select"
              />
              <Button
                disabled={!activeBusinessEntityToImport}
                onClick={() => {
                  if (
                    activeBusinessEntityToImport?.storageFacilities?.length ===
                    0
                  ) {
                    enqueueSnackbar(
                      t(
                        "business:receiver.noStorageFacilitiesConfigToImport",
                        "No tanks to import"
                      ),
                      {
                        variant: "info",
                      }
                    );
                    setActiveBusinessEntityToImport(null);
                    return;
                  }

                  onChange({
                    storageFacilities: (
                      localBusinessEntity?.storageFacilities || []
                    ).concat(
                      ...(activeBusinessEntityToImport?.storageFacilities?.map(
                        (sf, j) =>
                          omit(
                            {
                              ...sf,
                              identifier: `Tank #${
                                (localBusinessEntity?.storageFacilities || [])
                                  .length +
                                1 +
                                j
                              }`,
                            },
                            ["commodity", "defaultSupplier"]
                          )
                      ) || [])
                    ),
                  });
                  setActiveBusinessEntityToImport(null);
                  enqueueSnackbar(
                    t(
                      "assets:receiver.storageFacilitiesConfigImported",
                      "Tanks imported"
                    ),
                    {
                      variant: "success",
                    }
                  );
                }}
                variant="outlined"
                endIcon={<Download />}
                id="import-tank-config-button"
              >
                {t("common:list.import", "Import")}
              </Button>
            </Stack>
          </Box>
        ) : null}
      </FeatureGuard>

      {customFieldContexts.length > 0 ? (
        <Grid item xs={12}>
          <FormDivider variant="fullWidth" text={t("common:customFields")} />
          {customFieldContexts.map((customFieldContext) => {
            return (
              <CustomFieldsFormContainer
                context={customFieldContext}
                customFields={localBusinessEntity?.customFields || []}
                onChange={(customFields) => {
                  console.log("customFields", customFields);
                  onChange({ customFields });
                }}
              />
            );
          })}
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <FormDivider
          variant="fullWidth"
          text={t("common:documents.many")}
          sx={{
            textTransform: "capitalize",
          }}
        />

        {localBusinessEntity?.documents?.map((document) => {
          return (
            <Stack direction="row" alignItems="flex-start">
              <Box flex={1}>
                <Link href={document.url} component="a" target="_blank">
                  {document.name}
                </Link>
              </Box>

              <IconButton
                onClick={() =>
                  onChange({
                    documents: without(localBusinessEntity.documents, document),
                  })
                }
              >
                <RemoveCircle />
              </IconButton>
            </Stack>
          );
        })}

        <LabeledAddButton
          label={t("common:documents.add", "Add Document")}
          onClick={() => setDocumentUploadModalOpen(true)}
        />

        <DocumentsUploadModalContainer
          isOpen={documentUploadModalOpen}
          onSubmit={(documents) => {
            onChange({
              documents: (localBusinessEntity?.documents || []).concat(
                documents
              ),
            });
            setDocumentUploadModalOpen(false);
          }}
          onCancel={() => setDocumentUploadModalOpen(false)}
        />
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          id="businessEntityFormSaveButton"
          onClick={() => {
            if (!localBusinessEntity) {
              return;
            }
            if (validate()) {
              cancelConfirm();
              // Have to cast because the localBusinessEntity is partial
              // but because we validate at runtime, it should be a complete
              // business entity input by the time we reach this
              onSave(localBusinessEntity as NewBusinessEntityInputWithType);
            }
          }}
        >
          {t("common:save", "Save")}
        </Button>
        <Box sx={{ mr: 1 }}>
          <ErrorMessage message={validationResult?.error?.message || null} />
        </Box>
      </Box>
    </Box>
  );
}

export const MuiPhoneInput = React.forwardRef((props, ref) => {
  return <TextField {...props} inputRef={ref} />;
});

export default BusinessEntityForm;
