import {
  Box,
  Button,
  Chip,
  ChipProps,
  Link,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { first, last, without } from "lodash";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetOrderListQuery,
  ShipmentLocationType,
  Status,
} from "../../../graphql/generated";
import { formatDateTimeForLocation } from "../../../utils/labels/formatDateTime";
import { EnumTableField, TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import {
  shipmentLocationAddressLabel,
  shipmentLocationLabel,
} from "../../../utils/labels/shipmentLocationLabel";
import goodUnitLabel from "../../../utils/labels/goodUnitsLabel";
import RoleGuard from "../../account/Access/RoleGuard";
import { useTranslation } from "react-i18next";

type OrderListItem = GetOrderListQuery["orders"]["data"][0];

export const renderExtraLocations = (
  order: {
    locations?:
      | {
          locationType: ShipmentLocationType;
          shipper?: {
            name: string;
            address: {
              label: string;
            };
          } | null;
          receiver?: {
            name: string;
            address: {
              label: string;
            };
          } | null;
        }[]
      | null;
  },
  locationType: ShipmentLocationType
) => {
  const sameTypeLocations = order.locations?.filter(
    (location) => location.locationType === locationType
  );
  if (sameTypeLocations?.length === 1) {
    return null;
  }
  const furthestLocation =
    locationType === ShipmentLocationType.Pickup
      ? first(order.locations)
      : last(order.locations);
  const extraDropoffLocations = without(sameTypeLocations, furthestLocation);
  if (!extraDropoffLocations.length) {
    return null;
  }
  return (
    <Tooltip
      title={extraDropoffLocations.map((location) => {
        const shipperOrReceiver =
          location?.locationType === ShipmentLocationType.Pickup
            ? location.shipper
            : location?.receiver;
        return (
          <Box>
            {shipperOrReceiver?.name} - {shipperOrReceiver?.address?.label}
          </Box>
        );
      })}
    >
      <Chip
        label={`+${extraDropoffLocations.length} other${
          extraDropoffLocations.length > 1 ? "s" : ""
        }`}
        color="honeyDew"
        sx={{
          fontWeight: "bold",
        }}
      />
    </Tooltip>
  );
};

export const orderStatusColors: Record<Status, ChipProps["color"]> = {
  ASSIGNED: "primary",
  ACTIVE: "primary",
  CANCELLED: "error",
  COMPLETE: "success",
  DELETED: "error",
  DELIVERED: "success",
  PENDING: "default",
  UNASSIGNED: "default",
  WAITING_FOR_APPROVAL: "default",
  WAITING_FOR_ASSIGNMENT: "default",
  PLANNED: "primary",
  IN_PROGRESS: "info",
  FORECASTED: "honeyDew",
  FORECASTED_LOCKED: "default",
  UNPLANNED: "warning",
};

const statusField: EnumTableField<OrderListItem, Status> = {
  value: (order) => order.status,
  label: "Status",
  type: "enum",
  colors: orderStatusColors,
  sortBy: "status",
};

type OrdersListProps = {
  query: QueryFn<
    OrderListItem,
    "orders",
    unknown,
    {
      status: Status | null;
    }
  >;
  onRefetch?: () => void;
  readonly?: boolean;
  disableSearch?: boolean;
  disablePagination?: boolean;
  disableStatusTab?: boolean;
};

export enum OrderStatusTab {
  ALL,
  UNASSIGNED,
  ASSIGNED,
  IN_PROGRESS,
  PAST,
  CANCELLED,
}

const statusTabToOrderStatus: {
  [key in OrderStatusTab]: Status | null;
} = {
  [OrderStatusTab.ALL]: null,
  [OrderStatusTab.ASSIGNED]: Status.Assigned,
  [OrderStatusTab.UNASSIGNED]: Status.WaitingForAssignment,
  [OrderStatusTab.IN_PROGRESS]: Status.InProgress,
  [OrderStatusTab.PAST]: Status.Complete,
  [OrderStatusTab.CANCELLED]: Status.Deleted,
};

export default function OrdersList({
  query,
  disableStatusTab,
}: OrdersListProps) {
  const [statusTab, setStatusTab] = useState<OrderStatusTab>(
    OrderStatusTab.ALL
  );

  const navigate = useNavigate();
  const { t } = useTranslation(["common", "orders"]);

  const fields: TableField<GetOrderListQuery["orders"]["data"][0], Status>[] =
    useMemo(
      () => [
        {
          value: (order) => (
            <Link
              onClick={(e) => e.stopPropagation()}
              href={`/orders/details/${order._id}`}
            >
              Order {order.orderNumber}
            </Link>
          ),
          label: t("orders:orderNumber", "Order Number"),
          type: "custom",
        },
        {
          value(order) {
            return `${order.customer?.name}`;
          },
          label: t("orders:customer", "Customer"),
          type: "string",
        },
        statusField,
        {
          value: (order) => (
            <Typography variant="body1">
              {formatDateTimeForLocation(
                last(order.locations)?.timeWindows[0].fromDate,
                last(order.locations)
              )}
            </Typography>
          ),
          label: t("orders:deliveryDate", "Delivery Date"),
          type: "custom",
        },
        {
          value: (order) => shipmentLocationLabel(first(order.locations)),
          subtitle: (order) =>
            shipmentLocationAddressLabel(first(order.locations)),
          extra: (order) => {
            return renderExtraLocations(order, ShipmentLocationType.DropOff);
          },
          label: t("orders:consigneeReceiver", "Consignee/Receiver"),
          type: "string",
        },
        {
          label: t("orders:commodity", "Commodity"),
          value: (order) =>
            order.locations
              .map((location) =>
                location.receivedGoods?.map(
                  (receivedGood) =>
                    `(${receivedGood.goodProfile?.code}) ${
                      receivedGood.goodProfile?.label
                    } - ${receivedGood.quantity} ${goodUnitLabel(
                      receivedGood.goodProfile?.unit
                    )}`
                )
              )
              .flat()
              .join(", "),
          type: "string",
        },
      ],
      [t]
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {disableStatusTab ? null : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={statusTab}
            onChange={(event, value) => {
              setStatusTab(value);
            }}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab
              label={t("orders:filter.all", "All")}
              value={OrderStatusTab.ALL}
            />
            <Tab
              label={t("orders:filter.unassigned", "Unassigned")}
              value={OrderStatusTab.UNASSIGNED}
            />
            <Tab
              label={t("orders:filter.assigned", "Assigned")}
              value={OrderStatusTab.ASSIGNED}
            />
            <Tab
              label={t("orders:filter.inProgress", "In Progress")}
              value={OrderStatusTab.IN_PROGRESS}
            />
            <Tab
              label={t("orders:filter.cmpleted", "Completed")}
              value={OrderStatusTab.PAST}
            />
            <Tab
              label={t("orders:filter.cancelled", "Cancelled")}
              value={OrderStatusTab.CANCELLED}
            />
          </Tabs>
        </Box>
      )}

      <Stack
        sx={{
          alignSelf: "end",
          mb: 2,
          mt: 2,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
          <Button
            href="/orders/new"
            variant="contained"
            color="secondary"
            size="large"
            id="new-order-button"
          >
            {t("orders:new", "New Order")}
          </Button>
        </RoleGuard>
      </Stack>
      <SmartLynksTable
        query={query}
        additionalQueryVariables={{
          status: statusTabToOrderStatus[statusTab],
        }}
        dataKey="orders"
        id="orders-table"
        fields={fields}
        detailsUrlPrefix={(row) => `/orders/details/${row._id}`}
        actions={[
          {
            icon: <ContentCopy />,
            label: t("list.duplicate", "Duplicate"),
            tooltip: t("list.duplicate", "Duplicate"),
            onClick: (row) => {
              navigate(`/orders/new`, { state: { order: row._id } });
            },
            secondary: true,
          },
        ]}
      />
    </div>
  );
}
