import { useCallback, useEffect, useState } from "react";
import { Box, Grid, IconButton, Stack, TextField } from "@mui/material";
import { FormDivider } from "../BusinessEntityForm/BusinessEntityForm";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  BillingRuleConditionOperator,
  BusinessEntityType,
  NewSupplierContractInput,
  SupplierContractByIdQuery,
  SupplierContractConditionTarget,
} from "../../../graphql/generated";
import { capitalize, isArray, mergeWith, omit } from "lodash";
import supplierContractSchema from "./supplierContractSchema";
import { isValid } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import { useTranslation } from "react-i18next";
import BusinessEntitySelectContainer from "../../shipment/ShipmentForm/BusinessEntitySelect";
import GoodProfileSelectContainer from "../../commodity-management/GoodProfileSelect";
import NumberTextField from "../../common/NumberTextField";
import { LoadingButton } from "@mui/lab";
import ConditionForm from "../../accounting/BillingRuleForm/ConditionForm";
import LabeledAddButton from "../../common/LabeledAddButton";
import { Clear } from "@mui/icons-material";

type SupplierContractFormProps = {
  initialSupplierContract?: SupplierContractByIdQuery["supplierContractById"];
  saving: boolean;
  onSave: (supplierContract: NewSupplierContractInput) => void;
};

type PartialSupplierContractInput = Partial<NewSupplierContractInput>;
type DeepPartialSupplierContract = {
  [key in keyof PartialSupplierContractInput]: Partial<
    PartialSupplierContractInput[key]
  >;
};

const SupplierContractForm = ({
  initialSupplierContract,
  saving,
  onSave,
}: SupplierContractFormProps) => {
  const { t } = useTranslation(["common", "users", "business"]);

  const [validationResult, setValidationResult] =
    useState<ValidationResult<NewSupplierContractInput> | null>(null);
  const [localSupplierContract, setLocalSupplierContract] = useState<
    DeepPartialSupplierContract | undefined
  >(
    omit(
      initialSupplierContract,
      "_id",
      "supplierId",
      "customer",
      "goodProfile",
      "shipper",
      "usedAllocation"
    ) || { allocation: {} }
  );

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialSupplierContract) => {
    setLocalSupplierContract((localSupplierContract) =>
      mergeWith({}, localSupplierContract, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = supplierContractSchema.validate(
      localSupplierContract,
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSupplierContract]);

  useEffect(() => {
    setLocalSupplierContract(
      omit(
        initialSupplierContract,
        "_id",
        "supplierId",
        "customer",
        "goodProfile",
        "shipper",
        "usedAllocation"
      ) || { allocation: {} }
    );
  }, [initialSupplierContract]);

  const { cancelConfirm } = useConfirmBeforeLeave(localSupplierContract);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <FormDivider
            variant="fullWidth"
            text={capitalize(
              t("business:supplier.contract.details", "Contract Details")
            )}
          />
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <TextField
                required
                label={capitalize(
                  t(
                    "business:supplier.contract.contractNumber",
                    "Contract Number"
                  )
                )}
                name="contractNumber"
                fullWidth
                value={localSupplierContract?.contractNumber || ""}
                error={!!getFieldError("contractNumber")}
                helperText={getFieldError("contractNumber")}
                onChange={(event) => {
                  onChange({ contractNumber: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <GoodProfileSelectContainer
                multiple={false}
                value={localSupplierContract?.goodProfileId || null}
                onChange={(goodProfileId) => {
                  if (!goodProfileId) {
                    return;
                  }
                  onChange({
                    goodProfileId,
                  });
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <BusinessEntitySelectContainer
                businessEntityType={BusinessEntityType.Customer}
                onChange={(customerId) => {
                  onChange({
                    customerId: customerId || "",
                  });
                }}
                value={localSupplierContract?.customerId}
              />
            </Grid>
            <Grid item sm={6}>
              <BusinessEntitySelectContainer
                businessEntityType={BusinessEntityType.Shipper}
                onChange={(shipperId) => {
                  onChange({
                    shipperId: shipperId || "",
                  });
                }}
                value={localSupplierContract?.shipperId}
              />
            </Grid>
            <Grid item sm={6}>
              <NumberTextField
                required
                label={t(
                  "business:supplier.contract.totalAllocation",
                  "Total allocation"
                )}
                name="allocation.totalQuantity"
                fullWidth
                value={localSupplierContract?.allocation?.totalQuantity || ""}
                error={!!getFieldError("allocation.totalQuantity")}
                helperText={getFieldError("allocation.totalQuantity")}
                onChange={(event) => {
                  onChange({
                    allocation: {
                      ...localSupplierContract?.allocation,
                      totalQuantity:
                        Number.parseFloat(event.target.value) || null,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <NumberTextField
                label={t(
                  "business:supplier.contract.dailyAllocation",
                  "Daily Allocation"
                )}
                name="allocation.dailyQuantity"
                fullWidth
                value={localSupplierContract?.allocation?.dailyQuantity || ""}
                error={!!getFieldError("allocation.dailyQuantity")}
                helperText={getFieldError("allocation.dailyQuantity")}
                onChange={(event) => {
                  onChange({
                    allocation: {
                      ...localSupplierContract?.allocation,
                      dailyQuantity:
                        Number.parseFloat(event.target.value) || null,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <NumberTextField
                label={t(
                  "business:supplier.contract.weeklyAllocation",
                  "Weekly Allocation"
                )}
                name="allocation.weeklyQuantity"
                fullWidth
                value={localSupplierContract?.allocation?.weeklyQuantity || ""}
                error={!!getFieldError("allocation.weeklyQuantity")}
                helperText={getFieldError("allocation.weeklyQuantity")}
                onChange={(event) => {
                  onChange({
                    allocation: {
                      ...localSupplierContract?.allocation,
                      weeklyQuantity:
                        Number.parseFloat(event.target.value) || null,
                    },
                  });
                }}
              />
            </Grid>

            <Grid item sm={6}>
              <NumberTextField
                label={t(
                  "business:supplier.contract.monthlyAllocation",
                  "Monthly Allocation"
                )}
                name="allocation.monthlyQuantity"
                fullWidth
                value={localSupplierContract?.allocation?.monthlyQuantity || ""}
                error={!!getFieldError("allocation.monthlyQuantity")}
                helperText={getFieldError("allocation.monthlyQuantity")}
                onChange={(event) => {
                  onChange({
                    allocation: {
                      ...localSupplierContract?.allocation,
                      monthlyQuantity:
                        Number.parseFloat(event.target.value) || null,
                    },
                  });
                }}
              />
            </Grid>

            <Grid item sm={6}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    required
                    label={t("common:startDate", "Start Date")}
                    name="startDate"
                    fullWidth
                    error={!!getFieldError("startDate")}
                    helperText={getFieldError("startDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                value={localSupplierContract?.startDate || null}
                onChange={(dateString: string | null) => {
                  if (dateString && isValid(dateString)) {
                    onChange({
                      startDate: new Date(dateString),
                    });
                  }
                }}
                maxDate={localSupplierContract?.endDate as string | undefined}
              />
            </Grid>

            <Grid item sm={6}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    required
                    label={t("common:endDate", "End Date")}
                    name="endDate"
                    fullWidth
                    error={!!getFieldError("endDate")}
                    helperText={getFieldError("endDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                value={localSupplierContract?.endDate || null}
                onChange={(dateString: string | null) => {
                  if (dateString && isValid(dateString)) {
                    onChange({
                      endDate: new Date(dateString),
                    });
                  }
                }}
                minDate={localSupplierContract?.startDate as string | undefined}
              />
            </Grid>
            <Grid item sm={6}>
              <NumberTextField
                required
                label={t("business:supplier.contract.unitPrice", "Unit Price")}
                name="unitPrice"
                fullWidth
                value={localSupplierContract?.unitPrice}
                error={!!getFieldError("unitPrice")}
                helperText={getFieldError("unitPrice")}
                onChange={(event) => {
                  onChange({
                    unitPrice: Number.parseFloat(event.target.value),
                  });
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label={t("business:supplier.contract.pinCode", "PIN Code")}
                name="pinCode"
                fullWidth
                value={localSupplierContract?.pinCode || null}
                error={!!getFieldError("pinCode")}
                helperText={getFieldError("pinCode")}
                onChange={(event) => {
                  onChange({
                    pinCode: event.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <FormDivider
            variant="fullWidth"
            text={capitalize(
              t("business:supplier.contract.conditions", "Contract Conditions")
            )}
          />
          <Stack spacing={3}>
            {localSupplierContract?.conditions?.map((condition, index) => {
              if (!condition) {
                return null;
              }
              return (
                <Stack direction="row" spacing={1} key={index}>
                  <ConditionForm
                    entity="supplierContract"
                    condition={condition}
                    onChange={(updatedCondition) => {
                      onChange({
                        conditions: localSupplierContract?.conditions?.map(
                          (c, i) => (i === index ? updatedCondition : c)
                        ),
                      });
                    }}
                  />

                  <IconButton size="small">
                    <Clear
                      fontSize="small"
                      onClick={() => {
                        onChange({
                          ...localSupplierContract,
                          conditions: localSupplierContract?.conditions?.filter(
                            (c, i) => i !== index
                          ),
                        });
                      }}
                    />
                  </IconButton>
                </Stack>
              );
            })}
            <LabeledAddButton
              label={t("form.Condition", "Condition")}
              onClick={() => {
                onChange({
                  conditions: [
                    ...(localSupplierContract?.conditions || []),
                    {
                      target: SupplierContractConditionTarget.PurchaseTime,
                      operator: BillingRuleConditionOperator.Equals,
                      value: null,
                    },
                  ],
                });
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <LoadingButton
          variant="contained"
          disabled={saving || !!validationResult?.error}
          loading={saving}
          size="large"
          onClick={() => {
            if (!localSupplierContract) {
              return;
            }
            if (validate()) {
              cancelConfirm();
              onSave(localSupplierContract as NewSupplierContractInput);
            }
          }}
          id="saveSupplierContractButton"
        >
          {t("common:save", "Save")}
        </LoadingButton>
        <Box sx={{ mr: 1 }}>
          <ErrorMessage message={validationResult?.error?.message || null} />
        </Box>
      </Box>
    </Box>
  );
};

export default SupplierContractForm;
