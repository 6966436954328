import { Grid, TextField } from "@mui/material";
import { FC } from "react";
import EnumSelect from "../../common/EnumSelect";
import { useTranslation } from "react-i18next";
import {
  NewBusinessEntityInputWithType,
  DefaultReferenceNumber,
  ShipmentReferenceNumberType,
} from "../../../graphql/generated";

type ReferenceNumberListProps = {
  businessEntity: Partial<NewBusinessEntityInputWithType> | null;
  onChange: (value: DefaultReferenceNumber) => void;
  value: DefaultReferenceNumber;
};

export const ReferenceNumberForm: FC<ReferenceNumberListProps> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation(["business", "common", "assets", "orders"]);
  return (
    <Grid justifyContent="space-between" container spacing={2}>
      <Grid item xs={6}>
        <EnumSelect
          label={t("business:referenceNumberTypes", "Reference Number Type")}
          sx={{ mt: 3 }}
          multiple={false}
          fullWidth
          enumObject={ShipmentReferenceNumberType}
          value={value.referenceNumberType}
          onChange={(_, referenceNumberType) => {
            referenceNumberType && onChange({ ...value, referenceNumberType });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t(
            "business:defaultReferenceNumber",
            "Default Reference Number"
          )}
          sx={{ mt: 3 }}
          fullWidth
          value={value.referenceNumberValue}
          onChange={(event) => {
            onChange({
              ...value,
              referenceNumberValue: event.target.value,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};
