import { BillingMethod } from "../../../graphql/generated";
import i18next from "i18next";
import enumLabel from "../enumLabel";

const billingMethodLabel = (method: BillingMethod) => {
  return i18next.t(
    `billingRules:method.${method}`,
    enumLabel(method) || method
  ) as string;
};

export default billingMethodLabel;
