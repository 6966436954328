import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  DriverShiftAssignment,
  NewDriverShiftAssignmentInput,
} from "../../../graphql/generated";
import DriverShiftAssignmentForm, {
  DriverShiftAssignmentFormContainerProps,
} from "../DriverShiftAssignmentForm";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import driverShiftAssignmentSchema from "./driverShiftAssignmentSchema";
import { useTranslation } from "react-i18next";

export type DriverShiftAssignmentFormModalProps = Omit<
  DriverShiftAssignmentFormContainerProps,
  "driverShiftAssignment" | "onChange"
> & {
  driverShiftAssignment: NewDriverShiftAssignmentInput | null;
  onSubmit: (driverShiftAssignment: NewDriverShiftAssignmentInput) => void;
  isOpen: boolean;
  error: Error | null;
  onClose: () => void;
};

const DriverShiftAssignmentFormModal = ({
  driverShiftAssignment,
  onSubmit,
  isOpen,
  onClose,
  ...props
}: DriverShiftAssignmentFormModalProps) => {
  const [localDriverShiftAssignment, setLocalDriverShiftAssignment] =
    useState<NewDriverShiftAssignmentInput | null>(driverShiftAssignment);

  const [validationResult, setValidationResult] =
    useState<ValidationResult<DriverShiftAssignment> | null>(null);

  useEffect(() => {
    if (!localDriverShiftAssignment) {
      setLocalDriverShiftAssignment(driverShiftAssignment);
    }
  }, [driverShiftAssignment, localDriverShiftAssignment]);

  useEffect(() => {
    if (!isOpen) {
      setLocalDriverShiftAssignment(null);
    }
  }, [isOpen]);

  const { t } = useTranslation(["assets", "common"]);

  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogTitle>
        {t("driverShifts.assignDriverToShift", "Assign driver to shift")}
      </DialogTitle>

      <DialogContent>
        <ErrorMessage error={validationResult?.error || props.error} />
        <DriverShiftAssignmentForm
          driverShiftAssignment={localDriverShiftAssignment}
          onChange={(driverShiftAssignment) => {
            setLocalDriverShiftAssignment(driverShiftAssignment);
            if (validationResult) {
              setValidationResult(
                driverShiftAssignmentSchema.validate(driverShiftAssignment, {
                  abortEarly: false,
                })
              );
            }
          }}
          {...props}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose()}>{t("common:close", "Close")}</Button>
        <Button
          variant="contained"
          disabled={!localDriverShiftAssignment || !!validationResult?.error}
          onClick={() => {
            if (!localDriverShiftAssignment) {
              return;
            }
            const result = driverShiftAssignmentSchema.validate(
              localDriverShiftAssignment,
              {
                abortEarly: false,
              }
            );
            if (result.error) {
              setValidationResult(result);
              return;
            }
            onSubmit(localDriverShiftAssignment);
          }}
        >
          {t("common:save", "Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DriverShiftAssignmentFormModal;
