import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import DocumentsUploadModalContainer from "../../common/DocumentsUploadModal";
import { useTranslation } from "react-i18next";
import { useId, useState } from "react";
import { Document, MyDocumentsQuery } from "../../../graphql/generated";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";

type OrganizationDocumentsManagementProps = {
  onAddDocument: (document: Document) => Promise<void>;
  onEditDocument: (document: Document) => Promise<void>;
  onDeleteDocument: (documentId: string) => void;
  documents: MyDocumentsQuery["myDocuments"];
  loading: boolean;
};

export default function OrganizationDocumentsManagement({
  onAddDocument,
  onEditDocument,
  onDeleteDocument,
  documents,
  loading,
}: OrganizationDocumentsManagementProps) {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const { t } = useTranslation(["organization", "common"]);
  const [currentDocument, setCurrentDocument] = useState<Document | null>(null);
  const confirm = useConfirm();
  const fallbackRowId = useId();

  return (
    <Box>
      {loading && <CircularProgress />}
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("common:list.name", "Name")}</TableCell>
              <TableCell align="right">
                {t("common:list.actions", "Actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, rowIndex) => (
              <TableRow
                key={document._id || fallbackRowId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {document.name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="right"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Tooltip title={t("common:list.show", "Show")}>
                    <IconButton
                      size="small"
                      id={`row-${rowIndex}-action-show`}
                      onClick={() => {
                        if (document.url) {
                          window.open(document.url, "_blank");
                        }
                      }}
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("common:list.edit", "Edit")}>
                    <IconButton
                      size="small"
                      id={`row-${rowIndex}-action-edit`}
                      onClick={() => {
                        setUploadModalOpen(true);
                        if (document.url) {
                          setCurrentDocument({
                            ...document,
                            url: document.url,
                          });
                        }
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("common:delete", "Delete")}>
                    <IconButton
                      size="small"
                      id={`row-${rowIndex}-action-delete`}
                      onClick={async () => {
                        if (document._id) {
                          await confirm({
                            title: t("common:confirmation", "Confirmation"),
                            description: t(
                              "common:documents.deleteThisConfirmation",
                              "Delete this document ?"
                            ),
                            confirmationText: t("common:delete", "Delete"),
                          });
                          onDeleteDocument(document._id);
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => setUploadModalOpen(true)}
      >
        {t("common:add", "Add")}
      </Button>

      <DocumentsUploadModalContainer
        initialDocuments={
          currentDocument
            ? [
                {
                  ...currentDocument,
                  _id: currentDocument._id || "",
                  file: null,
                },
              ]
            : undefined
        }
        isOpen={uploadModalOpen}
        multiple={false}
        title={t("uploadYourDocument", "Upload your document")}
        accept="image/*,application/pdf"
        onCancel={() => {
          setUploadModalOpen(false);
          setCurrentDocument(null);
        }}
        onSubmit={async (documents) => {
          if (!currentDocument) {
            await onAddDocument(documents[0]);
            setUploadModalOpen(false);
          }

          if (currentDocument) {
            await onEditDocument(documents[0]);
            setUploadModalOpen(false);
          }

          setCurrentDocument(null);
        }}
      />
    </Box>
  );
}
