import {
  ExtensionMessageContext,
  useGetExtensionsMessagesQuery,
} from "../../../graphql/generated";
import ExtensionsMessages, {
  ExtensionMessagesProps,
} from "./ExtensionsMessages";

export type ExtensionsMessagesContainerProps = Omit<
  ExtensionMessagesProps,
  "messages"
> & {
  context?: ExtensionMessageContext;
};

const ExtensionsMessagesContainer = (
  props: ExtensionsMessagesContainerProps
) => {
  const getExtensionsMessagesQuery = useGetExtensionsMessagesQuery(
    {},
    {
      refetchInterval: 60 * 1000,
      refetchOnWindowFocus: true,
    }
  );

  const messagesInContext = (
    getExtensionsMessagesQuery.data?.extensionsMessages || []
  ).filter((message) => message.context === props.context);

  return <ExtensionsMessages messages={messagesInContext} />;
};

export default ExtensionsMessagesContainer;
