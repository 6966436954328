import { Grid } from "@mui/material";
import {
  BillingRateParameter,
  BillingRateParameterType,
} from "../../../graphql/generated";
import GoodsInput from "./value-selectors/GoodsInput";
import enumLabel from "../../../utils/labels/enumLabel";
import {
  ConditionValueSelectorComponent,
  ConditionValueSelectorComponentProps,
} from "./ConditionForm";
import LocationTypeSelect from "./value-selectors/LocationTypeSelect";
import NumberInput from "./value-selectors/NumberInput";

const ConditionValueSelectorComponentByTarget: {
  [key in BillingRateParameterType]: ConditionValueSelectorComponent | null;
} = {
  [BillingRateParameterType.GoodProfile]: (
    props: ConditionValueSelectorComponentProps
  ) => <GoodsInput {...props} sx={{ maxWidth: 300, display: "flex" }} />,
  [BillingRateParameterType.LocationType]: LocationTypeSelect,
  [BillingRateParameterType.FreeLocationCount]: NumberInput,
};

const rateParameterLabels: Partial<Record<BillingRateParameterType, string>> = {
  [BillingRateParameterType.GoodProfile]: "Commodity",
  [BillingRateParameterType.LocationType]: "Stop Type",
  [BillingRateParameterType.FreeLocationCount]: "No. of Free Stops",
};

type RateParameterFormProps = {
  rateParameter: BillingRateParameter;
  onChange: (rateParameter: BillingRateParameter) => void;
};

const RateParameterForm = ({
  rateParameter,
  onChange,
}: RateParameterFormProps) => {
  const ValueSelectorComponent =
    ConditionValueSelectorComponentByTarget[rateParameter.type];
  return (
    <Grid container direction="row" alignItems="center" spacing={1} flex={1}>
      {rateParameter.type && ValueSelectorComponent ? (
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
        >
          <ValueSelectorComponent
            label={
              rateParameterLabels[rateParameter.type] ||
              enumLabel(rateParameter.type)
            }
            value={rateParameter.value}
            onChange={(value) =>
              onChange({
                ...rateParameter,
                value,
              })
            }
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default RateParameterForm;
