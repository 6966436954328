import AnalyticsDashboard, {
  AnalyticsDashboardProps,
} from "./AnalyticsDashboard";

type AnalyticsDashboardContainerProps = AnalyticsDashboardProps;

const AnalyticsDashboardContainer = (
  props: AnalyticsDashboardContainerProps
) => {
  return <AnalyticsDashboard />;
};

export default AnalyticsDashboardContainer;
