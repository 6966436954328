import { omit } from "lodash";
import {
  NewDriverShiftAssignmentInput,
  GetDriverShiftAssignmentDetailsQuery,
  useAddDriverShiftAssignmentMutation,
  useEditDriverShiftAssignmentMutation,
  useGetDriverShiftAssignmentDetailsQuery,
} from "../../../graphql/generated";
import DriverShiftAssignmentFormModal, {
  DriverShiftAssignmentFormModalProps,
} from "./DriverShiftAssignmentFormModal";
import LoadingOverlay from "../../common/LoadingOverlay";

type DriverShiftAssignmentFormModalContainerProps = Omit<
  DriverShiftAssignmentFormModalProps,
  "driverShiftAssignment" | "onSubmit" | "error"
> & {
  driverShiftAssignmentId?: string;
  onSaved?: (driverShiftAssignmentId: string) => void;
};

type DriverShiftAssignmentDetailsData = Exclude<
  GetDriverShiftAssignmentDetailsQuery["driverShiftAssignment"],
  null | undefined
>;

const driverShiftAssignmentToInput = (
  driverShiftAssignment: DriverShiftAssignmentDetailsData | null
): NewDriverShiftAssignmentInput | null => {
  if (!driverShiftAssignment) {
    return null;
  }
  return {
    ...omit(driverShiftAssignment, "_id", "driver", "driverShift"),
  };
};

const DriverShiftAssignmentFormModalContainer = (
  props: DriverShiftAssignmentFormModalContainerProps
) => {
  const driverShiftAssignmentQuery = useGetDriverShiftAssignmentDetailsQuery(
    {
      id: props.driverShiftAssignmentId || "",
    },
    {
      enabled: !!props.driverShiftAssignmentId,
    }
  );

  const addDriverShiftAssignmentMutation =
    useAddDriverShiftAssignmentMutation();
  const editDriverShiftAssignmentMutation =
    useEditDriverShiftAssignmentMutation();

  const existingDriverShiftAssignment =
    driverShiftAssignmentQuery?.data?.driverShiftAssignment || null;

  const submitDriverShiftAssignment = async (
    driverShiftAssignment: NewDriverShiftAssignmentInput
  ) => {
    const newLinkingHasPeriod =
      !!driverShiftAssignment.startDate && !!driverShiftAssignment.endDate;
    const existingLinkingHasPeriod =
      !!existingDriverShiftAssignment?.startDate &&
      !!existingDriverShiftAssignment?.endDate;
    // If we are editing an existing link and set a period, we should create a new one
    // instead of overriding the existing one
    if (
      existingDriverShiftAssignment?._id &&
      (existingLinkingHasPeriod || !newLinkingHasPeriod)
    ) {
      const response = await editDriverShiftAssignmentMutation.mutateAsync({
        id: existingDriverShiftAssignment._id,
        editDriverShiftAssignmentData: driverShiftAssignment,
      });
      props.onSaved?.(response.editDriverShiftAssignment._id);
    } else {
      const response = await addDriverShiftAssignmentMutation.mutateAsync({
        newDriverShiftAssignmentData: driverShiftAssignment,
      });
      props.onSaved?.(response.addDriverShiftAssignment._id);
    }
    driverShiftAssignmentQuery.refetch();
    props.onClose();
  };

  if (props.driverShiftAssignmentId && driverShiftAssignmentQuery.isLoading) {
    return <LoadingOverlay loading />;
  }

  return (
    <DriverShiftAssignmentFormModal
      driverShiftAssignment={driverShiftAssignmentToInput(
        existingDriverShiftAssignment
      )}
      onSubmit={submitDriverShiftAssignment}
      loading={
        addDriverShiftAssignmentMutation.isLoading ||
        editDriverShiftAssignmentMutation.isLoading ||
        (!!props.driverShiftAssignmentId &&
          driverShiftAssignmentQuery?.isLoading)
      }
      error={
        addDriverShiftAssignmentMutation.error ||
        editDriverShiftAssignmentMutation.error
      }
      {...props}
    />
  );
};

export default DriverShiftAssignmentFormModalContainer;
