import { Box, CircularProgress, Stack } from "@mui/material";

export enum ChargebeeWidgetForward {
  BillingAddress = "portal_address",
  SubscriptionDetails = "sub_details",
  BillingHistory = "portal_billing_history",
  PaymentMethods = "portal_payment_methods",
}

type ChargebeeWidgetProps = {
  portalUrlSuffix: string;
  forward: ChargebeeWidgetForward;
  subscriptionId?: string;
  loading: boolean;
};

export default function ChargebeeWidget({
  portalUrlSuffix,
  forward,
  subscriptionId,
  loading,
}: ChargebeeWidgetProps) {
  return (
    <Box sx={{ minHeight: "500px" }}>
      {loading ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <iframe
          id="cb-frame"
          style={{
            width: "100%",
            height: "500px",
            visibility: "visible",
            position: "relative",
            border: "0px",
            display: "block",
            boxShadow: "unset",
          }}
          src={`${portalUrlSuffix}&forward=${forward}${
            subscriptionId ? `&fw%5BsubscriptionId%5D=${subscriptionId}` : ""
          }`}
          title="Billing Portal"
        />
      )}
    </Box>
  );
}
