import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import { FormDivider } from "../../asset-management/BusinessEntityForm/BusinessEntityForm";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  CustomFieldContext,
  KeycloakUserRole,
  NewOrgUserInput,
} from "../../../graphql/generated";
import { isArray, mergeWith, omit } from "lodash";
import userSchema from "./userSchema";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChipTagsInput from "../../common/ChipTagsInput/ChipTagsInput";
import CustomFieldsFormContainer from "../../extensions/CustomFieldsForm";

type User = NewOrgUserInput;

type UserFormProps = {
  initialUser?: User;
  availableRoles: KeycloakUserRole[];
  saving: boolean;
  onSave: (user: NewOrgUserInput) => void;
};
type PartialUserInput = Partial<NewOrgUserInput>;
type DeepPartialUser = {
  [key in keyof PartialUserInput]: Partial<PartialUserInput[key]>;
};

const UserForm = ({
  initialUser,
  saving,
  availableRoles,
  onSave,
}: UserFormProps) => {
  const { t } = useTranslation(["common", "users"]);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<NewOrgUserInput> | null>(null);
  const { state } = useLocation();
  const initialRoles: string[] | undefined | null = state?.initialRoles;
  const [localUser, setLocalUser] = useState<Partial<NewOrgUserInput>>(
    initialUser || {
      roles: availableRoles.filter((r) => initialRoles?.includes(r.name)),
    }
  );

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialUser) => {
    setLocalUser((localUser) =>
      mergeWith({}, localUser, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = userSchema.validate(
      omit(localUser, "_id", initialUser ? "password" : ""),
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localUser]);

  const { cancelConfirm } = useConfirmBeforeLeave(localUser);
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <FormDivider
            variant="fullWidth"
            text={t("users:details", "User Details")}
          />
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <TextField
                required
                label={t("users:firstName", "First Name")}
                fullWidth
                value={localUser?.firstName || ""}
                error={!!getFieldError("firstName")}
                helperText={getFieldError("firstName")}
                onChange={(event) => {
                  onChange({
                    firstName: event.target.value,
                  });
                }}
                name="firstName"
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                required
                label={t("users:lastName", "Last Name")}
                fullWidth
                value={localUser?.lastName || ""}
                error={!!getFieldError("lastName")}
                helperText={getFieldError("lastName")}
                onChange={(event) => {
                  onChange({ lastName: event.target.value });
                }}
                name="lastName"
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                label={t("users:email", "Email")}
                fullWidth
                autoComplete="off"
                value={localUser?.email || ""}
                error={!!getFieldError("email") || !!getFieldError("username")}
                helperText={getFieldError("email") || getFieldError("username")}
                onChange={(event) => {
                  onChange({
                    email: event.target.value,
                    username: event.target.value,
                  });
                }}
                required
                name="email"
              />
            </Grid>
            {/* <Grid item sm={4}>
              <TextField
                required
                label="Username"
                autoComplete="off"
                fullWidth
                value={localUser?.username || ""}
                error={!!getFieldError("username")}
                helperText={getFieldError("username")}
                onChange={(event) => {
                  onChange({ username: event.target.value });
                }}
              />
            </Grid> */}
            <Grid item sm={4}>
              <TextField
                select
                required
                label={t("users:roles", "Roles")}
                SelectProps={{
                  multiple: true,
                }}
                value={localUser?.roles?.map((role) => role.id)}
                onChange={(event) => {
                  onChange({
                    roles: availableRoles.filter((role) =>
                      event.target.value.includes(role.id)
                    ),
                  });
                }}
                sx={{ width: "100%" }}
                name="roles"
              >
                {availableRoles
                  .filter((role) => role.name !== "Carrier Driver")
                  .map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            {!initialUser ? (
              <Grid item sm={4}>
                <TextField
                  required
                  label={t("users:password", "Password")}
                  type="password"
                  autoComplete="off"
                  fullWidth
                  value={localUser?.password || ""}
                  error={!!getFieldError("password")}
                  helperText={
                    getFieldError("password") ||
                    t(
                      "users:passwordResetDescription",
                      "User will need to change his password on his next login"
                    )
                  }
                  onChange={(event) => {
                    onChange({ password: event.target.value });
                  }}
                  name="password"
                />
              </Grid>
            ) : null}
            <Grid item sm={6}>
              <ChipTagsInput
                value={
                  localUser?.attributes?.tags
                    ? localUser.attributes.tags.split(",")
                    : []
                }
                onChange={(tags) => {
                  onChange({ attributes: { tags: tags.join(",") } });
                }}
              />
            </Grid>{" "}
            <Grid item xs={12}>
              <FormDivider
                variant="fullWidth"
                text={t("common:customFields")}
              />

              <CustomFieldsFormContainer
                context={CustomFieldContext.User}
                customFields={
                  localUser?.attributes?.customFields
                    ? JSON.parse(localUser.attributes.customFields)
                    : []
                }
                onChange={(customFields) => {
                  onChange({
                    attributes: { customFields: JSON.stringify(customFields) },
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (validate()) {
              cancelConfirm();
              onSave(localUser as NewOrgUserInput);
            }
          }}
          id="saveUserButton"
        >
          {t("common:save", "Save")}
        </Button>
        <Box sx={{ mr: 1 }}>
          <ErrorMessage
            message={
              validationResult?.error ? validationResult.error.message : null
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserForm;
