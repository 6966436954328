import { Stack } from "@mui/material";
import ReportGenerator from "../../components/reporting/ReportGenerator";
import AnalyticsDashboardContainer from "../../components/reporting/AnalyticsDashboard";

export default function GenerateReport() {
  return (
    <Stack spacing={3}>
      <AnalyticsDashboardContainer />
      <ReportGenerator />
    </Stack>
  );
}
