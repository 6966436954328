import keycloak, {
  isAnonymousPage,
  registerErrorCallback,
  registerSuccessCallback,
  resourceKey,
} from "./keycloak";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Role } from "../graphql/generated";

export type UserInfo = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  sub: string;
  organization: {
    [key: string]:
      | {
          name: string;
          roles: string[];
          attributes: {
            dotNumber: [string];
          };
        }
      | undefined;
  };
  freshchatRestoreId?: string;
  driverId?: string;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
};

type AuthContextValue = {
  authenticated: boolean;
  userInfo: UserInfo | null;
  roles: string[];
  signOut: (redirectUri?: string) => Promise<void>;
  register: () => Promise<void>;
  refreshToken: () => void;
};

export const AuthContext = React.createContext<AuthContextValue>({
  authenticated: false,
  userInfo: null,
  roles: [],
  signOut: () => Promise.reject(new Error("Auth provider not yet initialized")),
  register: () =>
    Promise.reject(new Error("Auth provider not yet initialized")),
  refreshToken: () =>
    Promise.reject(new Error("Auth provider not yet initialized")),
});

export const useAuth = () => useContext(AuthContext);

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [isReady, setIsReady] = useState(isAnonymousPage());
  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      return;
    }
    registerSuccessCallback(async () => {
      console.log("success");
      setAuthenticated(true);
      setIsReady(true);
      if (!keycloak) {
        return;
      }
      await keycloak.loadUserInfo();
      if (keycloak.userInfo) {
        setUserInfo(keycloak.userInfo as UserInfo);
      }
    });

    registerErrorCallback(async () => {
      console.log("error");
      setIsReady(true);
    });

    isInitialized.current = true;
  });

  const signOut = useCallback(async (redirectUri?: string) => {
    await keycloak?.logout({
      redirectUri,
    });
  }, []);

  const register = useCallback(async () => {
    await keycloak?.register();
  }, []);

  const refreshToken = useCallback(async () => {
    await keycloak.updateToken(Infinity);
    await keycloak.loadUserInfo();
    if (keycloak.userInfo) {
      setUserInfo(keycloak.userInfo as UserInfo);
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const roles = (keycloak.tokenParsed?.resource_access?.[resourceKey]?.roles ||
    []) as Role[];

  useEffect(() => {
    const { pathname } = window.location;
    const authorizedPaths = roles
      .map((role) => authorizedPathsByRole[role])
      .flat();
    if (
      authorizedPaths.every(
        (authorizedPath) => !pathname.startsWith(authorizedPath)
      )
    ) {
      if (authorizedPaths[0]) {
        window.location.href = authorizedPaths[0];
      }
    }
  }, [roles]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        userInfo,
        roles,
        signOut,
        refreshToken,
        register,
      }}
    >
      {isReady ? children : null}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

export const getOrganizationId = (user: UserInfo) =>
  Object.keys(user.organization || {})[0];

const authorizedPathsByRole: Record<Role, string[]> = {
  [Role.CarrierAdmin]: [
    "/loads",
    "/orders",
    "/trips",
    "/carrier-trips",
    "/load-board",
    "/planning",
    "/good-profiles",
    "/customers",
    "/business-locations",
    "/suppliers",
    "/carriers",
    "/payto",
    "/drivers",
    "/relations",
    "/tractors",
    "/trailers",
    "/tracker",
    "/billing",
    "/settlements",
    "/users",
    "/reports",
    "/extensions",
    "/organization-settings",
    "/download",
    "/forecasting",
    "/tracking",
    "/customer-portal",
  ],
  [Role.CarrierDriver]: ["/download"],
  [Role.Clerical]: [
    "/orders",
    "/planning",
    "/trips",
    "/carrier-trips",
    "/customers",
    "/business-locations",
    "/suppliers",
    "/carriers",
    "/payto",
    "/drivers",
    "/relations",
    "/tractors",
    "/trailers",
    "/tracker",
  ],
  [Role.Dispatcher]: [
    "/loads",
    "/orders",
    "/trips",
    "/carrier-trips",
    "/load-board",
    "/planning",
    "/driver-shifts",
    "/relations",
    "/forecasting",
  ],
  [Role.Manager]: [
    "/loads",
    "/orders",
    "/trips",
    "/carrier-trips",
    "/load-board",
    "/planning",
    "/good-profiles",
    "/customers",
    "/business-locations",
    "/suppliers",
    "/carriers",
    "/payto",
    "/drivers",
    "/relations",
    "/tractors",
    "/trailers",
    "/tracker",
    "/billing",
    "/settlements",
    "/users",
    "/reports",
    "/extensions",
    "/organization-settings",
    "/download",
    "/forecasting",
    "/tracking",
    "/customer-portal",
  ],
  [Role.Hr]: ["/users", "/drivers"],
  [Role.Accounting]: [
    "/billing",
    "/settlements",
    "/reports",
    "/customers",
    "/business-locations",
    "/tractors",
    "/trailers",
    "/carriers",
    "/forecasting",
  ],
};
