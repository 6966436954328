import { useNavigate } from "react-router-dom";
import {
  BusinessEntityType,
  useAddBusinessEntityMutation,
  useGetBusinessEntitiesQuery,
  useGetLoadBoardLoadDetailsQuery,
} from "../../../graphql/generated";
import LoadingOverlay from "../../common/LoadingOverlay";
import LoadBoardLoadDetails, {
  LoadBoardLoadDetailsProps,
} from "./LoadBoardLoadDetails";
import { omit } from "lodash";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";

type LoadBoardLoadDetailsContainerProps = Omit<
  LoadBoardLoadDetailsProps,
  "load" | "onImport"
> & {
  loadId: string;
};

const LoadBoardLoadDetailsContainer = ({
  loadId,
  ...props
}: LoadBoardLoadDetailsContainerProps) => {
  const getLoadBoardLoadDetailsQuery = useGetLoadBoardLoadDetailsQuery({
    loadId,
  });
  const navigate = useNavigate();
  const addBusinessEntityMutation = useAddBusinessEntityMutation();
  const load = getLoadBoardLoadDetailsQuery.data?.loadBoardLoadById;

  async function onImport() {
    if (!load) {
      return;
    }
    let customerId: string = "";
    const existingCustomersWithSameName =
      await useGetBusinessEntitiesQuery.fetcher({
        businessEntityTypes: [
          BusinessEntityType.Customer,
          BusinessEntityType.Broker,
        ],
        filter: {
          name: load.customer?.name,
        },
      })();
    const existingCustomer =
      existingCustomersWithSameName.businessEntities.data[0];
    if (existingCustomer) {
      customerId = existingCustomer._id;
    } else {
      const result = await addBusinessEntityMutation.mutateAsync({
        newBusinessEntityData: omit(load.customer, "_id"),
      });
      customerId = result.addBusinessEntity._id;
    }
    navigate("/orders/new", {
      state: { loadBoardShipmentId: loadId, customerId },
    });
  }
  if (getLoadBoardLoadDetailsQuery.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (getLoadBoardLoadDetailsQuery.error) {
    return <ErrorMessage error={getLoadBoardLoadDetailsQuery.error} />;
  }
  return <LoadBoardLoadDetails load={load} onImport={onImport} {...props} />;
};

export default LoadBoardLoadDetailsContainer;
