import Joi from "joi";
import { map, set } from "lodash";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import {
  ExcelCell,
  ExcelCellType,
  ExcelMapping,
  FieldMapping,
} from "../../utils/mapping/mappingTypes";
import fileDownload from "js-file-download";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Close, Download } from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import geocodeAddress, {
  parseTextualAddress,
} from "../../utils/geo/geocodeAddress";
// @ts-ignore
import parseAddress from "parse-address";
import { TextualAddress } from "../../graphql/generated";
import useDialog from "../../utils/hooks/useDialog";
import enumLabel from "../../utils/labels/enumLabel";
import { LoadingButton } from "@mui/lab";
import { localDistanceToMeters } from "../../utils/conversion/distance";
import { localWeightToKg } from "../../utils/conversion/weight";
import { localTemperatureToCelsius } from "../../utils/conversion/temperature";
import LynksIconButton from "./LynksIconButton";
import { useTranslation } from "react-i18next";

enum BulkImportSteps {
  UploadFile = 0,
  Validate = 1,
  Import = 2,
}
const steps = [
  { id: BulkImportSteps.UploadFile, label: "Upload File" },
  { id: BulkImportSteps.Validate, label: "Validate" },
  { id: BulkImportSteps.Import, label: "Import" },
];

export type ParsedAddressString = {
  number: string;
  prefix: string;
  street: string;
  type: string;
  suffix: string;
  sec_unit_type: string;
  sec_unit_num: string;
  city: string;
  state: string;
  zip: string;
};

function createExcelFile(headers: string[]) {
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet([headers]);
  XLSX.utils.book_append_sheet(workbook, sheet, "TrueTMS");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "buffer",
  });

  fileDownload(excelBuffer, "model.xlsx");
}

const formatType = async (cellOptions: FieldMapping, value: ExcelCellType) => {
  const { enumOptions, type, unitType } = cellOptions;
  if (value === null || value === undefined) {
    return value;
  }
  if (type === "string") {
    return value.toString().trim();
  }
  if (type === "number") {
    let parsedValue = Number.parseFloat(String(value).trim());
    if (unitType === "distance") {
      parsedValue = localDistanceToMeters(parsedValue);
    }
    if (unitType === "weight") {
      parsedValue = localWeightToKg(parsedValue);
    }
    if (unitType === "temperature") {
      parsedValue = localTemperatureToCelsius(parsedValue);
    }
    return parsedValue;
  }
  if (type === "boolean") {
    return value === "yes" ? true : false;
  }
  if (type === "enum") {
    return (enumOptions || []).find(
      (enumOption) =>
        enumOption === value.toString().trim().toLowerCase() ||
        enumLabel(enumOption)?.toLowerCase() ===
          value.toString().trim().toLowerCase()
    );
  }
  if (type === "[enum]") {
    return (value || "")
      .toString()
      .trim()
      .split(",")
      .map((v) =>
        (enumOptions || []).find(
          (enumOption) =>
            enumOption === v.toString().trim().toLowerCase() ||
            enumLabel(enumOption)?.toLowerCase() ===
              v.toString().trim().toLowerCase()
        )
      );
  }

  if (type === "[string]") {
    return (value || "").toString().trim().split(",");
  }

  if (type === "physicalAddress") {
    if (!value) {
      return null;
    }
    const geocodedAddress = await geocodeAddress(String(value).trim());
    if (geocodedAddress) {
      return {
        ...geocodedAddress,
        label: String(value).trim(),
      };
    }
    return null;
  }

  if (type === "textualAddress") {
    const parsed: ParsedAddressString | null = parseAddress.parseLocation(
      String(value).trim()
    );
    if (parsed) {
      const textualAddress: TextualAddress = parseTextualAddress(
        String(value).trim()
      );
      return textualAddress;
    }
    return null;
  }

  if (type === "date") {
    return new Date(String(value).trim());
  }
};

function ExcelImporter({
  onBulkCreate,
  schema,
  mapping,
}: {
  onBulkCreate: (data: any[]) => Promise<any>;
  schema: Joi.ObjectSchema<any>;
  mapping: ExcelMapping;
}) {
  const { t } = useTranslation("common");
  const [file, setFile] = useState<File | null | undefined>(null);
  const [formErrors, setFormErrors] = useState<
    Array<{ row: number; error: Joi.ValidationError | undefined }>
  >([]);
  const [forms, setForms] = useState<Array<ExcelCell>>([]);
  const { showDialog } = useDialog();

  const validate = (form: any) => {
    const validationResult = schema.validate(form, {
      abortEarly: false,
    });
    return validationResult;
  };

  const buildFormInput = (sheetData: Array<ExcelCell>) => {
    let errors:
      | Array<{ row: number; error: Joi.ValidationError | undefined }>
      | [] = [];
    const forms: Promise<Array<ExcelCell>> = Promise.all(
      sheetData.map(async (row, i) => {
        const form: ExcelCell = {};
        for (const header in mapping) {
          // there is a "*" appended to the required fields names
          const rowHeader = mapping[header].required ? `${header}*` : header;
          const headerMapping = mapping[header];
          if (!headerMapping) {
            continue;
          }
          if (!row[rowHeader] || String(row[rowHeader]).trim() === "") {
            continue;
          }
          set(
            form,
            mapping[header].key,
            await formatType(mapping[header], row[rowHeader])
          );
        }

        const validationResult = validate(form);
        if (validationResult.error) {
          // @ts-ignore
          errors.push({ row: i, error: validationResult.error });
        }
        return form;
      })
    );
    return { forms, errors };
  };

  const handleImportClick = () => {
    if (!file) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      setFormErrors([]);
      const data = event.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData: Array<{ [key in string]: string | number }> =
        XLSX.utils.sheet_to_json(sheet);
      const { forms, errors } = buildFormInput(sheetData);
      const _forms = await forms;
      setForms(_forms);
      if (errors.length > 0) {
        setFormErrors(errors);
        return;
      }
    };
    fileReader.readAsBinaryString(file);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const reset = () => {
    setFile(null);
    setFormErrors([]);
    setForms([]);
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    setLoading(true);
    if (activeStep === BulkImportSteps.UploadFile) {
      handleImportClick();
      setFile(null);
      setLoading(false);
      return;
    }
    if (activeStep === BulkImportSteps.Validate) {
      if (formErrors.length > 0) {
        const result = window.confirm(
          "There are errors in your file. Are you sure you want to continue?"
        );
        if (!result) {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          reset();
          setLoading(false);
          return;
        }
      }
      try {
        await onBulkCreate(forms);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoading(false);
      } catch (e) {
        const error = e as Error;
        console.error(error);
        showDialog({
          type: "error",
          title: "Error when importing",
          description: `The following error happened while importing the file: ${
            error.message || "Unknown error"
          }}`,
        });
        setLoading(false);
      }
      return;
    }
    if (activeStep === BulkImportSteps.Import) {
      handleClose();
      setLoading(false);
      // Temporary until we setup refetching in the parent components
      window.location.reload();
      return;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        color="secondary"
        size="large"
        id="import-button"
        endIcon={<Download />}
      >
        {t("list.import", "Import")}
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          Bulk Import
          <LynksIconButton
            color="primary"
            aria-label="close"
            onClick={handleClose}
          >
            <Close />
          </LynksIconButton>
        </DialogTitle>
        <DialogContent sx={{ minHeight: "400px" }}>
          <Stepper
            activeStep={activeStep}
            sx={{
              mt: 1,
              mb: 3,
            }}
          >
            {steps.map((step, index) => {
              const displayError = formErrors.length > 0 && index === 1;

              return (
                <Step key={step.label}>
                  <StepLabel error={displayError}>
                    <Typography color={displayError ? "error" : "inherit"}>
                      {step.label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === BulkImportSteps.UploadFile && (
            <React.Fragment>
              <Stack spacing={4}>
                <Button
                  onClick={() =>
                    createExcelFile(
                      // add an "*" to required fields
                      map(mapping, (field, mappingKey) =>
                        field.required ? `${mappingKey}*` : mappingKey
                      )
                    )
                  }
                  variant="outlined"
                  color="secondary"
                  size="large"
                  id="import-button"
                  endIcon={<Download />}
                  sx={{ alignSelf: "end" }}
                >
                  Download model
                </Button>
                <DialogContentText>
                  Drag and drop your excel file here or click to select a file.
                </DialogContentText>
                <Box sx={{ height: 100 }}>
                  <FileUploader
                    handleChange={(file: File | null | undefined) =>
                      setFile(file)
                    }
                    name="file"
                    types={["XLSX", "CSV", "XLS"]}
                    style={{
                      minHeight: 200,
                    }}
                    classes="file-uploader"
                  />
                </Box>
              </Stack>
            </React.Fragment>
          )}
          {activeStep === BulkImportSteps.Validate && (
            <React.Fragment>
              <Stack spacing={2} alignItems="center">
                <Alert severity={formErrors.length ? "error" : "info"}>
                  {formErrors.length > 0
                    ? `${formErrors.length} row(s) failed to validate.`
                    : "No errors found. Click the import button below to proceed."}
                </Alert>
                {!formErrors.length ? (
                  <Typography>Total records: {forms.length}.</Typography>
                ) : null}

                {formErrors.map((formError) => (
                  <Alert severity="error" key={formError.row}>
                    Row {formError.row + 2}: {formError.error?.message}
                  </Alert>
                ))}
              </Stack>
            </React.Fragment>
          )}
          {activeStep === BulkImportSteps.Import && (
            <React.Fragment>
              <Alert severity="success">
                {forms.length} rows imported successfully.
              </Alert>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            onClick={handleNext}
            disabled={
              (activeStep === BulkImportSteps.UploadFile && !file) ||
              (activeStep === BulkImportSteps.Validate && !!formErrors.length)
            }
            variant="contained"
            loading={loading}
          >
            {activeStep === BulkImportSteps.UploadFile && "Validate"}
            {activeStep === BulkImportSteps.Validate && "Import"}
            {activeStep === BulkImportSteps.Import && "Finish"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ExcelImporter;
