import { endpoint } from "../config/endpoint";
import keycloak from "../providers/keycloak";

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables,
  options: HeadersInit | undefined = undefined
) {
  return async (): Promise<TData> => {
    const businessEntityAccessToken = localStorage.getItem(
      "businessEntityAccessToken"
    );
    // the tracking page doesn't need authentication
    if (
      !window.location.pathname.startsWith("/tracking") &&
      !window.location.pathname.startsWith("/customer-portal")
    ) {
      try {
        if (!keycloak.authenticated) {
          await keycloak.login({
            redirectUri: window.location.origin,
          });
        }
        if (keycloak.isTokenExpired(30)) {
          await keycloak.updateToken(30);
        }
      } catch (e) {
        try {
          await keycloak.login({
            redirectUri: window.location.origin,
          });
        } catch (error) {
          console.error("Failed to login with Keycloak", error);
          throw new Error("Failed to login");
        }
      }
    }
    const res = await fetch(`${endpoint}/graphql`, {
      method: "POST",
      ...{
        headers: {
          credentials: "include",
          "content-type": "application/json",
          authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
          "x-business-entity-access-token": businessEntityAccessToken
            ? businessEntityAccessToken
            : "",
        },
      },
      body: JSON.stringify({ query, variables }),
    });

    if (!res.ok) {
      const json = await res.json();
      const thrownError = new Error(`Request failed with status ${res.status}`);
      // @ts-ignore
      thrownError.response = res;
      if (json.message) {
        thrownError.message = `Request failed with status ${res.status}: ${json.message}`;
      }
      throw thrownError;
    }

    const json = await res.json();

    if (json.errors) {
      const {
        message,
        extensions: { exception },
      } = json.errors[0];
      // We throw an error so we can keep the stack
      // but Error does not support additional properties
      // so we have to ts-ignore them
      const thrownError = new Error(message);
      if (exception) {
        thrownError.message = message;
        thrownError.name = exception.name;
        // @ts-ignore
        thrownError.data = exception.data;
        // @ts-ignore
        thrownError.type = exception.type;
      }
      throw thrownError;
    }

    return json.data;
  };
}
