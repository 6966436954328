import distance from "@turf/distance";
import { useMemo, useState } from "react";
import {
  AssetStatus,
  ConstraintValidationException,
  Exception,
  ExceptionType,
  ShipmentConstraintType,
  TrailerType,
  TripAssetAssignment,
  TripAssetTypes,
  useGenerateTripMutation,
  useAssignTripAssetsMutation,
  useAssignTripTrailerCompartmentsMutation,
  useGetAssignmentAssetsQuery,
  useGetTripQuery,
  useValidateAssetCustomFieldsMutation,
} from "../../../graphql/generated";
import coordinatesToTurfPoint from "../../../utils/geo/coordinatesToTurfPoint";
import AssignmentModal, {
  AssignmentModalProps,
  AssignmentData,
} from "./AssignmentModal";
import { intersection, isNull, isUndefined } from "lodash";
import formatDistance from "../../../utils/labels/formatDistance";
import formatTemperature from "../../../utils/labels/formatTemperature";
import { useConfirm } from "material-ui-confirm";
import { Typography } from "@mui/material";

interface AssignmentModalContainerProps {
  tripId?: string | null;
  shipments?: AssignmentModalProps["shipments"];
  open: boolean;
  onClose: (tripId?: string | null) => void;
}

function AssignmentModalContainer({
  tripId,
  shipments,
  open,
  onClose,
}: AssignmentModalContainerProps) {
  const assignmentAssetsQuery = useGetAssignmentAssetsQuery();
  const assignTripAssetsMutation = useAssignTripAssetsMutation();
  const assignTripTrailerCompartmentsMutation =
    useAssignTripTrailerCompartmentsMutation();
  const generateTripMutation = useGenerateTripMutation();
  const validateAssetCustomFieldsMutation =
    useValidateAssetCustomFieldsMutation();
  const confirm = useConfirm();

  const assetLinkings = assignmentAssetsQuery.data?.assetLinkings.data || [];

  const [assignments, setAssignments] = useState<AssignmentData | null>(null);

  const tripDetailsQuery = useGetTripQuery({
    id: tripId || "",
  });

  const trip = tripId ? tripDetailsQuery.data?.tripById : null;

  const updateAssignments = (newAssignments: AssignmentData) => {
    setAssignments(newAssignments);
  };

  const submitAssignments = async (assignments: AssignmentData) => {
    const validationResponses = await Promise.all(
      (trip?.shipments || shipments || [])
        .flatMap((shipment) =>
          [
            assignments.trailer &&
              validateAssetCustomFieldsMutation.mutateAsync({
                assetType: TripAssetTypes.Trailer,
                assetId: assignments.trailer?._id,
                shipmentId: shipment._id,
              }),
            assignments.tractor &&
              validateAssetCustomFieldsMutation.mutateAsync({
                assetType: TripAssetTypes.Tractor,
                assetId: assignments.tractor?._id,
                shipmentId: shipment._id,
              }),
            assignments.driver &&
              validateAssetCustomFieldsMutation.mutateAsync({
                assetType: TripAssetTypes.Driver,
                assetId: assignments.driver?._id,
                shipmentId: shipment._id,
              }),
            assignments.carrier &&
              validateAssetCustomFieldsMutation.mutateAsync({
                assetType: TripAssetTypes.Carrier,
                assetId: assignments.carrier?._id,
                shipmentId: shipment._id,
              }),
            assignments.additionalTrailers &&
              assignments.additionalTrailers.map((trailer) =>
                validateAssetCustomFieldsMutation.mutateAsync({
                  assetType: TripAssetTypes.Trailer,
                  assetId: trailer._id,
                  shipmentId: shipment._id,
                })
              ),
          ].flat()
        )
        .filter(Boolean)
    );

    if (
      validationResponses.some(
        (response) => response && response.validateAssetCustomFields.length
      )
    ) {
      const validationErrors = validationResponses.flatMap(
        (response) => response?.validateAssetCustomFields || []
      );
      const validationErrorMessages = validationErrors.map(
        (error) => error.message
      );
      const hasHardRequirement = validationErrors.some(
        (error) => error.isRequired
      );
      try {
        if (hasHardRequirement) {
          await confirm({
            title: "Validation Failed",
            description: (
              <Typography>
                Validation failed due to the following errors: <br />
                {validationErrorMessages.map((message) => (
                  <div key={message}>{message}</div>
                ))}
              </Typography>
            ),
            hideCancelButton: true,
          });
          return;
        } else {
          await confirm({
            title: "Validation Warning",
            description: (
              <Typography>
                Validation warning due to the following errors: <br />
                {validationErrorMessages.map((message) => (
                  <div key={message}>{message}</div>
                ))}
              </Typography>
            ),
            confirmationText: "Continue with errors",
            cancellationText: "Cancel",
            confirmationButtonProps: {
              color: "secondary",
              variant: "contained",
            },
          });
        }
      } catch (error) {
        // If the user cancels the confirmation, we should not proceed
        if (!error) {
          return;
        }
        throw error;
      }
    }
    if (tripId) {
      const assignmentDatas: TripAssetAssignment[] = [];
      assignmentDatas.push({
        tripId: tripId,
        assetId: assignments.trailer?._id || null,
        assetType: TripAssetTypes.Trailer,
      });
      assignmentDatas.push({
        tripId: tripId,
        assetId: assignments.tractor?._id || null,
        assetType: TripAssetTypes.Tractor,
      });
      assignmentDatas.push({
        tripId: tripId,
        assetId: assignments.driver?._id || null,
        assetType: TripAssetTypes.Driver,
      });
      assignmentDatas.push({
        tripId: tripId,
        assetId: assignments.carrier?._id || null,
        assetType: TripAssetTypes.Carrier,
      });
      assignmentDatas.push({
        tripId: tripId,
        assetId:
          assignments.additionalTrailers?.map((t) => t._id).join(",") || null,
        assetType: TripAssetTypes.AdditionalTrailers,
      });
      await assignTripAssetsMutation.mutateAsync({
        assignmentData: assignmentDatas,
      });
      if (assignments.compartmentAssignments) {
        await assignTripTrailerCompartmentsMutation.mutateAsync({
          tripId,
          trailerCompartmentAssignments: assignments.compartmentAssignments,
        });
      }
      onClose(tripId);
      window.analytics?.track("Trip Assigned", {
        tripId,
      });
      window.analytics?.identify({
        loadAssigned: true,
        tripAssigned: true,
      });
      window.analytics?.group(window.analytics?.group?.()?.id?.(), {
        loadAssigned: true,
        tripAssigned: true,
      });
    } else if (shipments) {
      const result = await generateTripMutation.mutateAsync({
        generateTripData: {
          shipments: shipments.map((shipment) => shipment._id),
          trailer: assignments.trailer?._id,
          tractor: assignments.tractor?._id,
          driver: assignments.driver?._id,
          carrier: assignments.carrier?._id,
          additionalTrailers: assignments.additionalTrailers?.map(
            (trailer) => trailer._id
          ),
          trailerCompartmentAssignments: assignments.compartmentAssignments,
        },
      });
      onClose(result.generateTrip._id);
      window.analytics?.track("Load Assigned", {
        shipmentIds: shipments.map((shipment) => shipment._id),
        tripId: result.generateTrip._id,
      });
      window.analytics?.identify({
        loadAssigned: true,
        lastLoadAssignmentDate: new Date(),
        lastLoadAssignmentDateOnly: new Date().toISOString().split("T")[0],
        numberOfLoadsAssigned:
          (window.analytics?.user?.()?.traits?.()?.numberOfLoadsAssigned || 0) +
          1,
      });
      window.analytics?.group(window.analytics?.group?.()?.id?.(), {
        loadAssigned: true,
        lastLoadAssignmentDate: new Date(),
        lastLoadAssignmentDateOnly: new Date().toISOString().split("T")[0],
        numberOfLoadsAssigned:
          (window.analytics?.user?.()?.traits?.()?.numberOfLoadsAssigned || 0) +
          1,
      });
    }
  };

  const assetsAreFarApart = useMemo(() => {
    if (!assignments) {
      return false;
    }
    const distancesBetweenAssets = [
      assignments.trailer,
      assignments.tractor,
      assignments.driver,
    ]
      .map((asset1) => {
        return [
          assignments.trailer,
          assignments.tractor,
          assignments.driver,
        ].map((asset2) => {
          if (
            asset1 &&
            asset2 &&
            asset1.lastKnownLocation &&
            asset2.lastKnownLocation
          ) {
            return distance(
              coordinatesToTurfPoint(asset1.lastKnownLocation),
              coordinatesToTurfPoint(asset2.lastKnownLocation),
              { units: "meters" }
            );
          }
          return 0;
        });
      })
      .flat();

    if (distancesBetweenAssets.some((d) => d > 3000)) {
      return true;
    }
    return false;
  }, [assignments]);

  const trailerTypes = intersection(
    ...(trip
      ? trip.shipments.map((shipment) => [
          shipment.trailerType,
          ...(shipment.additionalTrailerTypes || []),
        ])
      : (shipments || []).map((shipment) => [
          shipment.trailerType,
          ...(shipment.additionalTrailerTypes || []),
        ]))
  );

  if (!open) {
    return null;
  }

  const transformAssignmentError = (
    error: Exception | null
  ): Exception | null => {
    if (!error) {
      return null;
    }
    if (error.type === ExceptionType.ConstraintValidation) {
      return {
        ...error,
        message: (error as ConstraintValidationException).data.failedConstraints
          .map((failedConstraint) => {
            const constraintLabel = failedConstraint.constraintType
              .replace(/_/g, " ")
              .toLowerCase();
            const formatter = [
              ShipmentConstraintType.Width,
              ShipmentConstraintType.Length,
              ShipmentConstraintType.Height,
            ].includes(failedConstraint.constraintType)
              ? formatDistance
              : formatTemperature;
            const constraintValueLabel = formatter(
              parseFloat(failedConstraint.constraintValue)
            );
            const trailerCapacityValue = failedConstraint.trailerCapacityValue;
            const trailerCapacityLabel = `${
              isUndefined(trailerCapacityValue) || isNull(trailerCapacityValue)
                ? "N/A"
                : formatter(trailerCapacityValue)
            }`;
            return `The ${constraintLabel} of load ${failedConstraint.shipmentNumber} (${constraintValueLabel}) exceeds the ${constraintLabel} of the trailer (${trailerCapacityLabel}).`;
          })
          .join("\n"),
      };
    }
    return error;
  };

  return (
    <AssignmentModal
      trip={trip}
      shipments={shipments}
      loading={assignmentAssetsQuery.isLoading || tripDetailsQuery.isLoading}
      trailers={(
        assignmentAssetsQuery.data?.trailers.data.filter(
          (trailer) => trailer.status === AssetStatus.Active
        ) || []
      )
        .filter((t) => trailerTypes.includes(t.type))
        .filter((trailer) => {
          const currentAssetLinking = assetLinkings.find(
            (linking) =>
              (assignments?.trailer?._id &&
                linking.trailer?._id === assignments?.trailer?._id) ||
              (assignments?.tractor?._id &&
                linking.tractor?._id === assignments?.tractor?._id)
          );
          if (currentAssetLinking?.isHardLinked) {
            return trailer._id === currentAssetLinking.trailer?._id;
          }
          return true;
        })}
      allTrailers={assignmentAssetsQuery.data?.trailers.data || []}
      tractors={
        assignmentAssetsQuery.data?.tractors.data
          .filter((tractor) => tractor.status === AssetStatus.Active)
          .filter((tractor) => {
            const currentAssetLinking = assetLinkings.find(
              (linking) =>
                (assignments?.trailer?._id &&
                  linking.trailer?._id === assignments?.trailer?._id) ||
                (assignments?.tractor?._id &&
                  linking.tractor?._id === assignments?.tractor?._id)
            );
            if (currentAssetLinking?.isHardLinked) {
              return tractor._id === currentAssetLinking.tractor?._id;
            }
            return true;
          }) || []
      }
      drivers={
        assignmentAssetsQuery.data?.drivers.data.filter(
          (driver) => driver.status === AssetStatus.Active
        ) || []
      }
      carriers={(assignmentAssetsQuery.data?.carriers.data || []).filter(
        (carrier) => intersection(carrier.trailerTypes, trailerTypes).length > 0
      )}
      assetLinkings={assetLinkings}
      error={
        transformAssignmentError(assignTripAssetsMutation.error) ||
        assignmentAssetsQuery.error ||
        transformAssignmentError(generateTripMutation.error)
      }
      warning={
        assetsAreFarApart
          ? "The assets you selected are more than 3 km apart"
          : undefined
      }
      open={open}
      assigning={
        assignTripAssetsMutation.isLoading || generateTripMutation.isLoading
      }
      isPowerOnly={trailerTypes.includes(TrailerType.PowerOnly)}
      onClose={onClose}
      onSubmit={submitAssignments}
      onChange={updateAssignments}
    />
  );
}

export default AssignmentModalContainer;
