import {
  TripAssetTypes,
  useGetDriverDetailsQuery,
  useGetTractorDetailsQuery,
  useGetTrailerDetailsQuery,
} from "../../../graphql/generated";
import AssetLink, { AssetLinkProps } from "./AssetLink";
import { useRef } from "react";

type AssetLinkContainerProps = Omit<AssetLinkProps, "asset"> & {
  assetId: string;
};

const AssetLinkContainer: React.FC<AssetLinkContainerProps> = ({
  assetType,
  assetId,
}) => {
  const initialAssetTypeRef = useRef(assetType);
  const assetDetailsQuery =
    initialAssetTypeRef.current === TripAssetTypes.Tractor
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetTractorDetailsQuery({
          id: assetId,
        })
      : initialAssetTypeRef.current === TripAssetTypes.Trailer
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetTrailerDetailsQuery({
          id: assetId,
        })
      : initialAssetTypeRef.current === TripAssetTypes.Driver
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetDriverDetailsQuery({
          id: assetId,
        })
      : null;

  if (!assetDetailsQuery) {
    return null;
  }

  const assetDetailsData = assetDetailsQuery.data;

  if (!assetDetailsData) {
    return null;
  }

  const assetDetails =
    "tractorById" in assetDetailsData
      ? assetDetailsData.tractorById
      : "trailerById" in assetDetailsData
      ? assetDetailsData.trailerById
      : "driverById" in assetDetailsData
      ? assetDetailsData.driverById
      : null;

  if (!assetDetails) {
    return null;
  }

  return (
    <AssetLink assetType={initialAssetTypeRef.current} asset={assetDetails} />
  );
};

export default AssetLinkContainer;
