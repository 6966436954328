import { useState } from "react";
import {
  DateRangeInput,
  MetricType,
  useGetBulkMetricsQuery,
} from "../../../graphql/generated";
import AnalyticsGraph, { AnalyticsGraphProps } from "./AnalyticsGraph";

type AnalyticsGraphContaierProps = Omit<
  AnalyticsGraphProps,
  "metrics" | "onGraphOptionsChange"
> & {
  dateRange?: DateRangeInput | null;
  onGraphOptionsChange?: AnalyticsGraphProps["onGraphOptionsChange"];
};

const AnalyticsGraphContainer = (props: AnalyticsGraphContaierProps) => {
  const [metricTypes, setMetricTypes] = useState<MetricType[]>([
    MetricType.ProductPurchaseCost,
  ]);
  const [aggregateBy, setAggregateBy] = useState<string[]>([]);

  const metricsQuery = useGetBulkMetricsQuery({
    types: metricTypes,
    aggregateBy: aggregateBy,
    dateRange: props.dateRange,
  });

  const metrics = metricsQuery.data?.bulkMetrics || [];

  return (
    <AnalyticsGraph
      {...props}
      metrics={metrics}
      onGraphOptionsChange={(options) => {
        setMetricTypes(options.metricTypes);
        setAggregateBy(
          [options.xAxisDimension, options.aggregateBy].filter(Boolean)
        );
        props.onGraphOptionsChange?.(options);
      }}
    />
  );
};

export default AnalyticsGraphContainer;
