import { Box } from "@mui/material";
import { GridRow, GridRowProps, GridValidRowModel } from "@mui/x-data-grid-pro";
import { memo, useRef } from "react";

type DataGridCustomRowProps = GridRowProps & {
  onDragStart?: (
    event: React.DragEvent<HTMLDivElement>,
    row: GridValidRowModel
  ) => void;
  onDragEnd?: (
    event: React.DragEvent<HTMLDivElement>,
    row: GridValidRowModel
  ) => void;
};

export const DataGridCustomRow = memo((props: DataGridCustomRowProps) => {
  const dragTipRef = useRef(null);

  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    if (dragTipRef.current) {
      event.dataTransfer.setDragImage(dragTipRef.current, -20, 8);
    }

    const { row } = props;
    if (row === undefined) {
      throw new Error("The row should exist");
    }

    props.onDragStart?.(event, row);
  };

  const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    props.onDragEnd?.(event, props.row);
  };

  return (
    <Box draggable onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Box
        ref={dragTipRef}
        // Utilize an existing and off-screen HTMLElement as the drag feedback image.
        // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setDragImage#imgelement
        sx={(theme) => ({
          position: "fixed",
          left: -10000,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          p: 1,
        })}
      >
        {`1 selected row`}
      </Box>
      <GridRow {...props} />
    </Box>
  );
});
