import { omit } from "lodash";
import {
  AssetLinkingInput,
  GetAssetLinkingDetailsQuery,
  useAddAssetLinkingMutation,
  useEditAssetLinkingMutation,
  useGetAssetLinkingDetailsQuery,
} from "../../../graphql/generated";
import AssetLinkingFormModal, {
  AssetLinkingFormModalProps,
} from "./AssetLinkingFormModal";
import LoadingOverlay from "../../common/LoadingOverlay";

type AssetLinkingFormModalContainerProps = Omit<
  AssetLinkingFormModalProps,
  "assetLinking" | "onSubmit" | "error"
> & {
  assetLinkingId?: string;
  onSaved?: (assetLinkingId: string) => void;
  overrideCurrentAssignment?: boolean;
};

type AssetLinkingDetailsData = Exclude<
  GetAssetLinkingDetailsQuery["assetLinking"],
  null | undefined
>;

const assetLinkingToInput = (
  assetLinking: AssetLinkingDetailsData | null
): AssetLinkingInput | null => {
  if (!assetLinking) {
    return null;
  }
  return {
    ...omit(assetLinking, "_id"),
    driver: assetLinking.driver?._id || null,
    trailer: assetLinking.trailer?._id || null,
    tractor: assetLinking.tractor?._id || null,
    additionalTrailers: assetLinking.additionalTrailers?.map(
      (trailer) => trailer._id
    ),
  };
};

const AssetLinkingFormModalContainer = (
  props: AssetLinkingFormModalContainerProps
) => {
  const assetLinkingQuery = useGetAssetLinkingDetailsQuery(
    {
      id: props.assetLinkingId || "",
    },
    {
      enabled: !!props.assetLinkingId,
    }
  );

  const addAssetLinkingMutation = useAddAssetLinkingMutation();
  const editAssetLinkingMutation = useEditAssetLinkingMutation();

  const existingAssetLinking = assetLinkingQuery?.data?.assetLinking || null;

  const submitAssetLinking = async (assetLinking: AssetLinkingInput) => {
    const existingAssetLinkingIsPermanent = !existingAssetLinking?.endDate;
    if (
      props.overrideCurrentAssignment &&
      existingAssetLinking &&
      existingAssetLinkingIsPermanent
    ) {
      const response = await addAssetLinkingMutation.mutateAsync({
        newAssetLinkingData: assetLinking,
      });
      props.onSaved?.(response.addAssetLinking._id);
    } else if (existingAssetLinking?._id) {
      const response = await editAssetLinkingMutation.mutateAsync({
        id: existingAssetLinking._id,
        editAssetLinkingData: assetLinking,
      });
      props.onSaved?.(response.editAssetLinking._id);
    } else {
      const response = await addAssetLinkingMutation.mutateAsync({
        newAssetLinkingData: assetLinking,
      });
      props.onSaved?.(response.addAssetLinking._id);
    }
    assetLinkingQuery.refetch();
    props.onClose();
  };

  if (props.assetLinkingId && assetLinkingQuery.isLoading) {
    return <LoadingOverlay loading />;
  }

  return (
    <AssetLinkingFormModal
      assetLinking={assetLinkingToInput(existingAssetLinking)}
      onSubmit={submitAssetLinking}
      loading={
        addAssetLinkingMutation.isLoading ||
        editAssetLinkingMutation.isLoading ||
        (!!props.assetLinkingId && assetLinkingQuery?.isLoading)
      }
      error={addAssetLinkingMutation.error || editAssetLinkingMutation.error}
      {...props}
    />
  );
};

export default AssetLinkingFormModalContainer;
