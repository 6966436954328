import {
  GetShipmentsForBestBuyBulkUpdateQuery,
  PurchaseInput,
} from "../../../graphql/generated";
import BestBuyModal from "../BestBuyModal/BestBuyModal";

type ShipmentForBestBuyBulkUpdate =
  GetShipmentsForBestBuyBulkUpdateQuery["shipments"]["data"][0];

export type BestBuyBulkUpdateModalProps = {
  open: boolean;
  shipments: ShipmentForBestBuyBulkUpdate[];
  onClose: () => void;
  onSubmit: (shipmentIds: Array<string>, contractId: string) => void;
};

const BestBuyBulkUpdateModal = ({
  open,
  onSubmit,
  shipments,
  onClose,
}: BestBuyBulkUpdateModalProps) => {
  const purchases: PurchaseInput[] = shipments
    .flatMap((s) =>
      s.shipmentLocations.flatMap((sl) =>
        (sl.shippedGoods || []).map((sg) => ({
          ...sg,
          shipment: s,
          shipmentLocation: sl,
        }))
      )
    )
    .filter((sg) => sg.goodProfileId)
    .map((shippedGood) => {
      const receiverLocation = shippedGood.shipment.shipmentLocations.find(
        (sl) =>
          sl.receivedGoods?.some(
            (rg) => rg.goodProfileId === shippedGood.goodProfileId
          )
      );
      if (!receiverLocation) {
        throw new Error("Receiver location not found");
      }
      return {
        customerId: shippedGood.shipment.customer._id,
        date: shippedGood.shipment.route?.lastDropoffTime,
        dropoffLocation: receiverLocation.location,
        goodProfileId: shippedGood.goodProfileId as string,
        quantity: shippedGood.quantity,
      };
    });
  return (
    <BestBuyModal
      open={open}
      onClose={onClose}
      purchase={purchases}
      onBestBuyItemSubmit={(bestBuyItem) => {
        onSubmit(
          shipments.map((s) => s._id),
          bestBuyItem.supplierContract._id
        );
      }}
    />
  );
};

export default BestBuyBulkUpdateModal;
