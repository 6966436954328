import { omit } from "lodash";
import { useState } from "react";
import {
  S3_Operation,
  useGetSignedUrlMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import DocumentsUploadModal, {
  DocumentsUploadModalProps,
  DocumentWithFile,
} from "./DocumentsUploadModal";
import { useTranslation } from "react-i18next";

export type Document = Omit<DocumentWithFile, "file" | "_id">;

type DocumentsUploadModalContainerProps = Omit<
  DocumentsUploadModalProps,
  "onSubmit" | "isUploading"
> & {
  onSubmit: (documents: Array<Document>) => void;
};

const DocumentsUploadModalContainer = ({
  onSubmit,
  ...props
}: DocumentsUploadModalContainerProps) => {
  const { t } = useTranslation("common");
  const [isUploading, setIsUploading] = useState(false);
  const { showDialog } = useDialog();
  const signUrlMutation = useGetSignedUrlMutation();
  const handleSubmit = async (documents: DocumentWithFile[]) => {
    if (documents.length) {
      try {
        setIsUploading(true);
        const uploadedDocuments = await Promise.all(
          documents
            .filter(
              (document) => document.file !== null || document.url !== null
            )
            .map(async (document) => {
              // if it's already uploaded from an initalDocument
              if (document.url) {
                return document;
              }

              // if it's a new file upload
              // file can't be null due to above filter
              const file = document.file as File;
              const signingResult = await signUrlMutation.mutateAsync({
                document: {
                  operation: S3_Operation.PutObject,
                  extension: file.name.split(".").pop(),
                },
              });
              const getSigningResult = await signUrlMutation.mutateAsync({
                document: {
                  operation: S3_Operation.GetObject,
                  key: signingResult.documentSignedUrl.key,
                },
              });
              await fetch(signingResult.documentSignedUrl.url, {
                method: "PUT",
                body: document.file,
              });
              return {
                ...omit(document, "file"),
                ...(document.url ? { _id: document._id } : {}),
                url: getSigningResult.documentSignedUrl.url,
              };
            })
        );

        onSubmit(uploadedDocuments);
      } catch (error) {
        showDialog({
          type: "error",
          title: t(
            "documents.errors.uploadError",
            "Error while uploading document"
          ),
          description:
            (error as Error).message ||
            t("error.unknownError", "Unknown error"),
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <DocumentsUploadModal
      {...props}
      isUploading={isUploading}
      onSubmit={handleSubmit}
    />
  );
};

export default DocumentsUploadModalContainer;
