import Joi from "joi";
import { Relationship } from "../../../graphql/generated";

const driverShiftSchema = Joi.object<Relationship>({
  supplierIds: Joi.array()
    .items(Joi.string())
    .label("Supplier")
    .required()
    .min(1),
  shipperIds: Joi.array()
    .items(Joi.string())
    .label("Shipper")
    .required()
    .min(1),
  commodityIds: Joi.array()
    .items(Joi.string())
    .label("Commodity")
    .required()
    .min(1),
  receiverIds: Joi.array()
    .items(Joi.string())
    .label("Receiver")
    .required()
    .min(1),
  customerIds: Joi.array()
    .items(Joi.string())
    .label("Customer")
    .required()
    .min(1),
});

export default driverShiftSchema;
