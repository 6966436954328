import {
  useGetAllDriverShiftsQuery,
  useGetDriverListQuery,
} from "../../../graphql/generated";
import LoadingOverlay from "../../common/LoadingOverlay";
import DriverShiftAssignmentForm, {
  DriverShiftAssignmentFormProps,
} from "./DriverShiftAssignmentForm";

export type DriverShiftAssignmentFormContainerProps = Omit<
  DriverShiftAssignmentFormProps,
  "drivers" | "driverShifts"
>;

const DriverShiftAssignmentFormContainer = (
  props: DriverShiftAssignmentFormContainerProps
) => {
  const driversQuery = useGetDriverListQuery();
  const driverShiftsQuery = useGetAllDriverShiftsQuery();

  const driverShifts = driverShiftsQuery.data?.driverShifts.data || [];

  if (driversQuery.isLoading) {
    return <LoadingOverlay loading />;
  }

  return (
    <DriverShiftAssignmentForm
      drivers={driversQuery.data?.drivers.data || []}
      driverShifts={driverShifts}
      {...props}
      loading={props.loading || driversQuery.isLoading}
    />
  );
};

export default DriverShiftAssignmentFormContainer;
