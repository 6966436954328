import { RankSupplierContractsForPurchaseMutation } from "../../../graphql/generated";
import LynksTable from "../../common/LynksTable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TableField } from "../../common/LynksTable/LynksTable";
import LocaleProvider from "../../../providers/LocaleProvider";
import { capitalize } from "lodash";

export type BestBuyRankingListItem =
  RankSupplierContractsForPurchaseMutation["rankSupplierContractsForPurchase"][0];

export type BestBuyRankingListProps = {
  list: BestBuyRankingListItem[];
  onBestBuyItemSelect: (item: BestBuyRankingListItem) => void;
  defaultSelected?: BestBuyRankingListItem;
  loading?: boolean;
};

export default function BestBuyRankingList({
  list,
  onBestBuyItemSelect,
  defaultSelected: selected,
  loading,
}: BestBuyRankingListProps) {
  const { t } = useTranslation(["orders", "business", "common"]);

  const fields: Array<TableField<BestBuyRankingListItem>> = useMemo(
    () => [
      {
        value: (item) => item.supplierContract.contractNumber,
        label: t("orders:bestbuy.contractNumber", "Contract Number"),
        type: "string",
      },
      {
        value: (item) =>
          String(
            list.findIndex(
              (i) => i.supplierContract._id === item.supplierContract._id
            ) + 1
          ),
        label: t("orders:bestbuy.ranking", "Rank"),
        type: "string",
      },
      {
        value: (item) => String(item.supplierContract.supplier?.name),
        label: capitalize(t("business:supplier.one", "Supplier")),
        type: "string",
      },
      {
        value: (item) => String(item.supplierContract.shipper?.name),
        label: capitalize(t("business:shipper.one", "Shipper")),
        type: "string",
      },
      {
        value: (item) => item.supplierContract.pinCode,
        label: t("orders:bestbuy.pinCode", "Pin Code"),
        type: "string",
      },
      {
        // Need backend to return this in meters instead of miles
        value: (item) => item.mileage.toFixed(2),
        label: t("orders:distance", "Distance"),
        type: "string",
      },
      {
        value: (item) =>
          `${Math.round(
            item.productCost
          )} ${LocaleProvider.getCurrencySymbol()}`,
        label: t("orders:bestbuy.productCost", "Product Cost"),
        type: "string",
      },
      {
        value: (item) =>
          `${Math.round(
            item.shippingCost
          )} ${LocaleProvider.getCurrencySymbol()}`,
        label: t("orders:bestbuy.shippingCost", "Shipping Cost"),
        type: "string",
      },
      {
        value: (item) =>
          `${Math.round(item.totalCost)} ${LocaleProvider.getCurrencySymbol()}`,
        label: t("orders:bestbuy.totalCost", "Total Cost"),
        type: "string",
      },
    ],
    [t, list]
  );

  const dataWithTopLevelIds = useMemo(
    () =>
      (list || []).map((el, index) => ({
        _id: el.supplierContract._id,
        ...el,
      })),
    [list]
  );

  return (
    <LynksTable
      fields={fields}
      id="best-buy-ranking-list"
      data={dataWithTopLevelIds}
      onSelect={(records) => onBestBuyItemSelect(records[0])}
      disableMultipleRowSelection
      selected={
        selected
          ? dataWithTopLevelIds.filter(
              (item) => item._id === selected.supplierContract._id
            )
          : undefined
      }
      loading={loading}
      emptyState={{
        title: t("orders:bestbuy.noResults", "No contracts found"),
      }}
    />
  );
}
