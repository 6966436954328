import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Checkbox,
  Autocomplete,
  FormHelperText,
  Grid,
} from "@mui/material";

import {
  GetShipmentQuery,
  NoteAccessLevel,
} from "../../../../graphql/generated";
import UploadButton from "../../../common/UploadButton/UploadButton";
import {
  FormError,
  ShipmentDocumentInputData,
} from "../../../../redux/slices/Types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface ShipmentDocumentFormProps {
  id: number;
  document: ShipmentDocumentInputData;
  onUpload: (id: number, files: File | null) => void;
  onChange: (id: number, document: ShipmentDocumentInputData) => void;
  shipmentLocations: GetShipmentQuery["shipmentById"]["shipmentLocations"];
  errors?: FormError;
}

export const PREDEFINED_DOCUMENT_LABELS = [
  "RATECON",
  "Bill of lading - Pickup",
  "Bill of lading - Delivery",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function ShipmentDocumentForm({
  id,
  document,
  shipmentLocations,
  onChange,
  onUpload,
  errors,
}: ShipmentDocumentFormProps) {
  const { t } = useTranslation(["common", "orders"]);
  const [formErrors, setFormErrors] = useState<FormError | undefined>(errors);

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const removeError = (name: string) => {
    const err: FormError = {
      formId: formErrors?.formId || "",
      error: _.omit(formErrors?.error, name),
    };
    setFormErrors(err);
  };

  const handleChange = (name: string, value: string) => {
    removeError(name);
    if (name === "receiver") {
      return onChange(id, { ...document, [name]: value, shipper: null });
    }
    if (name === "shipper") {
      return onChange(id, { ...document, [name]: value, receiver: null });
    }
    onChange(id, { ...document, [name]: value });
  };

  const handleAccessLevelChange = (
    event: SelectChangeEvent<NoteAccessLevel[]>
  ) => {
    const {
      target: { value },
    } = event;
    removeError("accessLevel");
    onChange(id, {
      ...document,
      accessLevel: typeof value === "string" ? document.accessLevel : value,
    });
  };

  const handleUpload = (file: File | null) => {
    removeError("url");
    onUpload(id, file);
  };

  const shippersAndReceivers = shipmentLocations.map((location) => ({
    kind: location.receiver ? "receiver" : "shipper",
    name: location.receiver ? location.receiver?.name : location.shipper?.name,
    id: location.receiver ? location.receiver?._id : location.shipper?._id,
  }));

  return (
    <Stack component="form" spacing={5}>
      <Grid
        container
        spacing={1}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ md: "center" }}
      >
        <Grid item xs={12} md={5}>
          <UploadButton onUpload={handleUpload} />
          {formErrors && formErrors.error["url"] && (
            <FormHelperText sx={{ ml: 2 }} error>
              {formErrors.error["url"]}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            disablePortal
            options={PREDEFINED_DOCUMENT_LABELS}
            onChange={(
              event: React.SyntheticEvent<Element, Event>,
              value: string | null
            ) => {
              handleChange("name", value || "");
            }}
            freeSolo
            value={document.name || null}
            size="small"
            renderInput={(inputProps) => (
              <TextField
                required
                name="name"
                error={formErrors && formErrors.error["name"] ? true : false}
                helperText={formErrors && formErrors.error["name"]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(event.target.name, event.target.value)
                }
                {...inputProps}
                label={t("common:documents.titleLabel", "Document Label")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                name="isBillable"
                onChange={() =>
                  onChange(id, {
                    ...document,
                    isBillable: !document.isBillable,
                  })
                }
              />
            }
            label={t(
              "orders:documents.includeForInvoice",
              "Include for Invoicing"
            )}
          />
        </Grid>
      </Grid>
      <Stack
        direction={{
          sm: "row",
          xs: "column",
        }}
        spacing={2}
      >
        <TextField
          label={t("orders:notes.associatedLocation", "Associated location")}
          name="shipment"
          fullWidth
          select
          value={document.receiver || document.shipper}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const location = shippersAndReceivers.find(
              (c) => c.id === event.target.value
            );
            handleChange(location?.kind || "", location?.id || "");
          }}
        >
          {shippersAndReceivers.map((shipmentLocation, index) => {
            return (
              <MenuItem key={index} value={shipmentLocation.id}>
                {shipmentLocation.name}
              </MenuItem>
            );
          })}
        </TextField>
        <FormControl fullWidth>
          <InputLabel
            error={formErrors && formErrors.error["accessLevel"] ? true : false}
            id="user-label"
          >
            {t("orders:documents.visibility", "Visibility")}
          </InputLabel>
          <Select
            labelId="user-label"
            id="user"
            multiple
            size="small"
            value={document.accessLevel}
            onChange={handleAccessLevelChange}
            name="accessLevel"
            error={formErrors && formErrors.error["accessLevel"] ? true : false}
            renderValue={(selected) => (
              <Box
                sx={{
                  "& .MuiChip-root": {
                    height: 20,
                  },
                }}
              >
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={`${t(`orders:notes.accessLevel.${value}`, value)}`}
                  />
                ))}
              </Box>
            )}
            input={<OutlinedInput label="Visibility" />}
            MenuProps={MenuProps}
          >
            {Object.values(NoteAccessLevel).map((accessLevel, index) => (
              <MenuItem key={index} value={accessLevel}>
                {t(`orders:notes.accessLevel.${accessLevel}`, accessLevel)}
              </MenuItem>
            ))}
          </Select>
          {formErrors && (
            <FormHelperText error>
              {formErrors.error["accessLevel"]}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
    </Stack>
  );
}
