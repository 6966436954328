import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Status,
  useGenerateTripMutation,
  useGetShipmentSummariesQuery,
} from "../../../graphql/generated";
import TripCreator from "./TripCreator";

function TripCreatorContainer() {
  const pendingShipmentsQuery = useGetShipmentSummariesQuery({
    status: Status.WaitingForAssignment,
  });
  const generateTripMutation = useGenerateTripMutation();
  const navigate = useNavigate();

  const onShipmentsChange = useCallback(() => {
    generateTripMutation.reset();
    // Avoids maximum update depth exceeded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TripCreator
      shipments={pendingShipmentsQuery.data?.shipments.data || []}
      creating={generateTripMutation.isLoading}
      loading={pendingShipmentsQuery.isLoading}
      error={generateTripMutation.error as Error}
      onCreate={async (shipments) => {
        await generateTripMutation.mutateAsync({
          generateTripData: {
            shipments: shipments.map((shipment) => shipment._id),
          },
        });
        navigate("/trips");
      }}
      onShipmentsChange={onShipmentsChange}
    />
  );
}

export default TripCreatorContainer;
