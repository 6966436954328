import { useMemo, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Fab,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { addSeconds, formatDuration } from "date-fns";
import { capitalize, findIndex, flatten, keyBy, orderBy } from "lodash";
import {
  GetLoadTrackingBoardShipmentsQuery,
  GetShipmentSummariesQuery,
  GoodUnits,
  Shipment,
  ShipmentEvent,
  ShipmentEventType,
  ShipmentLocationType,
  Status,
} from "../../../graphql/generated";
import StepIndicator from "../../common/StepIndicator";
import { Step } from "../../common/StepIndicator/StepIndicator";
import theme from "../../../theme";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import formatWeight from "../../../utils/labels/formatWeight";
import { formatMileage } from "../../../utils/labels/formatDistance";
import {
  formatDateForLocation,
  formatLocationTimezone,
  formatTimeForLocation,
} from "../../../utils/labels/formatDateTime";
import { AccessAlarm, Route } from "@mui/icons-material";
import ShipmentLink from "../../common/ShipmentLink";
import {
  shipmentLocationAddressLabel,
  shipmentLocationLabel,
} from "../../../utils/labels/shipmentLocationLabel";
import AssignmentModalContainer from "../../trip/AssignmentModal";
import { useNavigate } from "react-router-dom";
import { shipmentStatusColors } from "../ShipmentsList/ShipmentsList";
import goodUnitLabel from "../../../utils/labels/goodUnitsLabel";
import { useTranslation } from "react-i18next";
import enumLabel from "../../../utils/labels/enumLabel";
import statusKey from "../../../utils/i18n/statusKey";

export type ShipmentDataSummary =
  GetShipmentSummariesQuery["shipments"]["data"][0] & {
    events?: GetLoadTrackingBoardShipmentsQuery["shipments"]["data"][0]["events"];
    issues?: GetLoadTrackingBoardShipmentsQuery["shipments"]["data"][0]["issues"];
  };

export type ShipmentSummaryProps = {
  shipment: ShipmentDataSummary;
  dragable?: boolean;
  assignable?: boolean;
  onClick?: (shipment: ShipmentDataSummary) => void;
  hideHeader?: boolean;
};

type ShipmentLocationTimeProps = {
  shipmentLocation: Omit<
    ShipmentDataSummary["shipmentLocations"][0],
    "timeWindows" | "__typename" | "location"
  > & {
    arrivalTime?: string;
    waitingDuration?: number;
    setupDuration?: number;
    serviceDuration?: number;
    timeWindows?: ShipmentDataSummary["shipmentLocations"][0]["timeWindows"];
  };
  variant?: TypographyProps["variant"];
  hideEndTime?: boolean;
};

export const ShipmentLocationTime = ({
  shipmentLocation,
  variant,
  hideEndTime,
}: ShipmentLocationTimeProps) => {
  const {
    timeWindows,
    arrivalTime,
    waitingDuration,
    setupDuration,
    serviceDuration,
  } = shipmentLocation;
  const startTime =
    arrivalTime || timeWindows?.length
      ? new Date(
          arrivalTime !== undefined
            ? addSeconds(new Date(arrivalTime), waitingDuration || 0)
            : timeWindows?.length
            ? timeWindows[0].fromDate
            : null
        )
      : null;
  const endTime =
    arrivalTime || timeWindows?.length
      ? new Date(
          arrivalTime !== undefined
            ? addSeconds(
                new Date(arrivalTime),
                (waitingDuration || 0) +
                  (setupDuration || 0) +
                  (serviceDuration || 0)
              )
            : timeWindows?.length
            ? timeWindows[0].toDate
            : null
        )
      : null;

  if (!startTime || !endTime) {
    return null;
  }
  const isSameDate =
    formatDateForLocation(startTime, shipmentLocation) ===
    formatDateForLocation(endTime, shipmentLocation);

  return (
    <Typography
      sx={!variant ? { fontSize: 10, fontWeight: 400 } : null}
      variant={variant}
    >
      {formatDateForLocation(startTime, shipmentLocation, false)}
      {", "}
      {formatTimeForLocation(startTime, shipmentLocation, false)}
      {!hideEndTime ? " - " : " "}
      {!isSameDate && !hideEndTime
        ? formatDateForLocation(endTime, shipmentLocation, false) + ", "
        : null}
      {!hideEndTime
        ? formatTimeForLocation(endTime, shipmentLocation)
        : `(${formatLocationTimezone(shipmentLocation)})`}
    </Typography>
  );
};

type ShipmentLocationProps = {
  shipmentLocation: ShipmentLocationTimeProps["shipmentLocation"];
  allShippedGoodsById: {
    [key: string]:
      | {
          __typename?: "Good" | undefined;
          _id: string;
          label: string;
          unit?: GoodUnits | null;
          quantity: number;
          weight?: number | null | undefined;
        }
      | undefined;
  };
  shipment?: Pick<Shipment, "_id" | "shipmentNumber"> & {
    events?: Pick<ShipmentEvent, "type" | "shipmentLocation">[] | null;
  };
  hideTime?: boolean;
  centered?: boolean;
  onShipmentEventLog?: (event: ShipmentEventType) => void;
};

export const ShipmentLocationDetails = ({
  allShippedGoodsById,
  shipmentLocation,
  shipment,
  hideTime,
  centered,
  onShipmentEventLog,
}: ShipmentLocationProps) => {
  const { t } = useTranslation("trips");
  const { shippedGoods, receivedGoods } = shipmentLocation;
  const name = shipmentLocationLabel(shipmentLocation);
  const address = shipmentLocationAddressLabel(shipmentLocation);

  const getNextEventType = () => {
    if (!shipment) {
      return null;
    }
    if (!shipment.events) {
      return null;
    }
    const events = shipment.events.filter(
      (event) => event.shipmentLocation === shipmentLocation._id
    );
    const arrivedEvent = events.find(
      (event) =>
        event.type === ShipmentEventType.PickupCheckpointArrive ||
        event.type === ShipmentEventType.DropOffCheckpointArrive
    );

    const loadStartEvent = events.find(
      (event) =>
        event.type === ShipmentEventType.PickupCheckpointLoadStart ||
        event.type === ShipmentEventType.DropoffCheckpointUnloadStart
    );

    const leftEvent = events.find(
      (event) =>
        event.type === ShipmentEventType.PickupCheckpointLeft ||
        event.type === ShipmentEventType.DropOffCheckpointLeft
    );

    if (leftEvent) {
      return null;
    } else if (loadStartEvent) {
      return shipmentLocation.locationType === ShipmentLocationType.Pickup
        ? ShipmentEventType.PickupCheckpointLeft
        : ShipmentEventType.DropOffCheckpointLeft;
    } else if (arrivedEvent) {
      return shipmentLocation.locationType === ShipmentLocationType.Pickup
        ? ShipmentEventType.PickupCheckpointLoadStart
        : ShipmentEventType.DropoffCheckpointUnloadStart;
    } else {
      return shipmentLocation.locationType === ShipmentLocationType.Pickup
        ? ShipmentEventType.PickupCheckpointArrive
        : ShipmentEventType.DropOffCheckpointArrive;
    }
  };

  const labelByEventType: { [key in ShipmentEventType]: string } = {
    [ShipmentEventType.PickupCheckpointArrive]: t("arrived", "Arrived"),
    [ShipmentEventType.PickupCheckpointLoadStart]: t("load", "Load"),
    [ShipmentEventType.PickupCheckpointLeft]: t("depart", "Depart"),
    [ShipmentEventType.DropOffCheckpointArrive]: t("arrived", "Arrived"),
    [ShipmentEventType.DropoffCheckpointUnloadStart]: t("unload", "Unload"),
    [ShipmentEventType.DropOffCheckpointLeft]: t("depart", "Depart"),
    [ShipmentEventType.TripStarted]: t("start", "Start trip"),
    [ShipmentEventType.TripEnd]: t("end", "End trip"),
    [ShipmentEventType.TripBreakStarted]: t("break", "Start break"),
    [ShipmentEventType.TripResumed]: t("resume", "Resume trip"),
    [ShipmentEventType.ShipmentComplete]: t(
      "orderDelivered",
      "Order delivered"
    ),
    [ShipmentEventType.CheckCall]: t("checkCall", "Check call"),
    [ShipmentEventType.TripAssetAssignmentUpdated]: t(
      "assetUpdate",
      "Asset update"
    ),
  };

  const logNextEvent = () => {
    if (!shipment) {
      return;
    }
    const eventType = getNextEventType();
    if (!eventType) {
      return;
    }
    onShipmentEventLog?.(eventType);
  };

  const nextEventType = getNextEventType();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: hideTime && centered ? "center" : "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 600,
            textAlign: centered ? "center" : undefined,
          }}
        >
          {name}
          {shipment ? <br /> : null}
          {shipment ? " " : null}
          {shipment ? <ShipmentLink shipment={shipment} /> : null}
        </Typography>
        {!hideTime ? (
          <Box sx={{ ml: 2 }} textAlign="right">
            <ShipmentLocationTime shipmentLocation={shipmentLocation} />
          </Box>
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 400,
            textAlign: centered ? "center" : undefined,
          }}
        >
          {address}
        </Typography>

        {!centered && nextEventType && onShipmentEventLog ? (
          <Box>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                logNextEvent();
              }}
              variant="outlined"
            >
              {labelByEventType[nextEventType]}
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box>
        {(shippedGoods || []).map((shippedGood) => (
          <Typography
            key={`shipped-${shippedGood._id}`}
            sx={{
              fontSize: 10,
              fontWeight: 400,
              textAlign: centered ? "center" : undefined,
            }}
          >
            {shippedGood.label} ({shippedGood.quantity}{" "}
            {goodUnitLabel(shippedGood.unit || GoodUnits.Item)} x{" "}
            {formatWeight(shippedGood.weight)})
            {shippedGood.pinCode
              ? ` (${t("business:supplier.contract.pinCode", "PIN Code")}: ${
                  shippedGood.pinCode
                })`
              : ""}
          </Typography>
        ))}
        {(receivedGoods || []).map((receivedGood) => {
          const shippedGood = allShippedGoodsById[receivedGood.goodId];
          if (!shippedGood) {
            return null;
          }
          return (
            <Typography
              key={`received-${receivedGood.goodId}`}
              sx={{
                fontSize: 10,
                fontWeight: 400,
                textAlign: centered ? "center" : undefined,
              }}
            >
              {shippedGood.label} (
              {receivedGood.quantity || shippedGood.quantity}{" "}
              {goodUnitLabel(shippedGood.unit || GoodUnits.Item)} x{" "}
              {formatWeight(shippedGood.weight)})
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

const ShipmentSummary = ({
  shipment,
  onClick,
  assignable,
  hideHeader = false,
}: ShipmentSummaryProps) => {
  const { t } = useTranslation(["trips", "orders"]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
  const navigate = useNavigate();
  const allShippedGoodsById = keyBy(
    flatten(
      shipment.shipmentLocations.map((location) => location.shippedGoods || [])
    ),
    "_id"
  );

  const orderedShipmentLocations = useMemo(() => {
    return orderBy(shipment.shipmentLocations, (shipmentLocation) =>
      findIndex(
        shipment.route?.locations,
        (routeLocation) =>
          routeLocation._id === shipmentLocation._id ||
          (!!shipmentLocation.shipper &&
            shipmentLocation.shipper?._id === routeLocation.shipper?._id) ||
          (!!shipmentLocation.receiver &&
            shipmentLocation.receiver?._id === routeLocation.receiver?._id)
      )
    );
  }, [shipment.shipmentLocations, shipment.route?.locations]);

  const shipmentLocationsCount = orderedShipmentLocations.length;
  const firstShipmentLocation = orderedShipmentLocations[0];
  const lastShipmentLocation =
    orderedShipmentLocations[shipmentLocationsCount - 1];
  const separatorLength = shipmentLocationsCount > 2 && !isExpanded ? 20 : 40;

  const betweenSteps: Step[] =
    shipmentLocationsCount > 2 && !isExpanded
      ? [
          {
            id: "between-indicator",
            indicator: (
              <Typography
                sx={{
                  fontSize: 8,
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                }}
              >{`+${shipmentLocationsCount - 2}`}</Typography>
            ),
            color: theme.palette.primary.main,
            separatorLength: 20,
            onIndicatorClick: () => setIsExpanded(true),
          },
        ]
      : orderedShipmentLocations
          .slice(1, shipmentLocationsCount - 1)
          .map((_shipmentLocation) => ({
            id:
              _shipmentLocation.receiver?._id ||
              _shipmentLocation.shipper?._id ||
              "",
            content: (
              <ShipmentLocationDetails
                allShippedGoodsById={allShippedGoodsById}
                shipmentLocation={_shipmentLocation}
              />
            ),
            color:
              _shipmentLocation.locationType === ShipmentLocationType.Pickup
                ? theme.palette.primary.main
                : theme.palette.accent.main,
            separatorLength: 40,
          }));

  const steps: Step[] = [
    {
      id: "firststep-indicator",
      hideFirstLine: true,
      content: (
        <ShipmentLocationDetails
          allShippedGoodsById={allShippedGoodsById}
          shipmentLocation={firstShipmentLocation}
        />
      ),
      color: theme.palette.primary.main,
      separatorLength: separatorLength,
    },
    ...betweenSteps,
    {
      id: "laststep-indicator",
      hideLastLine: true,
      content: (
        <ShipmentLocationDetails
          allShippedGoodsById={allShippedGoodsById}
          shipmentLocation={lastShipmentLocation}
        />
      ),
      color: theme.palette.accent.main,
      separatorLength: separatorLength,
    },
  ];

  const shipmentPotentialDelayIssue = shipment.issues?.find(
    (issue) => issue.type === "POTENTIAL_DELAY"
  );

  const shipmentActualDelayIssue = shipment.issues?.find(
    (issue) => issue.type === "DELAY"
  );

  const shipmentDelayIssue =
    shipmentActualDelayIssue || shipmentPotentialDelayIssue;

  return (
    <Box
      sx={(theme) => ({
        paddingTop: 2,
        backgroundColor: theme.palette.primaryBackground.main,
        position: "relative",
        cursor: onClick ? "pointer" : undefined,
        borderWidth: shipmentDelayIssue ? 5 : undefined,
        borderColor: shipmentActualDelayIssue
          ? theme.palette.error.main
          : shipmentPotentialDelayIssue
          ? theme.palette.accent.main
          : undefined,
        borderStyle: shipmentDelayIssue ? "solid" : undefined,
      })}
      onClick={() => onClick && onClick(shipment)}
    >
      {!hideHeader && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            pl: 2,
            pr: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              fontSize: 13,
            }}
          >
            {shipment.customer?.name}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            {shipment.status !== Status.WaitingForAssignment &&
            shipment.status !== Status.Assigned ? (
              <Chip
                label={capitalize(
                  t("orders:statusLabel", {
                    status: statusKey[shipment.status],
                    defaultValue: enumLabel(shipment.status),
                  })
                )}
                color={shipmentStatusColors[shipment.status]}
              />
            ) : null}
            <ShipmentLink shipment={shipment} />
            {assignable ? (
              <>
                {shipment.tripId ? (
                  <Button
                    variant="contained"
                    color="lightPrimary"
                    size="small"
                    href={`/trips/details/${shipment.tripId}`}
                    startIcon={<Route />}
                  >
                    {t("trips:open", "Open Trip")}
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="lightPrimary"
                  size="small"
                  onClick={() => {
                    setIsAssignmentModalOpen(true);
                  }}
                  id="assign-button"
                >
                  {t("trips:assign", "Assign")}
                </Button>
                <AssignmentModalContainer
                  tripId={shipment.tripId}
                  shipments={[shipment]}
                  open={isAssignmentModalOpen}
                  onClose={(tripId) => {
                    setIsAssignmentModalOpen(false);
                    if (tripId) {
                      navigate(`/trips/details/${tripId}`);
                    }
                  }}
                />
              </>
            ) : null}
          </Stack>
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          bottom: "calc(50% - 20px)",
          right: 30,
        }}
      >
        {shipmentDelayIssue ? (
          <Chip
            icon={<AccessAlarm />}
            label={`${
              shipmentActualDelayIssue ? "Delayed" : "Potential delay"
            } ${
              shipmentDelayIssue.delayAmount
                ? formatDuration({
                    hours: Math.floor(shipmentDelayIssue.delayAmount / 3600),
                    minutes: Math.floor(
                      (shipmentDelayIssue.delayAmount % 3600) / 60
                    ),
                  })
                : ""
            }`}
            color={shipmentActualDelayIssue ? "error" : "warning"}
          />
        ) : !isExpanded && shipment.route?.routeDistance ? (
          <Typography
            textAlign={"center"}
            color="primary"
            sx={{
              fontWeight: 400,
              fontSize: 13,
            }}
          >
            {formatMileage(shipment.route?.routeDistance)}
          </Typography>
        ) : null}
      </Box>
      <StepIndicator steps={steps} />
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {isExpanded && (
          <Fab
            color="primary"
            size="small"
            sx={{ marginBottom: -2 }}
            onClick={() => setIsExpanded(false)}
            aria-label="toggle-wide"
          >
            <ExpandLessOutlinedIcon sx={{ color: "white" }} />
          </Fab>
        )}
      </Box>
    </Box>
  );
};

export default ShipmentSummary;
