import {
  Alert,
  AlertProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import {
  ExtensionAction,
  ExtensionMessageType,
  GetExtensionsMessagesQuery,
} from "../../../graphql/generated";
import { useState } from "react";

type ExtensionMessageData = GetExtensionsMessagesQuery["extensionsMessages"][0];

export type ExtensionMessagesProps = {
  messages: ExtensionMessageData[];
  onActionWidgetClose?: () => void;
};

const messageTypeToSeverity: {
  [key in ExtensionMessageType]: AlertProps["severity"];
} = {
  [ExtensionMessageType.Error]: "error",
  [ExtensionMessageType.Warning]: "warning",
  [ExtensionMessageType.Info]: "info",
};

const ExtensionsMessages = ({
  messages,
  onActionWidgetClose,
}: ExtensionMessagesProps) => {
  const [openAction, setOpenAction] = useState<ExtensionAction | null>(null);

  return (
    <Stack>
      {messages.map((message) => (
        <Alert
          severity={messageTypeToSeverity[message.type]}
          action={
            message.actions?.length ? (
              <Button
                onClick={() => {
                  setOpenAction(message.actions?.[0] || null);
                }}
              >
                {message.actions[0].text}
              </Button>
            ) : undefined
          }
        >
          Extension {message.extensionName}: {message.text}
        </Alert>
      ))}

      <Dialog
        open={!!openAction}
        onClose={() => {
          setOpenAction(null);
        }}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{openAction?.text}</DialogTitle>
        <DialogContent
          sx={{
            minHeight: 600,
          }}
        >
          {openAction ? (
            <iframe
              title="Extensions Messages"
              name="extensions-messages-iframe"
              src={openAction.url}
              style={{
                width: "100%",
                minHeight: 600,
              }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAction(null);
              onActionWidgetClose?.();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default ExtensionsMessages;
