import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  GetCustomerDetailsQuery,
  NewShipmentInput,
  ShipmentConstraintType,
  ShipmentLocationInput,
  ShipmentLocationType,
  ShipmentReferenceNumberType,
  Status,
  useGenerateShipmentRouteMutation,
  useGetBrokersQuery,
  useGetCustomersQuery,
  useGetReceiversQuery,
  useGetShippersQuery,
} from "../../../graphql/generated";

import {
  FieldErrors,
  FormError,
  NewShipmentInputData,
} from "../../../redux/slices/Types";
import { editShipment } from "../../../redux/slices/shipment/ShipmentForm.slice";
import { flatten, groupBy, isEmpty, keyBy, mapValues, omit, sum } from "lodash";
import { addMinutes, addYears, isAfter } from "date-fns";
import _ from "lodash";
import { ShipmentDetailsProps } from "../ShipmentDetails/ShipmentDetails";
import { formatDateTimeForLocation } from "../../../utils/labels/formatDateTime";
import LocationDrivenShipmentForm from "./LocationDrivenShipmentForm";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import CommodityDrivenShipmentForm from "./CommodityDrivenShipmentForm";
import { Feature, useFeature } from "../../account/Access/FeatureGuard";
import useDialog from "../../../utils/hooks/useDialog";
import {
  referenceNumberTypeToShipmentField,
  ShipmentReferenceNumberField,
} from "./ShipmentReferencesForm/ShipmentReferencesForm";

export type ShipmentFormProps = {
  onSubmit: (shipment: NewShipmentInput) => void;
  loading: boolean;
  editMode?: boolean;
  isChildLoad?: boolean;
  isSplit?: boolean;
  isFromSplit?: boolean;
  onFetchCustomerDetails?: (
    customer: string
  ) => Promise<GetCustomerDetailsQuery["customerById"]>;
};

export default function ShipmentForm({
  onSubmit,
  loading = false,
  editMode = false,
  isChildLoad,
  isSplit,
  isFromSplit,
  onFetchCustomerDetails,
}: ShipmentFormProps) {
  const [validating, setValidating] = useState(false);

  const dispatch = useDispatch();
  const shipmentInput = useSelector(
    (state: RootState) => state.shipmentReducer.shipment
  );

  const { cancelConfirm } = useConfirmBeforeLeave(shipmentInput);

  const shipmentLocations = useSelector((state: RootState) => {
    return state.shipmentReducer.shipment.shipmentLocations;
  });

  const brokersQuery = useGetBrokersQuery();
  const customersQuery = useGetCustomersQuery();
  const shippersQuery = useGetShippersQuery();
  const receiversQuery = useGetReceiversQuery();
  const generateShipmentRouteMutation = useGenerateShipmentRouteMutation();
  const { showDialog } = useDialog();

  const [shipmentLocationErrors, setShipmentLocationErrors] =
    useState<Array<FormError>>();

  const [errors, setErrors] = useState<FieldErrors>();

  const findReceiver = (id: string) => {
    return receiversQuery.data?.receivers.data.find((s) => s._id === id);
  };

  const findShipper = (id: string) => {
    return shippersQuery.data?.shippers.data.find((s) => s._id === id);
  };

  const validateShipmentLocationForms = (): Array<FormError> => {
    const errors: Array<FormError> = [];

    const allReceivedGoods = flatten(
      shipmentLocations.map((location) => location.receivedGoods)
    );
    const allShippedGoodsById = keyBy(
      flatten(
        shipmentInput.shipmentLocations.map(
          (location) => location.shippedGoods || []
        )
      ),
      "_id"
    );
    const receivedQtyByGoodId = mapValues(
      groupBy(allReceivedGoods, (receivedGood) => receivedGood.goodId),
      (group) =>
        sum(
          group.map(
            (receivedGood) =>
              receivedGood.quantity ||
              allShippedGoodsById[receivedGood.goodId].quantity
          )
        )
    );

    for (let i = 0; i < shipmentLocations.length; i++) {
      const err: FieldErrors = {};
      if (shipmentLocations[i].locationType === ShipmentLocationType.Pickup) {
        if (
          !shipmentLocations[i].location ||
          (!shipmentLocations[i].location.latitude &&
            !shipmentLocations[i].location.longitude)
        ) {
          err["name"] = `Please select a shipper`;
        }
      } else {
        if (
          !shipmentLocations[i].location ||
          (!shipmentLocations[i].location.latitude &&
            !shipmentLocations[i].location.longitude)
        ) {
          err["name"] = `Please select a receiver`;
        }
      }

      // Pickups have optional dates
      if (
        shipmentLocations[i].timeWindows.length ||
        shipmentLocations[i].locationType === ShipmentLocationType.DropOff
      ) {
        const action =
          shipmentLocations[i].locationType === ShipmentLocationType.Pickup
            ? "Pickup"
            : "Delivery";

        if (!shipmentLocations[i].timeWindows.length) {
          err["date"] = `Please select a ${action} date`;
        } else if (
          shipmentLocations[i].timeWindows[0].fromDate <
          addYears(new Date(), -10)
        ) {
          err["date"] = `Date is too far back in the past`;
        }

        if (
          shipmentLocations[i].timeWindows[0]?.fromDate &&
          shipmentLocations[i].timeWindows[0]?.toDate
        ) {
          if (
            new Date(shipmentLocations[i].timeWindows[0].fromDate) >
            new Date(shipmentLocations[i].timeWindows[0].toDate)
          ) {
            err[
              "date"
            ] = `${action} time end cannot be before ${action.toLowerCase()} time start`;
          }
        }
      }

      if (shipmentLocations[i].locationType === ShipmentLocationType.Pickup) {
        const shippedGoods = shipmentLocations[i].shippedGoods;
        if (!shippedGoods.length) {
          err["goods"] =
            "You need to define at least one commodity for this shipper";
        }
        shippedGoods.forEach((shippedGood) => {
          const receivedQty = receivedQtyByGoodId[shippedGood._id];
          if (!receivedQty) {
            err[
              "goods"
            ] = `Commodity "${shippedGood.label}" needs to be delivered to at least one receiver`;
          } else if (shippedGood.quantity > receivedQty) {
            err["goods"] = `Commodity "${shippedGood.label}" has ${
              shippedGood.quantity - receivedQty
            } items not delivered`;
          }
        });
      }

      if (shipmentLocations[i].locationType === ShipmentLocationType.DropOff) {
        const receivedGoods = shipmentLocations[i].receivedGoods;
        if (!receivedGoods.length) {
          err["goods"] =
            "You need to select at least one commodity for this receiver";
        }
        receivedGoods.forEach((receivedGood) => {
          const shippedGood = allShippedGoodsById[receivedGood.goodId];
          const receivedQty = receivedQtyByGoodId[receivedGood.goodId];
          if ((shippedGood?.quantity || 0) < receivedQty) {
            err["goods"] = `Commodity "${
              shippedGood?.label || receivedGood.goodProfileId
            }" has +${
              receivedQty - (shippedGood?.quantity || 0)
            } items delivered in excess`;
          }
        });
      }

      if (!_.isEmpty(err)) {
        errors.push({ formId: shipmentLocations[i]._id, error: err });
      }
    }

    if (errors.length) {
      return errors;
    } else {
      return [];
    }
  };

  const buildShipmentLocations = (): Array<ShipmentLocationInput> => {
    const adaptedShipmentLocations: ShipmentLocationInput[] =
      shipmentInput.shipmentLocations.map((shipmentLocation) => {
        return {
          ...omit(shipmentLocation, "isDefaultFor"),
        };
      });
    return adaptedShipmentLocations;
  };

  const toShipment = (
    data: NewShipmentInputData
  ): ShipmentDetailsProps["shipment"] | null => {
    const customerOrBroker = [
      ...(brokersQuery.data?.brokers.data || []),
      ...(customersQuery.data?.customers.data || []),
    ].find((entity) => entity._id === shipmentInput.customer);

    if (!customerOrBroker) {
      return null;
    }

    const shipmentLocations = buildShipmentLocations();
    return {
      ...data,
      documents: data.documents?.map((document) => ({
        ...document,
        _id: document._id || "",
        receiver: {
          _id: document.receiver || "",
          name: findReceiver(document.receiver || "")?.name || "",
        },
        shipper: {
          _id: document.receiver || "",
          name: findShipper(document.shipper || "")?.name || "",
        },
      })),
      notes:
        data.notes?.map((note) => ({
          ...note,
          _id: note._id || "",
          receiver: {
            _id: note.receiver || "",
            name: findReceiver(note.receiver || "")?.name || "",
          },
          shipper: {
            _id: note.receiver || "",
            name: findShipper(note.shipper || "")?.name || "",
          },
        })) || [],
      customer: customerOrBroker,
      _id: "",
      shipmentNumber: shipmentInput.shipmentNumber || "",
      loadType: data.loadType,
      trailerType: data.trailerType,
      additionalTrailerTypes: data.additionalTrailerTypes,
      constraints:
        data.constraints?.map((c) => ({
          type: c.type as ShipmentConstraintType,
          unit: c.unit,
          value: c.value,
        })) || [],
      shipmentLocations: shipmentLocations.map((s) => ({
        _id: s._id || "",
        location: s.location,
        locationType: s.locationType,
        receivedGoods: s.receivedGoods,
        receiver:
          s.locationType === ShipmentLocationType.DropOff
            ? findReceiver(s.receiver || "")
            : null,
        shippedGoods: s.shippedGoods,
        shipper:
          s.locationType === ShipmentLocationType.Pickup
            ? findShipper(s.shipper || "")
            : null,
        timeWindows: s.timeWindows,
        name: s.name,
        addressLabel: s.addressLabel,
      })),
      status: Status.Unassigned,
      createdBy: "",
      charges: data.charges.map((chargeInput) => ({
        ...chargeInput,
        _id: chargeInput._id || "",
        total: chargeInput.rate * chargeInput.unit,
        billingRule: chargeInput.billingRule
          ? {
              _id: chargeInput.billingRule,
            }
          : null,
      })),
      expenses: data.expenses?.map((expenseInput) => ({
        ...expenseInput,
        _id: expenseInput._id || "",
      })),
    };
  };

  const validate = async () => {
    //Shipment Location Forms
    let errors: FieldErrors = {};
    // if (!shipmentInput.customer) {
    //   errors = {
    //     ...errors,
    //     customer: "Please select a Customer / Broker",
    //   };
    // }

    if (!isEmpty(errors)) {
      setErrors(errors);
    } else {
      setErrors(undefined);
    }
    const shipmentErrors = validateShipmentLocationForms();
    setShipmentLocationErrors(shipmentErrors);

    if (shipmentErrors.length || !isEmpty(errors)) {
      return false;
    }
    try {
      setValidating(true);
      const shipmentInputForRouteValidation: NewShipmentInput = {
        billOfLadingNumber: shipmentInput.billOfLadingNumber,
        charges: shipmentInput.charges,
        constraints: shipmentInput.constraints,
        customer: shipmentInput.customer,
        loadType: shipmentInput.loadType,
        postOfficeNumber: shipmentInput.postOfficeNumber,
        trailerType: shipmentInput.trailerType,
        shipmentLocations: buildShipmentLocations(),
      };
      const shipmentRouteResponse =
        await generateShipmentRouteMutation.mutateAsync({
          shipment: shipmentInputForRouteValidation,
        });
      const shipmentRoute = shipmentRouteResponse.generateShipmentRoute;
      // setLocalMileage(shipmentRoute.routeDistance);

      const shipmentRouteErrors: FormError[] = (shipmentRoute.locations || [])
        .map((location, index) => {
          if (!location.timeWindows.length) {
            return null;
          }
          if (
            isAfter(
              new Date(location.arrivalTime),
              new Date(location.timeWindows[0].toDate)
            )
          ) {
            const err: FieldErrors = {};
            const originalLocation = toShipment(
              shipmentInput
            )?.shipmentLocations.find((loc) => loc._id === location._id);
            err[
              "date"
            ] = `Earliest possible arrival time is ${formatDateTimeForLocation(
              addMinutes(new Date(location.arrivalTime), 1),
              originalLocation
            )}`;
            return {
              formId: location._id,
              error: err,
            };
          }
          return null;
        })
        .filter(Boolean) as FormError[];
      if (shipmentRouteErrors.length) {
        setShipmentLocationErrors(shipmentRouteErrors);
        return false;
      }
    } catch (error) {
      console.error(error);
      showDialog({
        type: "error",
        title: "Error validating order route",
        description: (error as Error).message,
      });
      return false;
    } finally {
      setValidating(false);
    }
    setShipmentLocationErrors([]);
    return true;
    // if (activeStep === 1) {
    //   const trailerRequirementsValidationResult =
    //     trailerRequirementsSchema.validate(shipment, {
    //       abortEarly: false,
    //       allowUnknown: true,
    //     });
    //   if (trailerRequirementsValidationResult.error) {
    //     console.log(trailerRequirementsValidationResult.error);
    //     return;
    //   }
    // }

    // if (activeStep === 2) {
    //   const _shipment: NewShipmentInput = {
    //     ...omit(
    //       shipment,
    //       "events",
    //       "issues",
    //       "route",
    //       "id",
    //       "isSplit",
    //       "isFromSplit",
    //       "childShipmentIds",
    //       "date",
    //       "predefinedRecurrence",
    //       "parentShipment"
    //     ),
    //     shipmentLocations: buildShipmentLocations(),
    //     documents: shipment.documents.map((document) => omit(document, "_id")),
    //     notes: shipment.notes?.map((note) => omit(note, "_id")),
    //     charges: shipment.charges.map((charge) =>
    //       omit(charge, "_id", "relatedTransactionId", "total")
    //     ),
    //     expenses: shipment.expenses?.map((charge) =>
    //       omit(charge, "_id", "total")
    //     ),
    //   };
    //   cancelConfirm();
    //   onSubmit(_shipment, uploadedDocuments);
    // }
    // if (activeStep < 2) {
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
  };

  const handleSubmit = () => {
    const customer =
      shipmentInput.customer ||
      (shipmentInput.shipmentLocations
        .filter(
          (location) => location.locationType === ShipmentLocationType.DropOff
        )
        .map(
          (location) =>
            location.receiver &&
            findReceiver(location.receiver)?.parentBusinessEntityId
        )
        .filter(Boolean)[0] as string);
    const _shipment: NewShipmentInput = {
      ...omit(
        shipmentInput,
        "events",
        "issues",
        "route",
        "id",
        "isSplit",
        "isFromSplit",
        "childShipmentIds",
        "date",
        "predefinedRecurrence",
        "parentShipment"
      ),
      customer,
      shipmentLocations: buildShipmentLocations(),
      documents: shipmentInput.documents.map((document) =>
        omit(document, "_id")
      ),
      notes: shipmentInput.notes?.map((note) => omit(note, "_id")),
      charges: shipmentInput.charges.map((charge) =>
        omit(charge, "_id", "relatedTransactionId", "total")
      ),
      expenses: shipmentInput.expenses?.map((charge) =>
        omit(charge, "_id", "total")
      ),
    };
    cancelConfirm();
    onSubmit(_shipment);
    return Promise.resolve();
  };

  const handleChange = (
    name: string,
    value: string | string[] | null | undefined
  ) => {
    let _shipment: NewShipmentInputData;
    _shipment = {
      ...shipmentInput,
      [name]: value,
    };
    setErrors(_.omit(errors, name));
    dispatch(editShipment(_shipment));
  };

  const handleUpdate = (updates: Partial<NewShipmentInputData>) => {
    const updatedShipment: NewShipmentInputData = {
      ...shipmentInput,
      ...updates,
    };
    if (updates.date) {
      updatedShipment.shipmentLocations = updatedShipment.shipmentLocations.map(
        (location) => ({
          ...location,
        })
      );
    }
    dispatch(editShipment(updatedShipment));
  };

  useEffect(() => {
    async function updateShipmentWithCustomerDefaults() {
      // Only fetch customer details in create mode when customer is selected
      if (editMode || !shipmentInput.customer || !onFetchCustomerDetails) {
        return;
      }

      const customerDetails = await onFetchCustomerDetails(
        shipmentInput.customer
      );
      if (!customerDetails?.defaultReferenceNumbers) return;

      // Initialize reference number fields with empty arrays to reset reference numbers
      const defaultReferenceFields:
        | Record<ShipmentReferenceNumberField, string[]>
        | {} = Object.values(ShipmentReferenceNumberType).reduce(
        (acc, refType) => ({
          ...acc,
          [referenceNumberTypeToShipmentField[refType]]: [],
        }),
        {}
      );

      const customerReferenceFields =
        customerDetails.defaultReferenceNumbers.reduce(
          (acc, { referenceNumberType, referenceNumberValue }) => {
            const fieldName =
              referenceNumberTypeToShipmentField[referenceNumberType];
            if (!acc[fieldName]) {
              acc[fieldName] = [];
            }
            acc[fieldName].push(referenceNumberValue);
            return acc;
          },
          {} as Record<ShipmentReferenceNumberField, string[]>
        );

      // Merge default empty arrays with customer values
      const mergedReferenceFields = {
        ...defaultReferenceFields,
        ...customerReferenceFields,
      };

      dispatch(
        editShipment({
          ...shipmentInput,
          ...mergedReferenceFields,
        })
      );
    }

    updateShipmentWithCustomerDefaults();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentInput.customer]);

  const commodityManagementFeature = useFeature(Feature.CommodityManagement);
  const isCommodityDriven = commodityManagementFeature.isAvailable;

  return isCommodityDriven ? (
    <CommodityDrivenShipmentForm
      shipmentInput={shipmentInput}
      errors={errors}
      shipmentLocationErrors={shipmentLocationErrors}
      loading={loading}
      validating={validating}
      isChildLoad={isChildLoad}
      isSplit={isSplit}
      onChange={handleChange}
      onUpdate={handleUpdate}
      onValidate={validate}
      onSubmit={handleSubmit}
      isEdit={editMode}
    />
  ) : (
    <LocationDrivenShipmentForm
      shipmentInput={shipmentInput}
      shipmentDetails={toShipment(shipmentInput)}
      errors={errors}
      shipmentLocationErrors={shipmentLocationErrors}
      loading={loading}
      validating={validating}
      isChildLoad={isChildLoad}
      isSplit={isSplit}
      onChange={handleChange}
      onUpdate={handleUpdate}
      onValidate={validate}
      onSubmit={handleSubmit}
      isEdit={editMode}
    />
  );
}
