import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../providers/AuthProvider";

type SetupStep = "ORGANIZATION" | "SUBSCRIPTION";

type AccountSetupModalProps = {
  needsSetup: boolean;
  setupStep: SetupStep | null;
  onOrganizationSetup: (orgName: string, dotNumber: string) => void;
  onSubscribe: () => void;
  isSettingUpOrganization: boolean;
  isSubscribing: boolean;
  hasTrial: boolean;
  error: Error | null;
};

const AccountSetupModal = ({
  onOrganizationSetup,
  onSubscribe,
  isSettingUpOrganization,
  isSubscribing,
  hasTrial,
  setupStep,
  needsSetup,
  error,
}: AccountSetupModalProps) => {
  const { t } = useTranslation(["account", "common"]);
  const setupSteps: SetupStep[] = ["ORGANIZATION", "SUBSCRIPTION"];
  const [organizationName, setOrganizationName] = useState("");
  const [dotNumber, setDotNumber] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(needsSetup);
  const { signOut } = useAuth();

  useEffect(() => {
    setIsModalOpen(needsSetup);
  }, [needsSetup]);
  return (
    <Dialog open={isModalOpen} maxWidth="lg">
      <DialogContent>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                {t(
                  "setupDescription",
                  "Setup your account in order to start using the platform"
                )}
              </Alert>
              <ErrorMessage error={error} />
            </Grid>
            <Grid item sm={2}>
              <Stepper
                activeStep={setupStep ? setupSteps.indexOf(setupStep) : 0}
                orientation="vertical"
              >
                <Step>
                  <StepLabel>
                    {t("setupOrg", "Setup your organization")}
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel>
                    {hasTrial
                      ? t("activateFreeTrial", "Activate your free trial")
                      : t("enableSubscription", "Enable your subscription")}
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel>{t("allDone", "All done")}</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item sm={10}>
              <Card>
                <CardHeader
                  title={
                    setupStep === "ORGANIZATION"
                      ? t("setupOrg", "Setup your organization")
                      : setupStep === "SUBSCRIPTION"
                      ? hasTrial
                        ? t("activateFreeTrial", "Activate your free trial")
                        : t("enableSubscription", "Enable your subscription")
                      : ""
                  }
                />
                <CardContent>
                  {setupStep === "ORGANIZATION" && (
                    <Stack direction="column" spacing={3}>
                      <TextField
                        type="text"
                        value={organizationName}
                        label={t("orgName", "Organization Name")}
                        required
                        onChange={(event) => {
                          setOrganizationName(event.target.value);
                        }}
                        name="orgName"
                      />
                      <TextField
                        type="text"
                        value={dotNumber}
                        label={t("dotNumber", "DOT Number")}
                        onChange={(event) => {
                          setDotNumber(event.target.value);
                        }}
                        name="dotNumber"
                      />
                    </Stack>
                  )}
                  {setupStep === "SUBSCRIPTION" && (
                    <Box>
                      {hasTrial ? (
                        <Typography variant="body2">
                          {isSubscribing
                            ? `${t(
                                "activateFreeTrial",
                                "Activating your trial"
                              )}...`
                            : t(
                                "freeTrialDescription",
                                "Activate your free trial now, you can cancel at any point in time."
                              )}
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          {t(
                            "enableSubscriptionDescription",
                            "Enable your subscription in order to start using the platform."
                          )}
                        </Typography>
                      )}
                    </Box>
                  )}
                </CardContent>
                <CardActions sx={{ justifyContent: "space-between" }}>
                  <LoadingButton
                    onClick={() => {
                      if (setupStep === "ORGANIZATION") {
                        onOrganizationSetup(organizationName, dotNumber);
                      } else if (setupStep === "SUBSCRIPTION") {
                        onSubscribe();
                      }
                    }}
                    variant="contained"
                    loading={isSettingUpOrganization || isSubscribing}
                    id={`${
                      setupStep === "SUBSCRIPTION" ? "active" : "next"
                    }-button`}
                  >
                    {setupStep === "SUBSCRIPTION"
                      ? t("activate", "Activate")
                      : t("next", "Next")}
                  </LoadingButton>
                  <Button id="logout-button" onClick={() => signOut()}>
                    {t("common:logout", "Logout")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default AccountSetupModal;
