import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type ActionMenu = {
  id: string;
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

type BulkActionsButtonProps = {
  id?: string;
  menus: Array<ActionMenu>;
  buttonProps?: ButtonProps;
  menuProps?: MenuProps;
  menuItemProps?: MenuItemProps;
} & Omit<ButtonProps, "id">;

export default function BulkActionsButton({
  id,
  menus,
  menuProps,
  menuItemProps,
  ...otherButtonProps
}: BulkActionsButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation("common");

  return (
    <div>
      <Button
        id={id || "dropdown-button-group"}
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        {...otherButtonProps}
      >
        {t("list.bulkActionsButton", "Bulk Actions")}
      </Button>
      <Menu
        {...menuProps}
        id="dropdown-button-group-menu"
        MenuListProps={{
          "aria-labelledby": "dropdown-button-group-menu",
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
      >
        {menus.map((menu) => (
          <MenuItem
            id={`${menu.id}-button`}
            onClick={() => {
              menu.onClick();
              handleClose();
            }}
            disableRipple
            sx={{
              minWidth: 150,
            }}
            {...menuItemProps}
          >
            {menu.icon}
            <Typography ml={1}>{menu.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
