import React, { useState } from "react";
import { Stack } from "@mui/material";
import {
  GoodDistributionInput,
  GoodInput,
  ShipmentLocationType,
  StorageFacility,
} from "../../../../graphql/generated";
import ReceiverGoodsListModal from "../ReceiverGoodsList";
import {
  FormError,
  ShipmentLocationInputData,
} from "../../../../redux/slices/Types";
import ShipmentLocationForm from "../ShipmentLocationForm";
import ReceiverGoodsList from "../ReceiverGoodsList/ReceiverGoodsList";

export type ReceiverFormProps = {
  shipmentLocation: ShipmentLocationInputData;
  shippedGoods: Array<GoodInput>;
  receivedGoods: Array<GoodDistributionInput>;
  storageFacilities: StorageFacility[];
  inline?: boolean;
  allowAddressLocation?: boolean;
  errors?: FormError;
  onChange: (receiver: ShipmentLocationInputData) => void;
  storageFacilitiesLoading?: boolean;
  allowNewLocation?: boolean;
};

export default function ReceiverForm({
  shipmentLocation,
  shippedGoods,
  receivedGoods,
  inline = false,
  allowAddressLocation = true,
  storageFacilities,
  errors,
  onChange,
  storageFacilitiesLoading,
  allowNewLocation = true,
}: ReceiverFormProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpen = () => setOpenModal(true);

  const closeGoodsModal = () => {
    setOpenModal(false);
  };

  return (
    <Stack spacing={5}>
      <ShipmentLocationForm
        locationType={ShipmentLocationType.DropOff}
        shippedGoods={shippedGoods || []}
        data={shipmentLocation}
        onChange={onChange}
        onGoodsOpen={handleOpen}
        errors={errors}
        locationOnly={inline}
        allowAddressLocation={allowAddressLocation}
        renderAfterLocationSelect={() => {
          if (!inline) {
            return null;
          }
          if (!shipmentLocation.receiver) {
            return null;
          }
          return (
            <ReceiverGoodsList
              onChange={(receivedGoods) => {
                onChange({ ...shipmentLocation, receivedGoods });
              }}
              shippedGoods={shippedGoods}
              receivedGoods={receivedGoods}
              shipmentLocation={{
                ...shipmentLocation,
                receivedGoods: shipmentLocation.receivedGoods,
                persistedReceivedGoods: shipmentLocation.receivedGoods,
              }}
              storageFacilities={storageFacilities}
              storageFacilitiesLoading={storageFacilitiesLoading}
            />
          );
        }}
        allowNewLocation={allowNewLocation}
      />
      {inline ? null : (
        <ReceiverGoodsListModal
          shippedGoods={shippedGoods || []}
          receivedGoods={receivedGoods || []}
          shipmentLocation={shipmentLocation}
          storageFacilities={storageFacilities}
          storageFacilitiesLoading={storageFacilitiesLoading}
          width="md"
          openModal={openModal}
          onClose={closeGoodsModal}
          onChange={(receivedGoods) => {
            onChange({ ...shipmentLocation, receivedGoods });
          }}
        />
      )}
    </Stack>
  );
}
