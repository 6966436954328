import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import TripSeparationTool from "../TripSeparationTool";
import { AugmentedShipment } from "../../../components/trip-planning/PlanningTool/PlanningTool";
import { useTranslation } from "react-i18next";

type TripSeparationModalProps = {
  open: boolean;
  onClose: () => void;
  shipments: AugmentedShipment[];
  onRefresh: (shipmentIds: string[]) => Promise<void>;
};

export default function TripSeparationModal({
  open,
  onClose,
  shipments,
  onRefresh,
}: TripSeparationModalProps) {
  const { t } = useTranslation("trips");
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle>{t("separateTrips", "Separate trips")}</DialogTitle>
      <DialogContent
        sx={{
          width: 850,
          height: 850,
        }}
      >
        <TripSeparationTool shipments={shipments} onRefresh={onRefresh} />
      </DialogContent>
    </Dialog>
  );
}
