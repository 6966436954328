import {
  TripSortCriteria,
  GetGoodProfileListQuery,
  AssetStatus,
  NewGoodProfileInput,
  CustomFieldContext,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import ExcelImporter from "../../common/ExcelImporter";
import goodProfileSchema from "../GoodProfileForm/goodProfileSchema";
import goodProfilesMapping from "../../../utils/mapping/goodProfiles";
import { Box, Button, Chip, Stack } from "@mui/material";
import { formatWeightForUnit } from "../../../utils/conversion/weight";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type GoodProfileListItem = GetGoodProfileListQuery["goodProfiles"]["data"][0];

export default function GoodProfilesList({
  query,
  onBulkCreate,
}: {
  query: QueryFn<GoodProfileListItem, "goodProfiles", TripSortCriteria>;
  onBulkCreate: (data: NewGoodProfileInput[]) => Promise<any>;
}) {
  const { t } = useTranslation(["assets", "common"]);

  const fields: TableField<GoodProfileListItem, AssetStatus>[] = useMemo(
    () => [
      {
        value: "code",
        label: t("commodity.code", "Commodity code"),
        type: "string",
      },
      {
        value: "label",
        label: t("commodity.label", "Label"),
        type: "string",
      },
      {
        value: (goodProfile) => goodProfile.unit,
        label: t("commodity.unit", "Unit"),
        type: "string",
        sortBy: "unit",
      },
      {
        value: (goodProfile) =>
          formatWeightForUnit(goodProfile.weight, goodProfile.unit),
        label: t("commodity.weight", "Weight"),
        type: "number",
        sortBy: "weight",
      },
      {
        value: "liquidGravity",
        label: t("commodity.gravity", "Gravity"),
        type: "number",
        sortBy: "liquidGravity",
      },
      {
        value: (goodProfile) => (
          <Stack direction="row" spacing={1}>
            {goodProfile.tags?.map((tag) => (
              <Chip label={tag} />
            ))}
          </Stack>
        ),
        sortBy: "tags",
        label: t("common:tags"),
        type: "string",
      },
    ],
    [t]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "calc(100% - 74px)",
        }}
      >
        <SmartLynksTable
          query={query}
          dataKey="goodProfiles"
          fields={fields}
          detailsUrlPrefix="/good-profiles/details"
          customFieldContext={[CustomFieldContext.Commodity]}
          selectable
          showGroups
          renderHeaderActions={() => (
            <Stack direction="row" spacing={2}>
              <ExcelImporter
                mapping={goodProfilesMapping}
                schema={goodProfileSchema}
                onBulkCreate={onBulkCreate}
              />
              <Button
                href="/good-profiles/new"
                variant="contained"
                color="secondary"
                size="large"
                id="new-goodProfile-button"
              >
                {t("commodity.new", "New Commodity")}
              </Button>
            </Stack>
          )}
        />
      </Box>
    </div>
  );
}
