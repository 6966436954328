import ChargebeeWidgetContainer from "../ChargebeeWidget";
import { ChargebeeWidgetForward } from "../ChargebeeWidget/ChargebeeWidget";

export default function OrganizationSubscriptionInfos() {
  return (
    <ChargebeeWidgetContainer
      forward={ChargebeeWidgetForward.SubscriptionDetails}
    />
  );
}
