import { useAuth } from "../../../providers/AuthProvider";

export type RoleGuardProps = {
  roles?: (string | null)[] | null;
  children: React.ReactNode;
};

const RoleGuard = (props: RoleGuardProps): JSX.Element | null => {
  const { roles } = useAuth();

  if (
    !props.roles ||
    roles.some((role) => (props.roles || []).includes(role))
  ) {
    return <>{props.children}</>;
  }

  return null;
};

export const useRoles = () => {
  const { roles } = useAuth();
  return roles;
};

export default RoleGuard;
