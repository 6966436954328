import { Alert } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetOrgUserDetailsQuery,
  useDeleteOrgUserMutation,
  useGetOrgUserDetailsQuery,
  useUpdateAccountStatusMutation,
  useUpdatePasswordMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import LoadingOverlay from "../../common/LoadingOverlay";
import UserDetails from "./UserDetails";
import { useTranslation } from "react-i18next";

type UserDetailsContainerProps = {
  userId: string;
  onLoad?: (user: GetOrgUserDetailsQuery["orgUserById"]) => void;
};

function UserDetailsContainer({ userId, onLoad }: UserDetailsContainerProps) {
  const { t } = useTranslation(["users", "common"]);
  const getUserDetailsQuery = useGetOrgUserDetailsQuery({
    id: userId,
  });
  const deleteUserMutation = useDeleteOrgUserMutation();
  const updatePasswordMutation = useUpdatePasswordMutation();
  const updateAccountStatusMutation = useUpdateAccountStatusMutation();

  const navigate = useNavigate();
  const { showDialog, hideDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (getUserDetailsQuery.data?.orgUserById) {
      onLoad?.(getUserDetailsQuery.data?.orgUserById);
    }
  }, [onLoad, getUserDetailsQuery.data]);

  if (getUserDetailsQuery.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (getUserDetailsQuery.error) {
    return (
      <Alert severity="error">
        {t("error.fetchError", "An error occurred while fetching the user")}.{" "}
        {(getUserDetailsQuery.error as Error).message ||
          t("common:error.unknownError", "Unknown error")}
      </Alert>
    );
  }
  const user = getUserDetailsQuery.data?.orgUserById;
  if (!user) {
    return (
      <Alert severity="error">{t("common:error.notFound", "Not found")}</Alert>
    );
  }
  return (
    <UserDetails
      user={user}
      onEdit={() => {
        navigate(`/users/${userId}/edit`);
      }}
      onDelete={async () => {
        showDialog({
          title: t("common:error.dangerZone", "Danger zone"),
          description: t(
            "userDeleteConfirmation",
            "Do you really want to delete this user? This action cannot be undone."
          ),
          type: "error",
          actions: [
            {
              type: "error",
              title: t("common:error.yesDelete", "Yes, Delete"),
              onClick: async () => {
                try {
                  await deleteUserMutation.mutateAsync({
                    id: userId,
                  });
                  hideDialog();
                  navigate("/users");
                } catch (error) {
                  console.error(error);
                  showDialog({
                    title: t("common:error.title", "Error"),
                    description:
                      t(
                        "error.deleteUser",
                        "An error occurred while deleting the user."
                      ) + (deleteUserMutation.error as Error).message ||
                      t("common:error.unknownError", "Unknown error"),
                  });
                }
              },
            },
            {
              type: "primary",
              title: t("common:error.noCancel", "No, Cancel"),
            },
          ],
        });
      }}
      updatingPassword={updatePasswordMutation.isLoading}
      onPasswordUpdate={async (password) => {
        try {
          await updatePasswordMutation.mutateAsync({
            id: userId,
            temporaryPassword: password,
          });
          enqueueSnackbar(
            t("passwordUpdated", "Password successfully updated")
          );
        } catch (error) {
          console.error(error);
          showDialog({
            title: t("common:error.title", "Error"),
            description:
              t(
                "error.updatePassword",
                "An error occurred while updating the password."
              ) + (error as Error).message ||
              t("common:error.unknownError", "Unknown error"),
          });
        }
      }}
      onUserAccountStatusUpdate={async (enabled) => {
        try {
          await updateAccountStatusMutation.mutateAsync({
            enabled,
            id: userId || "",
          });
          getUserDetailsQuery.refetch();
          enqueueSnackbar(
            t(
              enabled ? "enabled" : "disabled",
              enabled ? "User enabled" : "User disabled"
            )
          );
        } catch (error) {
          enqueueSnackbar(
            t(
              "updateUserAccountStatus",
              "An error occurred while updating user status"
            ),
            {
              variant: "error",
            }
          );
        }
      }}
    />
  );
}

export default UserDetailsContainer;
