import {
  useDeleteDriverShiftAssignmentMutation,
  useGetDriverShiftAssignmentsQuery,
} from "../../../graphql/generated";
import DriverShiftAssignmentsList, {
  DriverShiftAssignmentListProps,
} from "./DriverShiftAssignmentsList";

type DriverShiftAssignmentsListContainerProps = Omit<
  DriverShiftAssignmentListProps,
  "query" | "deleteMutation" | "onRefresh" | "onComplete"
>;

export default function DriverShiftAssignmentsListContainer(
  props: DriverShiftAssignmentsListContainerProps
) {
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new asset linking has been generated
  const useGetDriverShiftAssignmentListQueryCustom: typeof useGetDriverShiftAssignmentsQuery =
    (variables, options) => {
      const query = useGetDriverShiftAssignmentsQuery(
        {
          ...variables,
          filter: { driverId: props.driverId },
        },
        {
          ...options,
        }
      );
      refetch = query.refetch;
      return query;
    };
  useGetDriverShiftAssignmentListQueryCustom.fetcher =
    useGetDriverShiftAssignmentsQuery.fetcher;
  useGetDriverShiftAssignmentListQueryCustom.getKey =
    useGetDriverShiftAssignmentsQuery.getKey;

  return (
    <DriverShiftAssignmentsList
      query={useGetDriverShiftAssignmentListQueryCustom}
      deleteMutation={useDeleteDriverShiftAssignmentMutation}
      onRefresh={() => {
        refetch();
      }}
      {...props}
    />
  );
}
