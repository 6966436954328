import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AnalyticsGraphContainer from "../AnalyticsGraph";
import LabeledAddButton from "../../common/LabeledAddButton";
import { DateRangeInput } from "../../../graphql/generated";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { ChartType, GraphOptions } from "../AnalyticsGraph/AnalyticsGraph";
import { Delete } from "@mui/icons-material";

export type AnalyticsDashboardProps = {};

const DASHBOARD_GRAPH_DATA_KEY = `truetms::analytics::dashboard::graph-data`;

const AnalyticsDashboard = (props: AnalyticsDashboardProps) => {
  const [graphDatas, setGraphDatas] = useState<GraphOptions[]>(
    JSON.parse(localStorage.getItem(DASHBOARD_GRAPH_DATA_KEY) || "[]")
  );
  const [dateRange, setDateRange] = useState<DateRangeInput | null>(null);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Analytics Dashboard</Typography>
        <Button
          variant="contained"
          onClick={() => {
            localStorage.setItem(
              DASHBOARD_GRAPH_DATA_KEY,
              JSON.stringify(graphDatas)
            );
          }}
        >
          Save
        </Button>
      </Stack>

      <DateRangePicker
        value={
          dateRange ? [dateRange.startDate, dateRange.endDate] : [null, null]
        }
        onChange={(newValue) => {
          if (newValue) {
            setDateRange({
              startDate: newValue[0],
              endDate: newValue[1],
            });
          }
        }}
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} />
          </>
        )}
      />
      <Grid container spacing={2}>
        {graphDatas.map((graphData, index) => (
          <Grid item xs={12} sm={6}>
            <Paper
              sx={{
                p: 2,
                position: "relative",
              }}
            >
              <AnalyticsGraphContainer
                key={index}
                initialOptions={graphData}
                dateRange={dateRange}
                onGraphOptionsChange={(options) => {
                  setGraphDatas(
                    graphDatas.map((data, i) => (i === index ? options : data))
                  );
                }}
              />
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
                onClick={() => {
                  setGraphDatas(graphDatas.filter((_, i) => i !== index));
                }}
              >
                <Delete />
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <LabeledAddButton
        label="Add Graph"
        onClick={() => {
          setGraphDatas([
            ...graphDatas,
            {
              metricTypes: [],
              chartType: ChartType.Line,
              xAxisDimension: "",
              aggregateBy: "",
            },
          ]);
        }}
      />
    </Stack>
  );
};

export default AnalyticsDashboard;
