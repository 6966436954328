import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGenerateTripMutation,
  useGenerateTripsForPendingShipmentsMutation,
} from "../../../graphql/generated";
import TripsGenerator from "./TripsGenerator";

function TripsGeneratorContainer() {
  const generateTripsMutation = useGenerateTripsForPendingShipmentsMutation();
  const generateTripMutation = useGenerateTripMutation();
  const navigate = useNavigate();

  useEffect(() => {
    generateTripsMutation.mutate({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitTrips = async () => {
    try {
      await Promise.all(
        (
          generateTripsMutation.data?.generateTripsForPendingShipments.trips ||
          []
        ).map(async (trip) => {
          await generateTripMutation.mutateAsync({
            generateTripData: {
              shipments: trip.shipments.map((shipment) => shipment._id),
              trailer: trip.trailer?._id,
              tractor: trip.tractor?._id,
              driver: trip.driver?._id,
            },
          });
        })
      );
      navigate("/trips");
    } catch (error) {
      console.error(error);
      // TODO: remove trips created up to now
    }
  };

  return (
    <TripsGenerator
      generatedTrips={
        generateTripsMutation.data?.generateTripsForPendingShipments.trips || []
      }
      unassignedShipmentLocations={
        generateTripsMutation.data?.generateTripsForPendingShipments
          .unassignedShipmentLocations || []
      }
      generating={generateTripsMutation.isLoading}
      creating={generateTripMutation.isLoading}
      onValidate={submitTrips}
      error={generateTripsMutation.error || generateTripMutation.error}
    />
  );
}

export default TripsGeneratorContainer;
