import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { GetExtensionsWidgetsQuery } from "../../../graphql/generated";
import { useState } from "react";

type ExtensionWidgetData = GetExtensionsWidgetsQuery["extensionsWidgets"][0];

export type ExtensionWidgetsProps = {
  widgets: ExtensionWidgetData[];
  onWidgetClose?: () => void;
};

const ExtensionsWidgets = ({
  widgets,
  onWidgetClose,
}: ExtensionWidgetsProps) => {
  const [openWidget, setOpenWidget] =
    useState<ExtensionWidgetData | null>(null);

  return (
    <Stack direction="row" spacing={2}>
      {widgets.map((widget) => (
        <Button
          variant="contained"
          key={widget.label}
          onClick={() => {
            setOpenWidget(widget);
          }}
        >
          {widget.label}
        </Button>
      ))}

      <Dialog
        open={!!openWidget}
        onClose={() => {
          setOpenWidget(null);
        }}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{openWidget?.label}</DialogTitle>
        <DialogContent
          sx={{
            minHeight: 600,
          }}
        >
          {openWidget ? (
            <iframe
              title="Extensions Messages"
              name="extensions-messages-iframe"
              src={`${openWidget.rootUrl}${openWidget.endpoint}`}
              style={{
                width: "100%",
                minHeight: 600,
              }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenWidget(null);
              onWidgetClose?.();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default ExtensionsWidgets;
