import { queryClient } from "../../../App";
import { useGetCustomerDetailsQuery } from "../../../graphql/generated";
import ShipmentForm, { ShipmentFormProps } from "./ShipmentForm";

export default function ShipmentFormContainer(props: ShipmentFormProps) {
  return (
    <ShipmentForm
      {...props}
      onFetchCustomerDetails={async (customer) => {
        const customerDetails = await queryClient.fetchQuery(
          useGetCustomerDetailsQuery.getKey({
            id: customer,
          }),
          useGetCustomerDetailsQuery.fetcher({
            id: customer,
          })
        );

        return customerDetails.customerById;
      }}
    />
  );
}
