import { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import { OrganizationUpdateInput } from "../../../graphql/generated";
import { isEqual, omit } from "lodash";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import { useTranslation } from "react-i18next";
import organizationInfosSchema from "./organizationInfosSchema";
import PhoneInput from "react-phone-number-input";
import { MuiPhoneInput } from "../../asset-management/BusinessEntityForm/ContactForm";

type OrgnizationInfosFormProps = {
  initialOrgInfos?: OrganizationUpdateInput;
  saving: boolean;
  onSave: (tractor: OrganizationUpdateInput) => void;
};
type PartialOrganization = Partial<OrganizationUpdateInput>;

const OrgnizationInfosForm = ({
  initialOrgInfos,
  saving,
  onSave,
}: OrgnizationInfosFormProps) => {
  const { t } = useTranslation(["organization"]);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<OrganizationUpdateInput> | null>(null);
  const [localOrgInfos, setLocalOrgInfos] =
    useState<OrganizationUpdateInput | null>(initialOrgInfos || null);

  useEffect(() => {
    setLocalOrgInfos(initialOrgInfos || null);
  }, [initialOrgInfos]);

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback(
    (changes: PartialOrganization) => {
      setLocalOrgInfos({
        ...localOrgInfos,
        ...changes,
      });
    },
    [localOrgInfos]
  );

  const validate = () => {
    const validationResult = organizationInfosSchema.validate(
      omit(localOrgInfos, "_id"),
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localOrgInfos]);

  const { cancelConfirm } = useConfirmBeforeLeave(localOrgInfos);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                label={t("companyName", "Company Name")}
                name="companyName"
                required
                fullWidth
                value={localOrgInfos?.name || ""}
                error={!!getFieldError("name")}
                helperText={getFieldError("name")}
                onChange={(event) => {
                  onChange({ name: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label="DOT#"
                name="dotNumber"
                fullWidth
                value={localOrgInfos?.dotNumber || ""}
                error={!!getFieldError("dotNumber")}
                helperText={getFieldError("dotNumber")}
                onChange={(event) => {
                  onChange({ dotNumber: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                name="address"
                fullWidth
                label={t("address", "Address")}
                value={localOrgInfos?.address || ""}
                error={!!getFieldError("address")}
                helperText={getFieldError("address")}
                onChange={(e) =>
                  onChange({
                    address: e.target.value || "",
                  })
                }
              />
            </Grid>

            <Grid item sm={12}>
              <PhoneInput
                label={t("phoneNumber", "Phone Number")}
                fullWidth
                name="phoneNumber"
                defaultCountry="US"
                value={localOrgInfos?.phoneNumber || ""}
                style={{ flex: 1, minWidth: "25%" }}
                onChange={(phoneNumber) => {
                  onChange({
                    phoneNumber: phoneNumber || "",
                  });
                }}
                inputComponent={MuiPhoneInput}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("email", "Email")}
                name="email"
                fullWidth
                value={localOrgInfos?.email || ""}
                error={!!getFieldError("email")}
                helperText={getFieldError("email")}
                onChange={(event) => {
                  onChange({ email: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("contact", "Contact")}
                name="contact"
                fullWidth
                value={localOrgInfos?.contactFullname || ""}
                error={!!getFieldError("contact")}
                helperText={getFieldError("contact")}
                onChange={(event) => {
                  onChange({ contactFullname: event.target.value });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          disabled={
            saving ||
            !!validationResult?.error ||
            isEqual(initialOrgInfos, localOrgInfos)
          }
          size="large"
          onClick={() => {
            if (!localOrgInfos) {
              return;
            }
            if (validate()) {
              cancelConfirm();
              onSave(localOrgInfos);
            }
          }}
          sx={{ alignSelf: "flex-end" }}
          id="saveOrgInfosButton"
        >
          {t("common:list.edit", "Edit")}
        </Button>
        <Box sx={{ mt: 1 }}>
          <ErrorMessage message={validationResult?.error?.message || null} />
        </Box>
      </Box>
    </Box>
  );
};

export default OrgnizationInfosForm;
