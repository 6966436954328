import { Box, Container, Divider, IconButton, Stack } from "@mui/material";
import {
  FieldErrors,
  FormError,
  NewShipmentInputData,
} from "../../../redux/slices/Types";
import LynksTabs from "../../common/LynksTabs";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  GetCustomerDetailsQuery,
  ShipmentCommodityType,
  useGetGoodProfileListQuery,
} from "../../../graphql/generated";
import { RemoveCircle } from "@mui/icons-material";
import ReceiverFormContainer from "./ReceiverForm";
import LabeledAddButton from "../../common/LabeledAddButton";
import { useDispatch } from "react-redux";
import {
  addReceiver,
  addShipper,
  removeShipperOrReceiver,
  setCommodityType,
  setGoodProfiles,
  setIsCommodityDriven,
} from "../../../redux/slices/shipment/ShipmentForm.slice";
import {
  hideDialog,
  showDialog,
} from "../../../redux/slices/alert/Alert.slice";
import ShipperFormContainer from "./ShipperForm";
import ShipmentReferencesForm from "./ShipmentReferencesForm/ShipmentReferencesForm";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import TrailerRequirementsFormContainer from "./TrailerRequirementsForm/TrailerRequirementsFormContainer";
import {
  isDropoffLocation,
  isPickupLocation,
} from "../../../utils/location/isPickupLocation";

type CommodityDrivenShipmentFormProps = {
  shipmentInput: NewShipmentInputData;
  //   shipmentDetails: ShipmentDetailsProps["shipment"] | null;
  errors?: FieldErrors;
  shipmentLocationErrors?: FormError[];
  loading: boolean;
  validating: boolean;
  onUpdate: (updates: Partial<NewShipmentInputData>) => void;
  onChange: (name: string, value: string | null | undefined) => void;
  onValidate: () => Promise<boolean>;
  onSubmit: () => Promise<void>;
  isChildLoad?: boolean;
  isSplit?: boolean;
  isEdit?: boolean;
  customerDetails?: GetCustomerDetailsQuery["customerById"];
};

enum ShipmentFormTabs {
  Commodities = "commodities",
  Shippers = "shippers",
  Requirements = "requirements",
  References = "references",
}

const CommodityDrivenShipmentForm = (
  props: CommodityDrivenShipmentFormProps
) => {
  const { t } = useTranslation(["orders", "common"]);

  const tabs = [
    {
      label: t("orders:commodities", "Commodities"),
      value: ShipmentFormTabs.Commodities,
    },
    {
      label: t("orders:shippers", "Shippers"),
      value: ShipmentFormTabs.Shippers,
    },
    {
      label: t("orders:requirements", "Requirements"),
      value: ShipmentFormTabs.Requirements,
    },
    {
      label: t("orders:references", "References"),
      value: ShipmentFormTabs.References,
    },
  ];

  const [activeTab, setActiveTab] = useState(ShipmentFormTabs.Commodities);
  const receiverLocations =
    props.shipmentInput.shipmentLocations.filter(isDropoffLocation);
  const shipperLocations =
    props.shipmentInput.shipmentLocations.filter(isPickupLocation);

  const goodProfilesQuery = useGetGoodProfileListQuery();

  const goodProfiles = useMemo(
    () => goodProfilesQuery.data?.goodProfiles.data || [],
    [goodProfilesQuery]
  );

  const dispatch = useDispatch();

  const handleAddReceiverClick = () => {
    dispatch(addReceiver());
  };

  const handleAddShipperClick = () => {
    dispatch(addShipper());
  };

  useEffect(() => {
    dispatch(setIsCommodityDriven(true));
    // For now commodity driven form only handles liquid
    dispatch(setCommodityType(ShipmentCommodityType.Liquid));
    loadGoodProfiles();

    async function loadGoodProfiles() {
      const goodProfilesResponse = await useGetGoodProfileListQuery.fetcher()();

      const goodProfiles = goodProfilesResponse.goodProfiles.data || [];

      dispatch(setGoodProfiles(goodProfiles));
    }
  }, [dispatch]);

  const handleRemoveShipperOrReceiver = (type: string, id: string) => {
    dispatch(
      showDialog({
        title: t("common:error.dangerZone", "Danger zone"),
        description: t(
          `error.deleteShipperReceiverConfirmation.${type}`,
          `Do you really want to delete this ${type} ? This action cannot be undone.`
        ),
        type: "error",
        actions: [
          {
            type: "error",
            title: t("common:error.yesDelete", "Yes, Delete"),
            onClick: async () => {
              dispatch(removeShipperOrReceiver(id));
              dispatch(hideDialog());
            },
          },
          {
            type: "primary",
            title: t("common:error.noCancel", "No, Cancel"),
          },
        ],
      })
    );
  };

  const handleSelectTab = useCallback((tab: string) => {
    setActiveTab(tab as ShipmentFormTabs);
  }, []);

  const goodProfilesShippedGoods = useMemo(
    () =>
      goodProfiles.map((goodProfile) => ({
        _id: goodProfile._id,
        label: goodProfile.label,
        quantity: Infinity,
        goodProfileId: goodProfile._id,
        unit: goodProfile.unit,
        weight: goodProfile.weight,
      })),
    [goodProfiles]
  );

  return (
    <Container
      sx={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <LynksTabs tabs={tabs} value={activeTab} onSelectTab={handleSelectTab} />

      <Stack justifyContent="space-between" flex={1}>
        <Box
          display={
            activeTab === ShipmentFormTabs.Commodities ? undefined : "none"
          }
        >
          {receiverLocations.map((receiverLocation, index) => (
            <Box
              key={`${receiverLocation._id}-${index}`}
              sx={{ position: "relative" }}
            >
              {receiverLocations.length > 1 && (
                <IconButton
                  sx={{ position: "absolute", right: -30, top: -30 }}
                  color="default"
                  onClick={() =>
                    handleRemoveShipperOrReceiver(
                      "receiver",
                      receiverLocation._id
                    )
                  }
                >
                  <RemoveCircle />
                </IconButton>
              )}
              <ReceiverFormContainer
                shippedGoods={goodProfilesShippedGoods}
                receivedGoods={receiverLocations
                  .map((r) => r.receivedGoods)
                  .flat()}
                key={index}
                receiver={receiverLocation}
                inline
                errors={props.shipmentLocationErrors?.find(
                  (err) => err.formId === receiverLocation._id
                )}
                allowAddressLocation={false}
              />
              <Divider
                sx={{
                  my: 3,
                }}
              />
            </Box>
          ))}
          <LabeledAddButton
            sx={{ height: 50, width: 20 }}
            onClick={handleAddReceiverClick}
            label={t("orders:addReceiver", "Add Receiver")}
          />
        </Box>

        <Box
          display={activeTab === ShipmentFormTabs.Shippers ? undefined : "none"}
        >
          {shipperLocations.map((shipperLocation, index) => (
            <Box
              key={`${shipperLocation._id}-${index}`}
              sx={{ position: "relative" }}
            >
              {shipperLocations.length > 1 && (
                <IconButton
                  sx={{ position: "absolute", right: -30, top: -30 }}
                  color="default"
                  onClick={() =>
                    handleRemoveShipperOrReceiver(
                      "shipper",
                      shipperLocation._id
                    )
                  }
                >
                  <RemoveCircle />
                </IconButton>
              )}
              <ShipperFormContainer
                key={index}
                errors={
                  props.shipmentLocationErrors?.find(
                    (err) => err.formId === shipperLocation._id
                  ) || undefined
                }
                shipper={shipperLocation}
                allReceivedGoods={receiverLocations
                  .map((r) => r.receivedGoods)
                  .flat()}
                noTimeWindows={true}
                allowAddressLocation={false}
              />
              <Divider
                sx={{
                  my: 3,
                }}
              />
            </Box>
          ))}
          <LabeledAddButton
            sx={{ height: 50, width: 20 }}
            onClick={handleAddShipperClick}
            label={t("orders:addShipper", "Add Shipper")}
            id="add-shipper-button"
          />
        </Box>

        {activeTab === ShipmentFormTabs.Requirements ? (
          <Box>
            <TrailerRequirementsFormContainer
              shipment={props.shipmentInput}
              onUpdate={props.onUpdate}
            />
          </Box>
        ) : null}
        {activeTab === ShipmentFormTabs.References ? (
          <Box>
            <ShipmentReferencesForm
              shipment={props.shipmentInput}
              onUpdate={props.onUpdate}
              errors={props.errors}
            />
          </Box>
        ) : null}

        <Stack justifyContent="flex-end" direction="row">
          <LoadingButton
            loading={props.validating || props.loading}
            onClick={async () => {
              if (await props.onValidate()) {
                await props.onSubmit();
              }
            }}
            variant="contained"
            size="large"
          >
            {t("common:save", "Save")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Container>
  );
};

export default CommodityDrivenShipmentForm;
