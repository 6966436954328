import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { groupBy, map, orderBy } from "lodash";
import { useDrag } from "react-dnd";
import ShipmentSummary, {
  ShipmentDataSummary,
  ShipmentSummaryProps,
} from "../ShipmentSummary/ShipmentSummary";
import deliveryIcon from "../../../assets/icons/delivery.svg";
import trailerTypeLabel from "../../../utils/labels/trailerTypeLabel";
import { TrailerType } from "../../../graphql/generated";

interface ShipmentSummariesListProps {
  shipments: ShipmentDataSummary[];
  direction?: "column" | "row";
  groupByTrailer?: boolean;
  dragable?: boolean;
  selected?: ShipmentDataSummary | null;
  onSelect?: (shipment: ShipmentDataSummary) => void;
}

export function DraggableShipmentSummary(props: ShipmentSummaryProps) {
  const [{ isDragging }, shipmentDragRef] = useDrag(() => ({
    type: "Shipment",
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: props.shipment,
  }));

  const { dragable } = props;
  return dragable ? (
    <Box
      ref={shipmentDragRef}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? "grabbing" : "grab",
      }}
      data-testid={`DraggableShipmentSummary-${props.shipment._id}`}
    >
      <ShipmentSummary {...props} />
    </Box>
  ) : (
    <ShipmentSummary onClick={props.onClick} {...props} />
  );
}

function ShipmentSummariesList({
  shipments,
  direction = "row",
  groupByTrailer = true,
  dragable = true,
  onSelect,
  selected,
}: ShipmentSummariesListProps) {
  if (groupByTrailer) {
    const shipmentsByTrailerType = groupBy(
      orderBy(shipments, (s) => s.trailerType),
      (shipment) => shipment.trailerType
    );
    return (
      <Box>
        {map(
          shipmentsByTrailerType,
          (shipmentGroup, trailerType: TrailerType) => {
            return (
              <Box key={trailerType}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    pt: 2,
                    pb: 2,
                  }}
                >
                  <img src={deliveryIcon} alt="Delivery icon" />
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      ml: 2,
                    }}
                  >
                    {trailerTypeLabel(trailerType)}
                  </Typography>
                  <Box
                    bgcolor="primary.main"
                    sx={{
                      height: "1px",
                      flex: 1,
                      ml: 2,
                    }}
                  ></Box>
                </Box>
                <Grid direction={direction} container spacing={3}>
                  {shipmentGroup.map((shipment) => {
                    return (
                      <Grid item xs={12} md={6} key={shipment._id}>
                        <DraggableShipmentSummary
                          dragable={dragable}
                          shipment={shipment}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            );
          }
        )}
      </Box>
    );
  }

  return (
    <Grid direction={direction} container spacing={3}>
      {shipments.map((shipment) => {
        return (
          <Grid item xs={12} md={6} key={shipment._id}>
            <Box
              sx={(theme) => ({
                borderColor: theme.palette.primary.main,
                borderWidth: selected?._id === shipment._id ? 2 : 0,
                borderStyle: "solid",
                borderRadius: 5,
              })}
            >
              <DraggableShipmentSummary
                onClick={onSelect}
                shipment={shipment}
                dragable={dragable}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default ShipmentSummariesList;
