import Joi from "joi";
import { DriverShiftAssignment } from "../../../graphql/generated";

const driverShiftAssignmentSchema = Joi.object<DriverShiftAssignment>({
  _id: Joi.string().allow(null).label("ID"),
  driverId: Joi.string().label("Driver"),
  driverShiftId: Joi.string().label("Driver Shift"),
  startDate: Joi.date().allow(null).label("Start Date and Time"),
  endDate: Joi.date().allow(null).label("End Date and Time"),
}).custom((value, helpers) => {
  const { startDate, endDate } = value;
  if (startDate && endDate && startDate > endDate) {
    return helpers.message({
      custom: "Start date must be before end date",
    });
  }
  return value;
});

export default driverShiftAssignmentSchema;
