import { useGetDriverListQuery } from "../../../graphql/generated";
import DriversList from "./DriversList";

export default function DriversListContainer({
  renderHeaderActions,
}: {
  renderHeaderActions?: () => React.ReactNode;
}) {
  return (
    <DriversList
      query={useGetDriverListQuery}
      renderHeaderActions={renderHeaderActions}
    />
  );
}
