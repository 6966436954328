import { ReactNode, useEffect } from "react";
import { useGetOrganizationSettingsQuery } from "../../../graphql/generated";
import StaticLocaleProvider from "../../../providers/LocaleProvider";
import { getOrganizationId, useAuth } from "../../../providers/AuthProvider";

const LocaleProvider = (props: { children: ReactNode }) => {
  const { userInfo } = useAuth();
  const userOrganizationId = userInfo ? getOrganizationId(userInfo) : null;
  const organizationSettingsQuery = useGetOrganizationSettingsQuery(
    {},
    { enabled: !!userOrganizationId }
  );

  const organizationSettings =
    organizationSettingsQuery.data?.organizationSettings;

  useEffect(() => {
    if (organizationSettings?.general?.measurementSystem) {
      StaticLocaleProvider.setMeasurementSystem(
        organizationSettings.general.measurementSystem
      );
    }
    if (organizationSettings?.general?.language) {
      StaticLocaleProvider.setLanguage(organizationSettings.general.language);
    }
    if (organizationSettings?.general?.currency) {
      StaticLocaleProvider.setCurrency(organizationSettings.general.currency);
    }
  }, [organizationSettings]);

  return <>{props.children}</>;
};

export default LocaleProvider;
