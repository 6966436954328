import Joi from "joi";
import { Coordinates, NewTractorInput } from "../../../graphql/generated";
import customFieldSchema from "../../extensions/CustomFieldsForm/customFieldsSchema";
import documentSchema from "../../../utils/form/schemas/document.schema";

const currentYear = new Date().getFullYear();

const tractorSchema = Joi.object<NewTractorInput>({
  serialNumber: Joi.string().min(3).max(50).required().label("Asset Code"),
  licenseNumber: Joi.string().label("License Number").required(),
  plateNumber: Joi.string().label("VIN#").allow(null, ""),
  make: Joi.string().label("Make").allow(null, ""),
  model: Joi.string().label("Model").allow(null, ""),
  tareWeight: Joi.number().label("Tare Weight").allow(null, ""),
  mileage: Joi.number().label("Mileage").allow(null, ""),
  year: Joi.number().min(1900).max(currentYear).label("Year").allow(null, ""),
  domicile: Joi.object<NewTractorInput["domicile"]>({
    label: Joi.string().required().label("Address Label"),
    coordinates: Joi.object<Coordinates>({
      latitude: Joi.number().required(),
      longitude: Joi.number().required(),
    })
      .required()
      .label("Coordinates"),
    line1: Joi.string().allow("", null).label("Address Line 1"),
    line2: Joi.string().allow("", null).label("Address Line 2"),
    postalCode: Joi.string().allow("", null).label("Postal Code"),
    city: Joi.string().allow("", null).label("City"),
    state: Joi.string().allow("", null).label("State"),
    country: Joi.string().allow("", null).label("Country"),
    googlePlaceId: Joi.string().allow("", null).label("Google Place ID"),
  })
    .label("Physical Address")
    .allow(null),
  status: Joi.string().allow(null),
  eldId: Joi.string().allow(null),
  tags: Joi.array().items(Joi.string()).label("Tags").allow(null),
  customFields: Joi.array()
    .items(customFieldSchema)
    .label("Custom Fields")
    .allow(null),
  groupIds: Joi.array().items(Joi.string()).label("Group IDs").allow(null),
  documents: Joi.array().items(documentSchema).label("Documents").allow(null),
});

export default tractorSchema;
