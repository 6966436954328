import { useGetGoodProfileListQuery } from "../../../graphql/generated";
import GoodProfileSelect, {
  MultiSelectGoodProfileProps,
  SingleSelectGoodProfileProps,
} from "./GoodProfileSelect";

type GoodProfileSelectContainerProps =
  | Omit<SingleSelectGoodProfileProps, "goodProfiles" | "onGoodProfilesRefetch">
  | Omit<MultiSelectGoodProfileProps, "goodProfiles" | "onGoodProfilesRefetch">;

const GoodProfileSelectContainer = (props: GoodProfileSelectContainerProps) => {
  const goodProfilesQuery = useGetGoodProfileListQuery();

  const goodProfiles = goodProfilesQuery.data?.goodProfiles.data || [];

  return (
    <GoodProfileSelect
      {...props}
      goodProfiles={goodProfiles}
      onGoodProfilesRefetch={async () => {
        await goodProfilesQuery.refetch();
      }}
    />
  );
};

export default GoodProfileSelectContainer;
