import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  NewDriverShiftAssignmentInput,
  GetAllDriverShiftsQuery,
  GetDriverListQuery,
} from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import LoadingOverlay from "../../common/LoadingOverlay";
import {
  addDays,
  differenceInDays,
  endOfDay,
  isValid,
  startOfDay,
} from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import LocaleProvider from "../../../providers/LocaleProvider";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useCallback, useEffect, useMemo } from "react";
import { formatTime } from "../../../utils/labels/formatDateTime";

export type DriverShiftAssignmentFormProps = {
  driverShiftAssignment: NewDriverShiftAssignmentInput | null;
  onChange: (driverShiftAssignment: NewDriverShiftAssignmentInput) => void;
  drivers: GetDriverListQuery["drivers"]["data"];
  driverShifts: GetAllDriverShiftsQuery["driverShifts"]["data"];
  loading?: boolean;
  selectedDriverId?: string;
  initialStartDate?: Date;
  initialEndDate?: Date;
};

const DriverShiftAssignmentForm = ({
  drivers,
  driverShifts,
  driverShiftAssignment,
  loading,
  selectedDriverId,
  initialStartDate,
  initialEndDate,
  onChange,
}: DriverShiftAssignmentFormProps) => {
  const driverShiftAssignmentWithDefaults: NewDriverShiftAssignmentInput =
    useMemo(
      () => ({
        ...driverShiftAssignment,
        driverId: selectedDriverId || driverShiftAssignment?.driverId || "",
        driverShiftId: driverShiftAssignment?.driverShiftId || "",
        startDate: driverShiftAssignment?.startDate,
        endDate: driverShiftAssignment?.endDate,
      }),
      [driverShiftAssignment, selectedDriverId]
    );

  useEffect(() => {
    if (
      (initialStartDate || initialEndDate) &&
      !driverShiftAssignmentWithDefaults.startDate &&
      !driverShiftAssignmentWithDefaults.endDate
    ) {
      onChange({
        ...driverShiftAssignmentWithDefaults,
        startDate: initialStartDate,
        endDate: initialEndDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation("assets");

  const selectedDriver = drivers.find(
    (driver) => driver._id === driverShiftAssignmentWithDefaults.driverId
  );

  const driverTimezone =
    selectedDriver?.domicileEntity?.addressTimezone ||
    LocaleProvider.getTimezone();

  const onChangeWithAdjustedTimes = useCallback(
    (driverShiftAssignment: NewDriverShiftAssignmentInput) => {
      const driverShift = driverShifts.find(
        (driverShift) => driverShift._id === driverShiftAssignment.driverShiftId
      );
      if (!driverShift) {
        return onChange(driverShiftAssignment);
      }
      const adjustedStartDate = driverShiftAssignment.startDate
        ? utcToZonedTime(
            new Date(driverShiftAssignment.startDate),
            driverTimezone
          )
        : null;
      if (adjustedStartDate) {
        adjustedStartDate.setHours(
          driverShift.startTime.hour,
          driverShift.startTime.minute
        );
      }
      const adjustedEndDate = driverShiftAssignment.endDate
        ? utcToZonedTime(
            new Date(driverShiftAssignment.endDate),
            driverTimezone
          )
        : null;
      if (adjustedEndDate) {
        adjustedEndDate.setHours(
          driverShift.endTime.hour,
          driverShift.endTime.minute
        );
      }
      onChange({
        ...driverShiftAssignment,
        startDate: adjustedStartDate
          ? zonedTimeToUtc(adjustedStartDate, driverTimezone)
          : null,
        endDate: adjustedEndDate
          ? zonedTimeToUtc(adjustedEndDate, driverTimezone)
          : null,
      });
    },
    [driverShifts, driverTimezone, onChange]
  );

  const assignmentDuration =
    driverShiftAssignment?.endDate && driverShiftAssignment?.startDate
      ? differenceInDays(
          new Date(driverShiftAssignmentWithDefaults.endDate) || new Date(),
          new Date(driverShiftAssignmentWithDefaults.startDate) || new Date()
        )
      : Infinity;

  const isOneDay = assignmentDuration >= 0 && assignmentDuration <= 1;
  const isOneWeek = assignmentDuration >= 6 && assignmentDuration <= 7;
  const isPermanent = !driverShiftAssignmentWithDefaults.endDate;

  return (
    <Box>
      <LoadingOverlay loading={loading || false} />
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{
          pt: 2,
        }}
        spacing={1}
      >
        <Autocomplete
          fullWidth
          options={drivers}
          getOptionLabel={(driver) => driverLabel(driver)}
          onChange={(e, driver) => {
            if (!driver) {
              return;
            }
            onChangeWithAdjustedTimes({
              ...driverShiftAssignmentWithDefaults,
              driverId: driver._id,
            });
          }}
          value={
            drivers.find(
              (driver) =>
                driver._id === driverShiftAssignmentWithDefaults?.driverId
            ) || null
          }
          size="small"
          renderInput={(inputProps) => (
            <TextField {...inputProps} label={t("driver.one", "Driver")} />
          )}
          disabled={!!selectedDriverId}
        />

        <Autocomplete
          fullWidth
          options={driverShifts}
          getOptionLabel={(driverShift) => driverShift.label || ""}
          onChange={(e, driverShift) => {
            if (!driverShift) {
              return;
            }
            onChangeWithAdjustedTimes({
              ...driverShiftAssignmentWithDefaults,
              driverShiftId: driverShift._id,
            });
          }}
          value={
            driverShifts.find(
              (driverShift) =>
                driverShift._id ===
                driverShiftAssignmentWithDefaults?.driverShiftId
            ) || null
          }
          size="small"
          renderInput={(inputProps) => (
            <TextField
              {...inputProps}
              label={t("driverShifts.one", "Driver Shift")}
            />
          )}
        />

        <Divider />

        <ButtonGroup
          variant="outlined"
          aria-label={t("driverShifts.predefinedPeriods", "Predefined periods")}
        >
          <Button
            onClick={() => {
              const start = initialStartDate || startOfDay(new Date());
              onChangeWithAdjustedTimes({
                ...driverShiftAssignmentWithDefaults,
                startDate: start,
                endDate: zonedTimeToUtc(
                  endOfDay(utcToZonedTime(start, driverTimezone)),
                  driverTimezone
                ),
              });
            }}
            variant={isOneDay ? "contained" : "outlined"}
          >
            {t("driverShiftAssignmentPeriods.oneDay", "1 Day")}
          </Button>
          <Button
            onClick={() => {
              const start = initialStartDate || startOfDay(new Date());
              onChangeWithAdjustedTimes({
                ...driverShiftAssignmentWithDefaults,
                startDate: start,
                endDate: zonedTimeToUtc(
                  endOfDay(addDays(utcToZonedTime(start, driverTimezone), 6)),
                  driverTimezone
                ),
              });
            }}
            variant={isOneWeek ? "contained" : "outlined"}
          >
            {t("driverShiftAssignmentPeriods.oneWeek", "1 Week")}
          </Button>
          <Button
            onClick={() => {
              onChangeWithAdjustedTimes({
                ...driverShiftAssignmentWithDefaults,
                startDate: initialStartDate || startOfDay(new Date()),
                endDate: null,
              });
            }}
            variant={isPermanent ? "contained" : "outlined"}
          >
            {t("driverShiftAssignmentPeriods.permanent", "Permanent")}
          </Button>
        </ButtonGroup>

        <DatePicker
          label={t("driverShifts.startDateAndTime", "Start Date & Time")}
          value={
            driverShiftAssignmentWithDefaults.startDate
              ? utcToZonedTime(
                  new Date(driverShiftAssignmentWithDefaults.startDate),
                  driverTimezone
                )
              : null
          }
          onChange={(value) => {
            if (!value) {
              onChangeWithAdjustedTimes({
                ...driverShiftAssignmentWithDefaults,
                startDate: null,
              });
              return;
            }
            if (!isValid(value)) {
              return;
            }
            onChangeWithAdjustedTimes({
              ...driverShiftAssignmentWithDefaults,
              startDate: zonedTimeToUtc(value, driverTimezone),
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                endAdornment: (
                  <>
                    <Typography variant="caption">
                      {formatTime(
                        driverShiftAssignmentWithDefaults.startDate,
                        driverTimezone
                      )}
                    </Typography>{" "}
                    {params.InputProps?.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        <DatePicker
          label={t("driverShifts.endDateAndTime", "End Date & Time")}
          value={
            driverShiftAssignmentWithDefaults.endDate
              ? utcToZonedTime(
                  new Date(driverShiftAssignmentWithDefaults.endDate),
                  driverTimezone
                )
              : null
          }
          onChange={(value) => {
            if (!value) {
              onChangeWithAdjustedTimes({
                ...driverShiftAssignmentWithDefaults,
                endDate: null,
              });
              return;
            }
            if (!isValid(value)) {
              return;
            }
            onChangeWithAdjustedTimes({
              ...driverShiftAssignmentWithDefaults,
              endDate: zonedTimeToUtc(value, driverTimezone),
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                endAdornment: (
                  <>
                    <Typography variant="caption">
                      {formatTime(
                        driverShiftAssignmentWithDefaults.endDate,
                        driverTimezone
                      )}
                    </Typography>{" "}
                    {params.InputProps?.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Stack>
    </Box>
  );
};

export default DriverShiftAssignmentForm;
