import { useEffect, useState } from "react";
import ChargebeeWidget, { ChargebeeWidgetForward } from "./ChargebeeWidget";
import {
  useGetSubscriptionInfoQuery,
  useGetSubscriptionPortalSessionMutation,
} from "../../graphql/generated";

type ChargebeeWidgetContainerProps = {
  forward: ChargebeeWidgetForward;
};
export default function ChargebeeWidgetContainer({
  forward,
}: ChargebeeWidgetContainerProps) {
  const getPortalSessionMutation = useGetSubscriptionPortalSessionMutation();
  const [subscriptionPortalUrl, setSubscriptionPortalUrl] =
    useState<string | null>(null);

  const getSubscriptionInfos = useGetSubscriptionInfoQuery(
    {},
    { enabled: forward === ChargebeeWidgetForward.SubscriptionDetails }
  );

  useEffect(() => {
    async function initSubscriptionPortal() {
      const result = await getPortalSessionMutation.mutateAsync({});
      setSubscriptionPortalUrl(result.getSubscriptionPortalSession.url);
    }
    initSubscriptionPortal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return subscriptionPortalUrl ? (
    <ChargebeeWidget
      forward={forward}
      portalUrlSuffix={subscriptionPortalUrl}
      subscriptionId={getSubscriptionInfos.data?.subscriptionInfo?.id}
      loading={
        getSubscriptionInfos.isLoading || getPortalSessionMutation.isLoading
      }
    />
  ) : null;
}
