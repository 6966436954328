import {
  Alert,
  Box,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  AssignmentModalProps,
  TrailerAssignmentData,
} from "../AssignmentModal";
import enumLabel from "../../../../utils/labels/enumLabel";

import unloadedTractor from "../../../../assets/icons/unloaded-tractor.svg";
import {
  GetReceiverListWithStorageFacilitiesQuery,
  GoodProfile,
  TrailerCompartmentAssignment,
} from "../../../../graphql/generated";
import { useState } from "react";
import { capitalize, sum } from "lodash";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/icons-material";
import trailerLabel from "../../../../utils/labels/trailerLabel";
import { useSnackbar } from "notistack";

export type TrailerCompartmentAssignmentFormProps = {
  trailer: TrailerAssignmentData;
  additionalTrailers: TrailerAssignmentData[];
  shipments: AssignmentModalProps["shipments"];
  trip: AssignmentModalProps["trip"];
  goodProfiles: GoodProfile[];
  receivers: GetReceiverListWithStorageFacilitiesQuery["businessEntities"]["data"];
  compartmentAssignments: TrailerCompartmentAssignment[];
  onCompartmentAssignmentsChange: (
    compartmentAssignments: TrailerCompartmentAssignment[]
  ) => void;
};

const TrailerCompartmentAssignmentForm: React.FC<TrailerCompartmentAssignmentFormProps> =
  ({
    compartmentAssignments,
    onCompartmentAssignmentsChange,
    trailer,
    additionalTrailers,
    shipments,
    trip,
    receivers,
    goodProfiles,
  }) => {
    const { t } = useTranslation(["common", "assets", "trailers"]);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedCompartment, setSelectedCompartment] =
      useState<
        | (NonNullable<typeof trailer["compartments"]>[0] & {
            trailerId: string;
          })
        | null
      >(null);

    const selectedCompartmentAssignment =
      compartmentAssignments.find(
        (compartmentAssignment) =>
          compartmentAssignment.compartmentId ===
            selectedCompartment?.identifier &&
          (!compartmentAssignment.trailerId ||
            compartmentAssignment.trailerId === selectedCompartment?.trailerId)
      ) || null;

    const allShippedGoods =
      (shipments
        ? shipments?.map((shipment) => shipment.shipmentLocations)
        : trip
        ? trip.shipmentLocations
        : []
      )
        .flat()
        .map((location) => location.shippedGoods || [])
        .flat() || [];

    const selectedCompartmentGood =
      allShippedGoods.find(
        (good) => good._id === selectedCompartmentAssignment?.commodityId
      ) || null;

    const receiversForSelectedGood =
      shipments
        ?.map((shipment) =>
          shipment.shipmentLocations.filter((location) =>
            location.receivedGoods?.some(
              (receivedGood) =>
                receivedGood.goodId === selectedCompartmentGood?._id
            )
          )
        )
        .flat()
        ?.map((shipmentLocation) =>
          receivers.find(
            (receiver) => receiver._id === shipmentLocation.receiver?._id
          )
        ) || [];

    const availableStorageFacilities =
      receiversForSelectedGood
        .map((receiver) => receiver?.storageFacilities || [])
        .flat()
        .filter(
          (storageFacility) =>
            storageFacility.commodityId ===
            selectedCompartmentGood?.goodProfileId
        ) || [];

    const selectedStorageFacility =
      availableStorageFacilities.find(
        (storageFacility) =>
          storageFacility.identifier ===
          selectedCompartmentAssignment?.targetStorageFacilityId
      ) || null;

    const selectedCompartmentGoodRemainingQuantity =
      (selectedCompartmentGood?.quantity || 0) -
      sum(
        compartmentAssignments
          .filter(
            (compartmentAssignment) =>
              compartmentAssignment.commodityId === selectedCompartmentGood?._id
          )
          .filter(
            (compartmentAssignment) =>
              compartmentAssignment.compartmentId !==
              selectedCompartment?.identifier
          )
          .map((compartmentAssignments) => compartmentAssignments.quantity)
      );

    console.log("remaining quantity", selectedCompartmentGoodRemainingQuantity);

    const remainingGoodQuantites = allShippedGoods.map((good) => {
      const goodProfile = goodProfiles.find(
        (goodProfile) => goodProfile._id === good.goodProfileId
      );
      const goodQuantity = sum(
        compartmentAssignments
          .filter(
            (compartmentAssignment) =>
              compartmentAssignment.commodityId === good._id
          )
          .map((compartmentAssignment) => compartmentAssignment.quantity)
      );
      return {
        good,
        goodProfile,
        remainingQuantity: good.quantity - goodQuantity,
      };
    });

    const allCompartments = trailer?.compartments
      ?.map((compartment) => ({
        ...compartment,
        trailerId: trailer._id,
      }))
      ?.concat(
        additionalTrailers.flatMap((additionalTrailer) =>
          (additionalTrailer.compartments || []).map((compartment) => ({
            ...compartment,
            trailerId: additionalTrailer._id,
          }))
        )
      );

    const updateSelectedCompartmentAssignment = (
      update: Partial<TrailerCompartmentAssignment>
    ) => {
      if (selectedCompartmentAssignment) {
        onCompartmentAssignmentsChange(
          compartmentAssignments.map((compartmentAssignment) => {
            if (
              compartmentAssignment.compartmentId ===
                selectedCompartmentAssignment.compartmentId &&
              (!compartmentAssignment.trailerId ||
                compartmentAssignment.trailerId ===
                  selectedCompartmentAssignment.trailerId)
            ) {
              return {
                ...compartmentAssignment,
                ...update,
              };
            } else {
              return compartmentAssignment;
            }
          })
        );
      } else {
        onCompartmentAssignmentsChange([
          ...compartmentAssignments,
          {
            ...update,
            compartmentId: selectedCompartment?.identifier || "",
            commodityId: update.commodityId || "",
            quantity: update.quantity || 0,
            targetStorageFacilityId: update.targetStorageFacilityId || "",
            trailerId: selectedCompartment?.trailerId || null,
          },
        ]);
      }
    };

    const canHandleSelectedTrailer = (currentIndex: number) => {
      if (currentIndex > 0 && compartmentAssignments.length < currentIndex) {
        return false;
      }
      return true;
    };
    return (
      <Stack spacing={2}>
        {remainingGoodQuantites
          .filter(
            (remainingGoodQuantity) =>
              remainingGoodQuantity.remainingQuantity > 0
          )
          .map((remainingGoodQuantity) => (
            <Box>
              <Alert severity="warning">
                {remainingGoodQuantity.goodProfile?.label} -{" "}
                {remainingGoodQuantity.remainingQuantity}{" "}
                {enumLabel(remainingGoodQuantity.goodProfile?.unit)} remaining
                to assign
              </Alert>
            </Box>
          ))}
        <Stack direction="row" spacing={1}>
          <Stack
            direction="row-reverse"
            spacing={0.3}
            sx={{
              position: "relative",
              right: -90,
              zIndex: 102,
              top: -15,
            }}
          >
            {[trailer, ...(additionalTrailers || [])].map(
              (trailer, trailerIndex) => {
                if (!trailer.compartments?.length) {
                  return null;
                }
                return (
                  <Stack alignItems="center">
                    <Typography variant="caption">
                      {trailerLabel(trailer)}
                    </Typography>
                    <Stack
                      direction="row-reverse"
                      spacing={0.3}
                      sx={{
                        position: "relative",
                      }}
                    >
                      {trailer.compartments?.map(
                        (compartment, compartmentIndex) => {
                          const isFirstInTrailer = compartmentIndex === 0;
                          const isLastInTrailer =
                            compartmentIndex ===
                            (trailer.compartments?.length || 0) - 1;
                          const compartmentAssignment =
                            compartmentAssignments.find(
                              (compartmentAssignment) =>
                                compartmentAssignment.compartmentId ===
                                  compartment.identifier &&
                                (!compartmentAssignment.trailerId ||
                                  compartmentAssignment.trailerId ===
                                    trailer._id)
                            );
                          const compartmentAssignmentGood =
                            allShippedGoods.find(
                              (good) =>
                                good._id === compartmentAssignment?.commodityId
                            );
                          const compartmentAssignmentGoodProfile =
                            goodProfiles.find(
                              (goodProfile) =>
                                goodProfile._id ===
                                compartmentAssignmentGood?.goodProfileId
                            );
                          return (
                            <>
                              {trailerIndex > 0 && compartmentIndex === 0 ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    height: 70,
                                    alignItems: "center",
                                  }}
                                >
                                  <Link color="primary" fontSize="small" />
                                </Box>
                              ) : null}
                              <Box
                                sx={(theme) => {
                                  const compartmentCount =
                                    allCompartments?.length || 0;
                                  const allCompartmentsCapacity =
                                    allCompartments?.reduce(
                                      (acc, compartment) =>
                                        acc + compartment.capacity,
                                      0
                                    ) || 0;
                                  const compartmentCapacityPercentage =
                                    compartment.capacity /
                                    (allCompartmentsCapacity || 1);
                                  const totalWidth =
                                    300 + 20 * compartmentCount;
                                  return {
                                    position: "relative",
                                    backgroundColor:
                                      theme.palette.primary.light,
                                    opacity:
                                      selectedCompartment?.identifier ===
                                        compartment.identifier &&
                                      selectedCompartment.trailerId ===
                                        trailer._id
                                        ? 0.8
                                        : 0.5,
                                    width:
                                      totalWidth *
                                      compartmentCapacityPercentage,
                                    height: 70,
                                    cursor: "pointer",
                                    borderWidth:
                                      selectedCompartment?.identifier ===
                                        compartment.identifier &&
                                      selectedCompartment.trailerId ===
                                        trailer._id
                                        ? 2
                                        : 0,
                                    borderColor: theme.palette.primary.main,
                                    borderStyle: "solid",
                                    borderTopLeftRadius: isLastInTrailer
                                      ? 12
                                      : 0,
                                    borderBottomLeftRadius: isLastInTrailer
                                      ? 12
                                      : 0,
                                    borderTopRightRadius: isFirstInTrailer
                                      ? 12
                                      : 0,
                                    borderBottomRightRadius: isFirstInTrailer
                                      ? 12
                                      : 0,
                                    ":hover": {
                                      opacity: 0.8,
                                    },
                                    textAlign: "center",
                                    overflow: "hidden",
                                  };
                                }}
                                onClick={() => {
                                  if (!canHandleSelectedTrailer(trailerIndex)) {
                                    enqueueSnackbar(
                                      t(
                                        "trailers:cantSelectTrailer",
                                        "Previous trailers must have at least one compartment filled"
                                      ),
                                      { variant: "warning" }
                                    );
                                    return;
                                  }
                                  setSelectedCompartment({
                                    ...compartment,
                                    trailerId: trailer._id,
                                  });
                                }}
                              >
                                {compartmentAssignment ? (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      bottom: 0,
                                      backgroundColor:
                                        compartmentAssignmentGoodProfile?.color ||
                                        getRandomColor(
                                          compartmentAssignment.commodityId
                                        ),
                                      width: "100%",
                                      height: `${
                                        (compartmentAssignment.quantity /
                                          compartment.capacity) *
                                        100
                                      }%`,
                                      zIndex: -1,
                                      opacity: 0.7,
                                    }}
                                  />
                                ) : null}
                                <Typography color="primary.contrastText">
                                  {compartment.identifier}
                                </Typography>
                                <Typography
                                  color="primary.contrastText"
                                  variant="caption"
                                >
                                  {compartment.capacity}{" "}
                                  {enumLabel(compartment.unit)}
                                </Typography>
                                <br />
                                <Typography
                                  color="primary.contrastText"
                                  variant="caption"
                                >
                                  {compartmentAssignmentGood?.label}
                                </Typography>
                              </Box>
                            </>
                          );
                        }
                      )}
                    </Stack>
                  </Stack>
                );
              }
            )}
            {/* {allCompartments?.map((compartment, index) => {
              const isFirstInTrailer =
                index === 0 ||
                allCompartments[index - 1].trailerId !== compartment.trailerId;
              const isLastInTrailer =
                index === allCompartments.length - 1 ||
                allCompartments[index + 1].trailerId !== compartment.trailerId;
              const compartmentAssignment = compartmentAssignments.find(
                (compartmentAssignment) =>
                  compartmentAssignment.compartmentId ===
                    compartment.identifier &&
                  (!compartmentAssignment.trailerId ||
                    compartmentAssignment.trailerId === compartment.trailerId)
              );
              const compartmentAssignmentGood = allShippedGoods.find(
                (good) => good._id === compartmentAssignment?.commodityId
              );
              const compartmentAssignmentGoodProfile = goodProfiles.find(
                (goodProfile) =>
                  goodProfile._id === compartmentAssignmentGood?.goodProfileId
              );
              return (
                <>
                  {index > 0 && isFirstInTrailer ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: 70,
                        alignItems: "center",
                      }}
                    >
                      <Link color="primary" fontSize="small" />
                    </Box>
                  ) : null}
                  <Box
                    sx={(theme) => {
                      const compartmentCount = allCompartments?.length || 0;
                      const allCompartmentsCapacity =
                        allCompartments?.reduce(
                          (acc, compartment) => acc + compartment.capacity,
                          0
                        ) || 0;
                      const compartmentCapacityPercentage =
                        compartment.capacity / (allCompartmentsCapacity || 1);
                      const totalWidth = 300 + 20 * compartmentCount;
                      return {
                        position: "relative",
                        backgroundColor: theme.palette.primary.light,
                        opacity:
                          selectedCompartment?.identifier ===
                            compartment.identifier &&
                          selectedCompartment.trailerId ===
                            compartment.trailerId
                            ? 0.8
                            : 0.5,
                        width: totalWidth * compartmentCapacityPercentage,
                        height: 70,
                        cursor: "pointer",
                        borderTopLeftRadius: isLastInTrailer ? 12 : 0,
                        borderBottomLeftRadius: isLastInTrailer ? 12 : 0,
                        borderTopRightRadius: isFirstInTrailer ? 12 : 0,
                        borderBottomRightRadius: isFirstInTrailer ? 12 : 0,
                        ":hover": {
                          opacity: 0.8,
                        },
                        textAlign: "center",
                        overflow: "hidden",
                      };
                    }}
                    onClick={() => {
                      setSelectedCompartment(compartment);
                    }}
                  >
                    {compartmentAssignment ? (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          backgroundColor:
                            compartmentAssignmentGoodProfile?.color ||
                            getRandomColor(compartmentAssignment.commodityId),
                          width: "100%",
                          height: `${
                            (compartmentAssignment.quantity /
                              compartment.capacity) *
                            100
                          }%`,
                          zIndex: -1,
                          opacity: 0.7,
                        }}
                      />
                    ) : null}
                    <Typography color="primary.contrastText">
                      {compartment.identifier}
                    </Typography>
                    <Typography color="primary.contrastText" variant="caption">
                      {compartment.capacity} {enumLabel(compartment.unit)}
                    </Typography>
                    <br />
                    <Typography color="primary.contrastText" variant="caption">
                      {compartmentAssignmentGood?.label}
                    </Typography>
                  </Box>
                </>
              );
            })} */}
          </Stack>
          <img
            src={unloadedTractor}
            style={{
              height: 110,
              position: "relative",
            }}
            alt="Unloaded tractor"
          />
        </Stack>
        <TextField
          label={capitalize(t("assets:commodity.one", "Commodity"))}
          select
          value={selectedCompartmentAssignment?.commodityId || ""}
          onChange={(event) => {
            updateSelectedCompartmentAssignment({
              commodityId: event.target.value,
            });
          }}
          name="commodity"
        >
          {allShippedGoods.map((good) => (
            <MenuItem key={good._id} value={good._id}>
              {good.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={t("common:quantity", "Quantity")}
          value={selectedCompartmentAssignment?.quantity || ""}
          onChange={(event) => {
            updateSelectedCompartmentAssignment({
              quantity: Math.min(
                Number(event.target.value),
                selectedCompartmentGoodRemainingQuantity,
                selectedCompartment?.capacity || Infinity,
                selectedStorageFacility?.capacity || Infinity
              ),
            });
          }}
          InputProps={{
            endAdornment: (
              <Typography variant="caption">
                {enumLabel(selectedCompartmentGood?.unit)}
              </Typography>
            ),
          }}
          type="number"
          inputProps={{
            max: Math.min(
              selectedCompartmentGoodRemainingQuantity,
              selectedCompartment?.capacity || Infinity,
              selectedStorageFacility?.capacity || Infinity
            ),
            min: 0,
          }}
          name="quantity"
        />
        <TextField
          label={t("assets:tank", "Tank")}
          select
          value={selectedCompartmentAssignment?.targetStorageFacilityId || ""}
          onChange={(event) => {
            updateSelectedCompartmentAssignment({
              targetStorageFacilityId: event.target.value,
            });
          }}
          name="tank"
        >
          {availableStorageFacilities.map((storageFacility) => {
            const goodProfile = goodProfiles.find(
              (goodProfile) => goodProfile._id === storageFacility.commodityId
            );
            return (
              <MenuItem
                key={storageFacility.identifier}
                value={storageFacility.identifier}
              >
                {storageFacility.identifier} - {goodProfile?.label} (
                {storageFacility.capacity} {enumLabel(storageFacility.unit)})
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
    );
  };

export default TrailerCompartmentAssignmentForm;

// Initialize an empty object to store the color mappings
const colorMap: { [x in string]: string } = {};

function getRandomColor(identifier: string) {
  // Check if the identifier already has a mapped color
  if (colorMap.hasOwnProperty(identifier)) {
    return colorMap[identifier];
  } else {
    // Generate a random color and store it in the colorMap
    const randomColor = getRandomHexColor();
    colorMap[identifier] = randomColor;
    return randomColor;
  }
}

function getRandomHexColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
