import { useSnackbar } from "notistack";
import {
  useEditOrganizationMutation,
  useMyOrganizationQuery,
} from "../../../graphql/generated";
import OrganizationInfosForm from "./OrganizationInfosForm";
import { omit } from "lodash";
import { useTranslation } from "react-i18next";

export default function OrgnizationInfosFormContainer() {
  const myOrganizationQuery = useMyOrganizationQuery();
  const editOrganization = useEditOrganizationMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["organization"]);

  return (
    <OrganizationInfosForm
      initialOrgInfos={myOrganizationQuery.data?.myOrganization}
      onSave={async (organizationSetupData) => {
        try {
          await editOrganization.mutateAsync({
            organizationSetupData: omit(organizationSetupData, "_id"),
          });
          enqueueSnackbar(
            t("orgInfosSaved", "Organization informations saved"),
            {
              variant: "success",
            }
          );
        } catch (error) {
          enqueueSnackbar(
            t("orgInfosSaveFailed", "Failed to save organization informations"),
            {
              variant: "error",
            }
          );
        }
      }}
      saving={myOrganizationQuery.isLoading}
    />
  );
}
