import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { GoodProfile } from "../../../graphql/generated";
import { capitalize, isArray, sortBy } from "lodash";
import { useTranslation } from "react-i18next";

enum AutocompleteOptionType {
  GoodProfile = "GoodProfile",
  Address = "Address",
}

type AutocompleteOption = {
  id: string;
  label: string;
  type: AutocompleteOptionType;
};

const filter = createFilterOptions<AutocompleteOption>();

type SelectGoodProfile = Pick<GoodProfile, "label" | "code" | "_id">;

export type SingleSelectGoodProfileProps = Omit<
  AutocompleteProps<string, false, undefined, undefined>,
  | "options"
  | "disablePortal"
  | "filterOptions"
  | "getOptionLabel"
  | "size"
  | "renderInput"
  | "onChange"
> & {
  goodProfiles?: Array<SelectGoodProfile>;
  onGoodProfilesRefetch: () => Promise<void>;
  onChange: (goodProfileIds: string | null) => void;
  inputProps?: TextFieldProps;
};

export type MultiSelectGoodProfileProps = Omit<
  AutocompleteProps<string, true, undefined, undefined>,
  | "options"
  | "disablePortal"
  | "filterOptions"
  | "getOptionLabel"
  | "size"
  | "renderInput"
  | "onChange"
> & {
  goodProfiles?: Array<SelectGoodProfile>;
  onGoodProfilesRefetch: () => Promise<void>;
  onChange: (goodProfileIds: string[] | null) => void;
  inputProps?: TextFieldProps;
};

export type GoodProfileSelectProps =
  | SingleSelectGoodProfileProps
  | MultiSelectGoodProfileProps;

export default function GoodProfileSelect({
  goodProfiles,
  onGoodProfilesRefetch,
  inputProps: providedInputProps,
  onChange,
  multiple,
  value,
  ...props
}: GoodProfileSelectProps) {
  const { t } = useTranslation("assets");
  const getBeOptionName = (goodProfileId: string) => {
    const goodProfile = goodProfiles?.find((be) => be._id === goodProfileId);
    if (!goodProfile) {
      return "";
    }
    return `(${goodProfile.code}) ${goodProfile.label}`;
  };

  const rolodexAutocompleteOptions = sortBy(
    goodProfiles || [],
    (be) => be.label
  ).map((be) => ({
    id: be._id,
    label: getBeOptionName(be._id),
    type: AutocompleteOptionType.GoodProfile,
  }));

  return (
    <>
      <Autocomplete
        options={rolodexAutocompleteOptions}
        multiple={multiple}
        value={
          multiple
            ? rolodexAutocompleteOptions.filter(
                (option) => value?.includes(option.id) || false
              )
            : rolodexAutocompleteOptions.find(
                (option) => option.id === value
              ) || null
        }
        getOptionLabel={(option) => option.label}
        filterOptions={(options, params) => {
          const filtered = filter(
            options.filter(
              (option) => option.type === AutocompleteOptionType.GoodProfile
            ),
            params
          );
          return filtered;
        }}
        onChange={(event, newValue) => {
          if (isArray(newValue) && multiple) {
            onChange(newValue.map((option) => option.id));
          }

          if (!isArray(newValue) && multiple === false) {
            onChange(newValue?.id || null);
          }
        }}
        size="small"
        renderInput={(inputProps) => (
          <TextField
            name={"commodity-profile"}
            required
            {...inputProps}
            {...providedInputProps}
            label={capitalize(t("commodity.one", "Commodity"))}
          />
        )}
        fullWidth
      />
    </>
  );
}
