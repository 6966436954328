import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { GetBulkMetricsQuery, MetricType } from "../../../graphql/generated";
import EnumSelect from "../../common/EnumSelect";
import { useEffect, useMemo, useState } from "react";
import {
  AllSeriesType,
  AxisConfig,
  BarPlot,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsXAxisProps,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
  PieChart,
  PieSeriesType,
  PieValueType,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { intersection, uniq } from "lodash";
import { MakeOptional } from "@mui/x-charts/internals";
import enumLabel from "../../../utils/labels/enumLabel";

export type GraphOptions = {
  metricTypes: MetricType[];
  chartType: ChartType;
  xAxisDimension: string;
  aggregateBy: string;
};

export type AnalyticsGraphProps = {
  metrics: GetBulkMetricsQuery["bulkMetrics"];
  initialOptions?: GraphOptions;
  onGraphOptionsChange: (graphOptions: GraphOptions) => void;
};

const dimensionsByMetricType: Record<MetricType, string[]> = {
  [MetricType.ProductPurchaseCost]: [
    "day",
    "goodProfileId",
    "supplierId",
    "customerId",
  ],
  [MetricType.DeliveryCost]: [
    "day",
    "customerId",
    "receiverId",
    "goodProfileId",
    "supplierId",
  ],
  [MetricType.Mileage]: [
    "day",
    "customerId",
    "receiverId",
    "goodProfileId",
    "supplierId",
  ],
};

export enum ChartType {
  Line = "line",
  Bar = "bar",
  Pie = "pie",
}

const AnalyticsGraph = (props: AnalyticsGraphProps) => {
  const [metricTypes, setMetricTypes] = useState<MetricType[]>(
    props.initialOptions?.metricTypes || [MetricType.ProductPurchaseCost]
  );
  const [xAxisDimension, setXAxisDimension] = useState<string>(
    props.initialOptions?.xAxisDimension || ""
  );
  const [aggregateBy, setAggregateBy] = useState<string>(
    props.initialOptions?.aggregateBy || ""
  );
  const [chartType, setChartType] = useState<ChartType>(
    props.initialOptions?.chartType || ChartType.Line
  );

  const availableDimensions = useMemo(
    () =>
      intersection(
        ...metricTypes.map((metricType) => dimensionsByMetricType[metricType])
      ),
    [metricTypes]
  );

  const xAxisData: string[] = uniq(
    (props.metrics[0] || []).map((metric) => metric.dimensions[xAxisDimension])
  );

  const xAxis: AxisConfig<"point" | "band", any, ChartsXAxisProps> = {
    id: xAxisDimension,
    scaleType: chartType === ChartType.Bar ? "band" : "point",
    data: xAxisData,
  };

  const aggregateValues = aggregateBy
    ? uniq(
        (props.metrics[0] || []).map((metric) => metric.dimensions[aggregateBy])
      )
    : [];

  const series: AllSeriesType[] = metricTypes.flatMap((metricType, index) => {
    const metrics = props.metrics[index];

    if (!metrics) {
      return [];
    }

    return aggregateBy
      ? aggregateValues.map((aggregateValue) => ({
          type: chartType as "line" | "bar",
          label: `${enumLabel(metricType)} for ${aggregateValue}`,
          data: xAxisData.map((x) => {
            const metric = metrics.find(
              (metric) =>
                metric.dimensions[xAxisDimension] === x &&
                metric.dimensions[aggregateBy] === aggregateValue
            );
            return metric ? metric.value : 0;
          }),
        }))
      : [
          {
            type: chartType as "line" | "bar",
            label: `${enumLabel(metricType)}`,
            data: metrics.map((metric) => metric.value),
          },
        ];
  });
  const pieSeries: MakeOptional<
    PieSeriesType<MakeOptional<PieValueType, "id">>,
    "type"
  >[] =
    chartType === ChartType.Pie
      ? [
          {
            id: "pie",
            type: "pie",
            data: (props.metrics[0] || []).map((metric, index) => ({
              id: index,
              label: metric.dimensions[xAxisDimension],
              value: metric.value,
            })),
          },
        ]
      : [];

  useEffect(() => {
    if (!availableDimensions.includes(xAxisDimension)) {
      setXAxisDimension("");
    }
    if (!availableDimensions.includes(aggregateBy)) {
      setAggregateBy("");
    }
  }, [availableDimensions, xAxisDimension, aggregateBy]);

  const { onGraphOptionsChange } = props;

  useEffect(() => {
    onGraphOptionsChange({
      metricTypes,
      chartType,
      xAxisDimension,
      aggregateBy,
    });
  }, [
    metricTypes,
    xAxisDimension,
    aggregateBy,
    chartType,
    onGraphOptionsChange,
  ]);

  return (
    <Box>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <EnumSelect
            label="Metric type"
            enumObject={MetricType}
            value={metricTypes}
            multiple
            onChange={(event, value) => {
              if (!value) {
                return;
              }
              if (chartType === ChartType.Pie) {
                value = [value[0]];
              }
              setMetricTypes(value);
            }}
            fullWidth
          />

          <EnumSelect
            label="Chart type"
            enumObject={ChartType}
            value={chartType}
            onChange={(event, value) => {
              if (!value) {
                return;
              }
              setChartType(value);
              if (value === ChartType.Pie) {
                setAggregateBy("");
                setMetricTypes([metricTypes[0]]);
              }
            }}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="x-axis-label">X Axis</InputLabel>
            <Select
              labelId="x-axis-label"
              value={xAxisDimension}
              onChange={(event) => {
                const value = event.target.value as string;
                setXAxisDimension(value);
              }}
            >
              {availableDimensions.map((dimension) => (
                <MenuItem key={dimension} value={dimension}>
                  {dimensionLabel[dimension]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {chartType === ChartType.Pie ? null : (
            <FormControl fullWidth>
              <InputLabel id="group-by-label">Group By</InputLabel>
              <Select
                labelId="group-by-label"
                value={aggregateBy}
                onChange={(event) => {
                  const value = event.target.value as string;
                  setAggregateBy(value);
                }}
              >
                {availableDimensions.map((dimension) => (
                  <MenuItem key={dimension} value={dimension}>
                    {dimensionLabel[dimension]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>

        <Box
          sx={{
            my: 5,
            display: "flex",
            alignItems: "center",
          }}
        >
          {xAxisDimension ? (
            chartType === ChartType.Pie ? (
              <PieChart width={800} height={400} series={pieSeries} />
            ) : (
              <ResponsiveChartContainer
                series={series}
                xAxis={[xAxis]}
                height={400}
              >
                {chartType === ChartType.Bar ? <BarPlot /> : null}
                {chartType === ChartType.Line ? <LinePlot /> : null}
                {chartType === ChartType.Line ? <MarkPlot /> : null}
                <ChartsXAxis
                  label="X axis"
                  position="bottom"
                  axisId={xAxisDimension}
                />
                <ChartsYAxis label="Value" position="left" />
                <ChartsTooltip />
                <ChartsLegend />
              </ResponsiveChartContainer>
            )
          ) : null}
        </Box>
      </Stack>
    </Box>
  );
};

export default AnalyticsGraph;

const dimensionLabel: Record<string, string> = {
  day: "Day",
  goodProfileId: "Commodity",
  supplierId: "Supplier",
  customerId: "Customer",
  receiverId: "Receiver",
};
